import styled, { css } from "styled-components";

export const InterviewContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  background: #1a1110;
  min-height: 100%;
  color: white;
  box-sizing: border-box;
`;

export const CanvasContainer = styled.div`
  margin-top: 10px;
  // border: 1px solid white;
  // max-height: 75vh;
  width: ${({ width }) => (width ? width : "100%")};
  background: #1a1110;
`;

export const CountdownWrapper = styled.h1(
  ({
    theme: {
      colors: { white },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${white};
    font-family: ${base};
    font-size: ${fontSize.f32};
    font-weight: ${fontWeight.semibold};
  `
);
