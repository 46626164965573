import React from "react";
import { Container, Grid } from "@mui/material";
import InterviewIllustration from "assets/images/interviewIllustration.svg";
import { Title, Header } from "components";
import {
  Box,
  ContentCenter,
  BlockMargin,
  FlexColumn,
  PaddingBlock,
} from "assets/styles/main.styles";
import { useLocation, useNavigate } from "react-router-dom";
import PoweredComponent from "components/PoweredComponent/PoweredComponent";
import useWindowSize from "hooks/useWindowSize";

const ApplySuccess = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  return (
    <>
      <Header />
      <BlockMargin size={100}></BlockMargin>

      {!isMobile && <BlockMargin size={150}></BlockMargin>}
      <Container maxWidth="md" style={{ paddingBottom: "40px" }}>
        <Box>
          <PaddingBlock padding={20}>
            <FlexColumn gap={20}>
              <ContentCenter>
                <BlockMargin size={1}>
                  <Title
                    level="h1"
                    text={<>Job application submitted successfully.</>}
                  />
                </BlockMargin>
              </ContentCenter>
              <Grid container spacing={10} alignItems="center" className="mb-2">
                <Grid item sm={12} textAlign="center">
                  <img
                    src={InterviewIllustration}
                    alt="Interview"
                    height={250}
                    style={{ width: "100%" }}
                  />
                  <div
                    style={{
                      width: "200px",
                      margin: "20px auto 0",
                    }}
                  >
                    <PoweredComponent />
                  </div>
                </Grid>
              </Grid>
            </FlexColumn>
          </PaddingBlock>
        </Box>
      </Container>
    </>
  );
};
export default ApplySuccess;
