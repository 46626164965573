import { Grid, Skeleton } from "@mui/material";

export const ListLoader = () => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={100}
          variant="rounded"
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={100}
          variant="rounded"
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={100}
          variant="rounded"
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={100}
          variant="rounded"
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={100}
          variant="rounded"
        ></Skeleton>
      </Grid>
    </Grid>
  );
};
