import React, { useRef, useEffect, useState } from "react";
import {
  HeaderContainer,
  LogoContainer,
  MenuListing,
} from "./headermobile.styles";
import Logo from "assets/images/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "contexts/user.context";
import { ADMIN, RECRUITER } from "config/constants";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAuthValidate from "hooks/useAuthValidate";
import { menuNotNeededPage } from "utils";

const HeaderMobile = ({ isAdmin }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { userDetails } = useUser();
  //   const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { logOutHandler } = useAuthValidate();
  const url = pathname.split("/");

  const navigateToHome = () => {
    if (userDetails?.userRoles.includes(RECRUITER)) {
      navigate("/job-listings");
    } else if (userDetails?.userRoles.includes(ADMIN)) {
      navigate("/org-listings");
    }
  };

  //   const collapseRef = useRef(null);
  //   useEffect(() => {
  //     const handler = (event) => {
  //       if (!collapseRef.current) {
  //         return;
  //       }
  //       // if click was not inside of the element. "!" means not
  //       // in other words, if click is outside the modal element
  //       if (!collapseRef.current.contains(event.target)) {
  //         setMenuOpen(false);
  //       }
  //     };
  //     // the key is using the `true` option
  //     // `true` will enable the `capture` phase of event handling by browser
  //     document.addEventListener("click", handler, true);
  //     return () => {
  //       document.removeEventListener("click", handler);
  //     };
  //   }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {url[1] === "candidate-interview" ||
      url[1] === "interview-instructions" ||
      url[1] === "candidate-interview/kyc" ||
      url[1] === "interview-videos" ? (
        <></>
      ) : (
        <HeaderContainer>
          <LogoContainer onClick={navigateToHome}>
            <img src={Logo} alt="Logo" onClick={() => navigateToHome()} />
          </LogoContainer>
          {!menuNotNeededPage(url[1]) && (
            <Button
              variant="contained"
              size="large"
              startIcon={
                <MenuIcon
                  size="small"
                  sx={{
                    color: "#fff",
                    fontSize: "40px !important",
                    padding: 0,
                  }}
                />
              }
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              sx={{
                ".MuiButton-startIcon": {
                  marginRight: 0,
                  marginLeft: 0,
                },
                backgroundColor: "#5a189a",
                padding: "5px 8px",
                minWidth: "unset",
              }}
            ></Button>
          )}

          <Menu
            id="demo-customized-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiPaper-root": {
                left: "250px",
                width: "180px",
              },
            }}
          >
            {!isAdmin && (
              <>
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard");
                    handleClose();
                  }}
                  disableRipple
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/job-listings");
                    handleClose();
                  }}
                  disableRipple
                >
                  Job Listing
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/user-settings");
                    handleClose();
                  }}
                  disableRipple
                >
                  Settings
                </MenuItem>
              </>
            )}

            <MenuItem onClick={logOutHandler} disableRipple>
              Logout
            </MenuItem>
          </Menu>
          {/* <Collapse
        in={menuOpen}
        timeout="auto"
        ref={collapseRef}
        unmountOnExit
        sx={{
          width: "310px",
          padding: "20px",
          borderRadius: "4px",
          position: "absolute",
          bgcolor: "background.paper",
          top: "100%",
          zIndex: 1000,
          overflow: "auto",
          border: "1px solid #ccc",
          boxShadow: "0px 0px 10px #ccc",
        }}
      >
        <MenuListing>
          <li>
            <a href="#">Dashboard</a>
          </li>
          <li>
            <a href="#">Job Listing</a>
          </li>
          <li>
            <a href="#">Settings</a>
          </li>
          <li>
            <a href="#">Logout</a>
          </li>
        </MenuListing>
      </Collapse> */}
        </HeaderContainer>
      )}
    </>
  );
};

export default HeaderMobile;
