import {
  BackButton,
  FromWebsiteIcon,
  CIBBlock,
  RatingBlock,
  SubtitleInfo,
} from "assets/styles/main.styles";
import { GoBackButton, Tag, Title, Button } from "components";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import { RatingValues } from "config/constants";
import { statuses } from "config/constants";
import { capitalizeWord } from "utils";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import { format } from "date-fns";
import { getTimePassed } from "utils";
import { useLocation } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
export default function AnswerHeader({
  candidateData,
  reviewData,
  statusId,
  overallRating,
  handleReviewClicked,
}) {
  const location = useLocation();
  const windowSize = useWindowSize();

  const { pathname } = location;
  const url = pathname.split("/");
  const fromReviewer = url[1] === "reviewer-login-enc";
  const isMobile = windowSize.width <= 768;

  function stringAvatar(name) {
    if (name) {
      let nameArray = name.split(" ");
      return `${nameArray[0][0] || ""}${
        nameArray?.[1] ? nameArray?.[1][0] : ""
      }`;
    }
    return;
  }

  let thumbnail = "";

  if (candidateData?.AnswerPool?.length > 0) {
    const vidMeta =
      candidateData?.AnswerPool[candidateData?.AnswerPool?.length - 1]?.vidMeta;
    const vidMet = JSON.parse(vidMeta);

    thumbnail = vidMet.vidMeta.assets.thumbnail;
  }

  function getDisplayTime() {
    let displayTime = "";
    if (candidateData?.AnswerPool?.length > 0) {
      const vidMeta =
        candidateData?.AnswerPool[candidateData?.AnswerPool?.length - 1]
          ?.vidMeta;
      const vidMet = JSON.parse(vidMeta);
      displayTime =
        format(vidMet?.vidMeta?.createdAt, "PPp") +
        "(" +
        getTimePassed(vidMet?.vidMeta?.createdAt) +
        ")";
    } else if (candidateData?.createdDate) {
      displayTime =
        format(candidateData?.createdDate, "PPp") +
        "(" +
        getTimePassed(candidateData?.createdDate) +
        ")";
    }
    return displayTime;
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" xs={12}>
      <Grid item md={12} lg={5}>
        <BackButton>
          {!fromReviewer && (
            <GoBackButton
              sx={{
                color: "#dcdcdc",
                marginTop: "26px",
                marginLeft: "-12px",
                padding: "2px 15px",
                borderRight: "1px solid #c4c5c5",
                height: "36px",
                cursor: "pointer",
              }}
            />
          )}

          <div style={{ position: "relative" }}>
            <Avatar
              src={thumbnail && thumbnail}
              sx={{
                width: 90,
                height: 90,
                fontSize: 42,
                color: "#A2DFFF",
                border: "2px solid #C1EAFF",
                bgcolor: "#E0F5FF",
                flex: isMobile ? 1 : "unset",
              }}
            >
              {stringAvatar(candidateData?.name)}
            </Avatar>{" "}
            {candidateData?.fromWebsite && (
              <div
                style={{
                  zIndex: 10,
                  position: "absolute",
                  marginLeft: "28px",
                  marginTop: "-14px",
                }}
              >
                <FromWebsiteIcon title="Added from website">
                  www
                </FromWebsiteIcon>
              </div>
            )}
          </div>

          <CIBBlock>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexWrap: "wrap",
                marginBottom: isMobile ? "1rem" : 0,
              }}
            >
              <Title
                level="h2"
                text={candidateData?.name}
                paddingTop={"40px"}
                color="black"
              />{" "}
              <Tag
                type={statuses[statusId]}
                label={statuses[statusId]}
                size="large"
              />
            </div>
            <span
              style={{
                color: "gray",
                paddingTop: "8px",
                paddingBottom: "8px",
                fontWeight: "800",
              }}
            >{`${
              candidateData?.jobrole
                ? capitalizeWord(candidateData?.jobrole?.roleName)
                : ""
            }`}</span>

            <SubtitleInfo>
              <strong>{getDisplayTime()}</strong>
            </SubtitleInfo>
          </CIBBlock>
        </BackButton>
      </Grid>

      {/* Middle area : Rating and Feedback */}
      {candidateData?.Feedback?.length > 0 &&
        candidateData?.Feedback?.length >= reviewData?.length && (
          <Grid item lg={2} sm={12}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              verticalAlign={"center"}
              sx={{ alignItems: "center" }}
            >
              <div>
                <RatingBlock
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {[...Array(5)].map((_, index) => {
                    const isFilled = overallRating >= index + 1;
                    const isHalfFilled =
                      overallRating > index && overallRating < index + 1;
                    const color =
                      isHalfFilled || isFilled
                        ? "#faaf00"
                        : "rgba(0, 0, 0, 0.25)";
                    const Icon = isHalfFilled
                      ? StarHalfRoundedIcon
                      : isFilled
                      ? StarRoundedIcon
                      : StarBorderRoundedIcon;
                    return (
                      <Icon
                        key={index}
                        style={{
                          width: "60px",
                          height: "60px",
                          color: color,
                          textAlign: "center",
                        }}
                      ></Icon>
                    );
                  })}
                </RatingBlock>
              </div>
              {/* <div>
                                  <Title level="h4" text={Math.round(overallRating) ?? 0 + " / 5"}></Title>
                              </div> */}
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              verticalAlign={"center"}
              sx={{ alignItems: "center" }}
              paddingBottom={"30px"}
            >
              <Title
                level="h4"
                text={RatingValues[Math.round(overallRating)]}
              ></Title>
              {/* <p level="h6" text="PreScreening Status: ">
                                  Total Rating:
                              </p> */}
              {/* <Tag
                  type={statuses[statusId]}
                  label={statuses[statusId]}
                  size="large"
                /> */}
            </Stack>
          </Grid>
        )}

      {/* Right Side Area: Share, Save, Review */}
      <Grid item sm lg={7}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-end"
          paddingTop={"20px"}
        >
          {candidateData?.Feedback?.length != reviewData?.length && (
            <>
              {/* <Button
                  btnText="Save"
                  variant="outlined"
                  size="sm"
                  onClick={handleSave}
                  disabled={isSubmitting}
                  style={{ marginRight: "10px" }}
                /> */}
              {statusId === 2 && (
                <Button
                  btnText="Review"
                  variant="contained"
                  size="sm"
                  onClick={handleReviewClicked}
                  style={{ marginRight: "10px" }}
                />
              )}
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
