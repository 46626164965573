const recruiterName = "Sam Thomas";
const organisationName = "Neointeration Design";
const companyEmail = "info@neointeraction.com";
const userName = "";
const candidateName = "Rahul Mehra";
const message = "";
const thumbnail =
  "https://vod.api.video/vod/vi6TWEj4OaYr9Z11Jf9GqCMm/thumbnail.jpg";
const expiresIn = "";
const shareCandidateUrl = "";
const interviewLink = "";
const logo = "";
const kapireeLogo = "";
const roleName = "Graphic Designer";
const viewCandidateUrl =
  "https://app.kapiree.com/reviewer-login?id=1922&role=39";
const overall_rating = 3.5;
const goldRating = Math.round(overall_rating);
const greyRating = 5 - goldRating;

const candidates = [
  {
    candidateName: "Sam Thomas",
    roleName: "Graphic Designer",
    thumbnail:
      "https://vod.api.video/vod/vi4rApL8oS38DOemUN21TGBv/thumbnail.jpg",
    shareCandidateUrl: "https://app.kapiree.com/reviewer-login?id=1922&role=39",
  },
  {
    candidateName: "Midhun V Manikkath",
    roleName: "Graphic Designer",
    thumbnail:
      "https://vod.api.video/vod/vi4rApL8oS38DOemUN21TGBv/thumbnail.jpg",
    shareCandidateUrl: "https://app.kapiree.com/reviewer-login?id=1922&role=39",
  },
  {
    candidateName: "Ashuthosh",
    roleName: "Graphic Designer",
    thumbnail:
      "https://vod.api.video/vod/vi4rApL8oS38DOemUN21TGBv/thumbnail.jpg",
    shareCandidateUrl: "https://app.kapiree.com/reviewer-login?id=1922&role=39",
  },
];

// Email Templates
export const hrReviewSubmittedContent = JSON.stringify(
  {
    name: recruiterName,
    candidateName: candidateName,
    roleName: roleName,
    thumbnail,
    overall_rating: { overall_rating },
    viewCandidateUrl: viewCandidateUrl,
    goldRating: goldRating,
    greyRating: greyRating,
  },
  null,
  2
);

export const multipleReviewContent = JSON.stringify(
  {
    name: recruiterName,
    organisationName: organisationName,
    companyEmail: companyEmail,
    userName: "Midhun V Manikakth",
    candidateName: candidateName,
    roleName: roleName,
    message: `Candidate has submitted the interview. Candidate details are following`,
    thumbnail: thumbnail,
    expiresIn: "5 days",
    shareCandidateUrl: "https://app.kapiree.com",
    interviewLink: "https://local.auth:3000/reviewer-login?id=1719&role=76",
    logo: "https://vidrec-bkt-01.s3.ap-south-1.amazonaws.com/logo/WhatsApp+Image+2023-07-06+at+6.22.03+PM.jpeg",
    kapireeLogo:
      "https://vidrec-bkt-01.s3.ap-south-1.amazonaws.com/logo/WhatsApp+Image+2023-07-06+at+6.22.03+PM.jpeg",
    candidates: candidates,
  },
  null,
  2
);

export const submittedContent = JSON.stringify({
  name: recruiterName,
  candidateName: candidateName,
  roleName: roleName,
  thumbnail: thumbnail,
  viewCandidateUrl: viewCandidateUrl,
});

export const emailTemplates = [
  { label: "common", value: "common", subject: "", emailContent: "" },
  {
    label: "hr-review-submitted",
    value: "hr-review-submitted",
    subject: candidateName + " | " + roleName + " - is shortlisted.",
    emailContent: hrReviewSubmittedContent,
  },
  {
    label: "submitted",
    value: "submitted",
    subject: candidateName + " | " + roleName + " - Submitted Interview",
    emailContent: submittedContent,
  },
  {
    label: "multiple-review",
    value: "multiple-review",
    subject: "Review Request: Candidate List for " + roleName,
    emailContent: multipleReviewContent,
  },
  // { label: "candidate-fail", value: "candidate-fail" ,subject:"subject",emailContent:"emailContent"},
  // { label: "candidate-invite", value: "candidate-invite" ,subject:"subject",emailContent:"emailContent" },
  // { label: "candidate-pass", value: "candidate-pass"  ,subject:"subject",emailContent:"emailContent"},
  // { label: "new-organization", value: "new-organization"  ,subject:"subject",emailContent:"emailContent"},
];

// Whatsapp Templates

export const hello_world_Template = {
  name: "hello_world",
  language: { code: "en_US" },
};

export const test_template = {
  name: "test_template",
  language: { code: "en_US" },
};

export const is_shortlisted_Template = {
  name: "is_shortlisted",
  language: {
    code: "en_US",
  },
  components: [
    {
      type: "header",
      parameters: [
        {
          type: "text",
          text: candidateName,
        },
      ],
    },
    {
      type: "body",
      parameters: [
        { type: "text", text: recruiterName },
        { type: "text", text: candidateName },
      ],
    },
    {
      type: "button",
      sub_type: "url",
      index: "0",
      parameters: [
        {
          type: "text",
          text: "bvSwRInT8P6X2XfX3a0jBJfcbjS976yFU3Ic+U4nu8E",
        },
      ],
    },
  ],
};

export const candidate_invite_Template = {
  name: "candidate_invite",
  language: {
    code: "en",
  },
  components: [
    {
      type: "body",
      parameters: [
        { type: "text", text: organisationName },
        { type: "text", text: companyEmail },
        { type: "text", text: candidateName },
      ],
    },
  ],
};
export const whatsAppTemplates = [
  {
    value: "hello_world",
    label: "hello_world",
    template: hello_world_Template,
  },
  { value: "test_template", label: "test_template", template: test_template },
  {
    value: "is_shortlisted",
    label: "is_shortlisted",
    template: is_shortlisted_Template,
  },
  {
    value: "candidate_invite",
    label: "candidate_invite",
    template: candidate_invite_Template,
  },
];

function createData(mobileNumber, recruiter_id, message, messsageData) {
  return { mobileNumber, recruiter_id, message, messsageData };
}

export const rows = [
  createData(
    "+919972151617",
    1,
    "this is a text message",
    JSON.stringify({
      field: "messages",
      value: {
        messaging_product: "whatsapp",
        metadata: {
          display_phone_number: "16505551111",
          phone_number_id: "123456123",
        },
        contacts: [
          {
            profile: {
              name: "test user name",
            },
            wa_id: "16315551181",
          },
        ],
        messages: [
          {
            from: "16315551181",
            id: "ABGGFlA5Fpa",
            timestamp: "1504902988",
            type: "text",
            text: {
              body: "this is a text message",
            },
          },
        ],
      },
    })
  ),
  createData(
    "+918943032211",
    2,
    "this is a text message",
    JSON.stringify({
      field: "messages",
      value: {
        messaging_product: "whatsapp",
        metadata: {
          display_phone_number: "16505551111",
          phone_number_id: "123456123",
        },
        contacts: [
          {
            profile: {
              name: "test user name",
            },
            wa_id: "16315551181",
          },
        ],
        messages: [
          {
            from: "16315551181",
            id: "ABGGFlA5Fpa",
            timestamp: "1504902988",
            type: "text",
            text: {
              body: "this is a text message",
            },
          },
        ],
      },
    })
  ),
];

export const tableData = [
  {
    id: 1,
    object: null,
    entry:
      '[{"id":"409334732261799","changes":[{"value":{"messaging_product":"whatsapp","metadata":{"display_phone_number":"15556246022","phone_number_id":"337987242741291"},"statuses":[{"id":"wamid.HBgMOTE4OTQzMDMyMjExFQIAERgSRjMxQTVCMTdCNDFDQTUzMERGAA==","status":"delivered","timestamp":"1724826365","recipient_id":"918943032211","conversation":{"id":"eaef34aa954bc152e325cf727807a408","origin":{"type":"utility"}},"pricing":{"billable":true,"pricing_model":"CBP","category":"utility"}}]},"field":"messages"}]}]',
  },
  {
    id: 2,
    object: null,
    entry:
      '[{"id":"409334732261799","changes":[{"value":{"messaging_product":"whatsapp","metadata":{"display_phone_number":"15556246022","phone_number_id":"337987242741291"},"contacts":[{"profile":{"name":"Jishnu Krishnan"},"wa_id":"918943032211"}],"messages":[{"from":"918943032211","id":"wamid.HBgMOTE4OTQzMDMyMjExFQIAEhggREVDRTYwNDRGQkNEMkNBNDMxMjVFNzE3RkEzQTQxNjEA","timestamp":"1724826926","text":{"body":"Hii"},"type":"text"}]},"field":"messages"}]}]',
  },
  {
    id: 3,
    object: null,
    entry:
      '[{"id":"409334732261799","changes":[{"value":{"messaging_product":"whatsapp","metadata":{"display_phone_number":"15556246022","phone_number_id":"337987242741291"},"statuses":[{"id":"wamid.HBgMOTE4OTQzMDMyMjExFQIAERgSRjMxQTVCMTdCNDFDQTUzMERGAA==","status":"sent","timestamp":"1724826360","recipient_id":"918943032211","conversation":{"id":"eaef34aa954bc152e325cf727807a408","expiration_timestamp":"1724852760","origin":{"type":"utility"}},"pricing":{"billable":true,"pricing_model":"CBP","category":"utility"}}]},"field":"messages"}]}]',
  },
];
