import React from "react";
import GaugeChart from "react-gauge-chart";
import { Grid, Box, Rating } from "@mui/material";
import { StatusBadge, OtherRatingsWrapper } from "../candidatelist.styles";
import { Title } from "components";
import { BlockMargin } from "assets/styles/main.styles";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { printNullToZero } from "../utils";
import { statuses } from "config/constants";
import { getSubmittedStatusColor } from "../utils";

const ratingsColors = {
  0: "#E30000",
  1: "#E30000",
  2: "#FE7900",
  3: "#FFCF15",
  4: "#85CC03",
  5: "#00BF11",
};

const otherRatingsProperties = (value) => {
  let rating = printNullToZero(value);
  return {
    rating,
    color: ratingsColors[rating],
  };
};

const RatingBlock = ({ candidate }) => {
  const { rating, reviewComments, InterviewPool } = candidate;
  const comments =
    reviewComments?.length > 0 ? reviewComments[0]?.comments : "";
  const attitude = otherRatingsProperties(InterviewPool[0]?.attitude);
  const communication = otherRatingsProperties(InterviewPool[0]?.communication);
  const professionalism = otherRatingsProperties(
    InterviewPool[0]?.professionalism
  );
  const teamfit = otherRatingsProperties(InterviewPool[0]?.teamfit);
  const culturalfit = otherRatingsProperties(InterviewPool[0]?.culturalfit);
  const businessaccumen = otherRatingsProperties(
    InterviewPool[0]?.businessaccumen
  );
  const totalOtherRatings =
    attitude.rating +
    communication.rating +
    professionalism.rating +
    teamfit.rating +
    culturalfit.rating +
    businessaccumen.rating;
  const totalRatings = rating + totalOtherRatings;
  const averageRating = Number(totalRatings) / 7;
  const maximumRating = 5;
  const guageRating = ((averageRating / maximumRating) * 100) / 100;
  const statusId = InterviewPool[0]?.status;

  const getBackgroundColorByRating = () => {
    if (averageRating > 4) {
      return "#00BF11";
    } else if (averageRating > 3) {
      return "#85CC03";
    } else if (averageRating > 2) {
      return "#FFCF15";
    } else if (averageRating > 1) {
      return "#FE7900";
    }
    return "#E30000";
  };
  return (
    <div style={{ padding: "1rem" }}>
      <Grid
        container
        flexDirection={{ sm: "column", md: "row" }}
        justifyContent="flex-start"
        spacing={4}
        alignItems="left"
      >
        <Grid item xs={12} md={4} justifyContent="center" textAlign={"center"}>
          <Title level="h4" text="Overall Ratings" />
          <GaugeChart
            id="gauge-chart1"
            nrOfLevels={5}
            colors={["#E30000", "#FE7900", "#FFCF15", "#85CC03", "#00BF11"]}
            arcWidth={0.4}
            cornerRadius={0}
            hideText
            percent={guageRating}
          />
          <StatusBadge color={getBackgroundColorByRating()}>
            {statuses[statusId] == "Rejected" ? "Not Fit" : statuses[statusId]}
          </StatusBadge>
        </Grid>
        <Grid item sx={12} md={8}>
          <Box backgroundColor="#F8F9F9" padding="1rem">
            <Title level="body" text="Video Interview ratings" />
            <Rating
              defaultValue={rating}
              value={rating}
              precision={0.5}
              readOnly
            />
            <BlockMargin size={10} />
            {attitude.rating != 0 && (
              <>
                <Title level="body" text="Other ratings" />
                <OtherRatingsWrapper>
                  <OtherRatings
                    color={attitude.color}
                    innerText={attitude.rating}
                    text="Attitude"
                  />
                  <OtherRatings
                    color={communication.color}
                    innerText={communication.rating}
                    text="Communication"
                  />
                  <OtherRatings
                    color={professionalism.color}
                    innerText={professionalism.rating}
                    text="Professionalism"
                  />
                  <OtherRatings
                    color={teamfit.color}
                    innerText={teamfit.rating}
                    text="Team fit"
                  />
                  <OtherRatings
                    color={culturalfit.color}
                    innerText={culturalfit.rating}
                    text="Cultural Fit"
                  />
                  <OtherRatings
                    color={businessaccumen.color}
                    innerText={businessaccumen.rating}
                    text="Business Acumen"
                  />
                </OtherRatingsWrapper>
                <BlockMargin size={10} />
              </>
            )}

            <Title level="body" text="Comments" />
            <Box
              style={{
                height: "50px",
                overflowY: "auto",
                background: "white",
                padding: "10px",
                lineHeight: "18px",
                overflowY: "auto",
                fontSize: "14px",
              }}
            >
              {comments}
            </Box>
            <BlockMargin size={10} />

            {InterviewPool[0]?.screenReading == 1 && (
              <Box
                backgroundColor="#F6ECD2"
                padding="5px"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px 15px",
                  width: "fit-content",
                  gap: "10px",
                  borderRadius: "4px",
                }}
              >
                <WarningAmberIcon sx={{ fontSize: "1rem" }} />
                <Title
                  level="label"
                  text="Candidate may be reading from screen"
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RatingBlock;

const OtherRatings = ({ color, innerText, text }) => {
  return (
    <div>
      <StatusBadge color={color}>{innerText} / 5</StatusBadge>{" "}
      <Title level="label" text={text} />
    </div>
  );
};
