import React, { useEffect, useState } from "react";
import { candidateAppUrl } from "config/constants";
import { webAuth } from "config/auth-config";
import { useAlert } from "contexts/alert.context";
import { useSearchParams } from "react-router-dom";

const CandidateLogin = () => {
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  async function storeToken() {
    getToken().then((data) => {
      setLoading(false);
      localStorage.setItem("token", data);
    });
  }

  const getToken = async () => {
    var data = await webAuth.currentSession();
    return data.idToken.jwtToken;
  };

  const callGoogleLogin = () => {
    webAuth.authorize(
      {
        connection: "google-oauth2",
        redirectUri: candidateAppUrl,
        responseType: "token id_token",
        scope: "openid profile email offline_access",
      },
      (err, result) => {
        if (err) {
          console.log("failed to login");
          setLoading(false);
          showAlert("invalid email or password", "error");
          return;
        }
        storeToken();
        console.log("result", result);
      }
    );
  };

  useEffect(() => {
    if (id) {
      sessionStorage.setItem("uniqueInterviewId", id);
      callGoogleLogin();
    } else {
      alert("Please use a correct link");
    }
  }, [searchParams]);

  return <></>;
};

export default CandidateLogin;
