import React, { useState, useEffect } from "react";
import { CardContent, Tabs, Tab } from "@mui/material";
import { ButtonIcon } from "assets/styles/main.styles";
import { a11yProps } from "./utils";
import { CustomTabPanel } from "./components/CustomTabPanel";
import FilterModal from "./components/FilterModal";
import useWindowSize from "hooks/useWindowSize";
import { CandidateListItem } from "./CandidateListItem";
import { ScrollContainer } from "./candidatelist.styles";
import { getCandidatesUnderJobRole } from "config/api.service";
import { ListLoader } from "./components/ListLoader";
import { add, differenceInDays } from "date-fns";
import { Title } from "components";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const CandidatesList = ({
  roleId,
  setCandidateId,
  handleCandidateClick,
  selectedCandidatesForReviewEmail,
  setSelectedCandidatesForReviewEmail,
  candidate_id,
}) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [appliedCandidates, setAppliedCandidates] = useState([]);
  const [submittedCandidates, setSubmittedCandidates] = useState([]);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isFromDashboard, setIsFromDashboard] = useState(true);

  useEffect(() => {
    if (roleId) {
      setSelectedTabId(0);
      setSelectedCandidateId(null);
      getCandidatesByJobRole(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (candidate_id) {
      setSelectedCandidateId(candidate_id);
    }
  }, [candidate_id]);

  const getCandidatesByJobRole = async (roleId) => {
    setIsLoadingData(true);
    await getCandidatesUnderJobRole(handleCandidatesListing, Number(roleId));
    setIsLoadingData(false);
  };

  const handleCandidatesListing = (data) => {
    const transformedData = data.map((item) => {
      let statusId = 1;
      if (item?.InterviewPool?.length) {
        statusId = item?.InterviewPool?.[0]?.status;
      } else if (item.noticePeriod > item.jobrole.noticePeriod) {
        statusId = 8;
      }
      if (statusId === 1) {
        const expireIn = 3;
        let expiryDate = add(new Date(item.InvitePool?.[0]?.createdDate), {
          days: expireIn,
        });
        let today = new Date();
        if (differenceInDays(today, expiryDate) > expireIn) {
          statusId = 9;
        }
      }
      return {
        ...item,
        status: statusId,
      };
    });
    setAppliedCandidates(
      transformedData.filter((item) => [1, 8, 9, 7].includes(item?.status))
    );
    const submittedOnes = transformedData.filter((item) =>
      [2, 3, 5, 6].includes(item?.status)
    );
    setSubmittedCandidates(submittedOnes);
    if (selectedCandidateId && isFromDashboard) {
      handleCandidateSelection(selectedCandidateId);
      window.scrollTo({
        top: document.getElementById(`candidate-list-item-${candidate_id}`)
          ?.offsetTop,
        behavior: "smooth",
      });
    } else {
      handleCandidateSelection(submittedOnes[0]?.id);
    }
    setIsFromDashboard(false);
    setFilteredData(submittedOnes);
  };

  useEffect(() => {
    setCandidateId(selectedCandidateId);
  }, [selectedCandidateId]);

  const handleTabChange = (event, newValue) => {
    setSelectedTabId(newValue);
    if (newValue === 0) {
      setFilteredData(submittedCandidates);
      setSelectedCandidateId(submittedCandidates[0]?.id);
    } else {
      setFilteredData(appliedCandidates);
      setSelectedCandidateId(appliedCandidates[0]?.id);
    }
  };

  const applyFilter = (filterOptions) => {
    const name = filterOptions?.search;
    const status = filterOptions?.status;
    const noticePeriod = Number(filterOptions?.noticePeriod);

    let originalData = [];
    if (selectedTabId === 0) {
      originalData = submittedCandidates;
    } else {
      originalData = appliedCandidates;
    }
    let filtered = originalData;
    if (name) {
      filtered = originalData.filter((item) => {
        return item?.name?.toLowerCase().includes(name.toLowerCase());
      });
    }
    if (status && status.length > 0) {
      filtered = filtered.filter((item) => {
        return status.includes(item?.status.toString());
      });
    }
    if (noticePeriod) {
      filtered = filtered.filter((item) => {
        return item?.noticePeriod == noticePeriod;
      });
    }
    setFilteredData(filtered);
    setSelectedCandidateId(filtered[0]?.candidateId);
    setIsFilterModalOpen(false);
  };

  const clearFilter = () => {
    setIsFilterModalOpen(false);
    let originalData = [];
    if (selectedTabId === 0) {
      originalData = submittedCandidates;
    } else {
      originalData = appliedCandidates;
    }
    setFilteredData(originalData);
    setSelectedCandidateId(originalData[0]?.candidateId);
  };

  const handleCandidateSelection = (candidateId) => {
    setSelectedCandidateId(candidateId);
    handleCandidateClick(candidateId);
  };

  return (
    <>
      <FilterModal
        isFilterModalOpen={isFilterModalOpen}
        setIsFilterModalOpen={setIsFilterModalOpen}
        handleApplyFilter={applyFilter}
        handleClearFilter={clearFilter}
        selectedTabId={selectedTabId}
      />

      <div style={{ position: "relative", padding: "0px 10px", margin: "0px" }}>
        <Tabs
          value={selectedTabId}
          onChange={handleTabChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Video Interviews" {...a11yProps(0)} />
          <Tab label="Applications" {...a11yProps(1)} />
          <ButtonIcon
            onClick={() => {
              setIsFilterModalOpen(!isFilterModalOpen);
            }}
            style={{ cursor: "pointer" }}
          >
            <FilterAltIcon fontSize="large" />
          </ButtonIcon>
        </Tabs>

        <CardContent
          style={{
            padding: "0px",
            marginTop: "10px",
            marginRight: "10px",
            Height: "20vh",
          }}
        >
          {/* Video interview listing tab */}

          <CustomTabPanel
            value={selectedTabId}
            index={0}
            style={{ padding: "0px" }}
          >
            {isLoadingData ? (
              <ListLoader />
            ) : filteredData.length > 0 ? (
              <ScrollContainer
                style={{ maxHeight: isMobile ? "30vh" : "70vh" }}
              >
                {filteredData.map((item, index) => {
                  return (
                    <CandidateListItem
                      id={`candidate-list-item-${item.id}`}
                      details={item}
                      selectedCandidatesForReviewEmail={
                        selectedCandidatesForReviewEmail
                      }
                      setSelectedCandidatesForReviewEmail={
                        setSelectedCandidatesForReviewEmail
                      }
                      selectedCandidateId={selectedCandidateId}
                      handleCandidateClick={handleCandidateSelection}
                    />
                  );
                })}
              </ScrollContainer>
            ) : (
              <Title level="h4" text="No candidates found" />
            )}
          </CustomTabPanel>

          {/* Application listing tab */}

          <CustomTabPanel
            value={selectedTabId}
            index={1}
            style={{ padding: isMobile ? "0px" : "8px 0px" }}
          >
            {filteredData.length > 0 ? (
              <ScrollContainer>
                {filteredData.map((item) => {
                  return (
                    <CandidateListItem
                      details={item}
                      selectedCandidateId={selectedCandidateId}
                      handleCandidateClick={handleCandidateSelection}
                    />
                  );
                })}
              </ScrollContainer>
            ) : (
              <Title level="h4" text="No candidates found" />
            )}
          </CustomTabPanel>
        </CardContent>
      </div>
    </>
  );
};

export default CandidatesList;
