import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { BlockMargin } from "assets/styles/main.styles";
import Profile from "./cards/Profile";
import Ratings from "./cards/Ratings";
import InterviewAnswers from "./cards/InterviewAnswers";
import BasicAnswers from "./cards/BasicAnswers";
// import WhatsappCommunication from "./cards/WhatsappCommunication";
import AdditionalDetails from "./components/AdditionalDetails";
import { getCandidateDetails } from "config/api.service";
import { add, differenceInDays } from "date-fns";
import { Loader } from "./components/Loader";
import InterviewStatus from "./cards/InterviewStatus";
import { SelectedScrollContainer } from "./candidatelist.styles";

const SelectedCandidate = ({ selectedCandidateId, roleId }) => {
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [isSelectedCandidateLoading, setIsSelectedCandidateLoading] =
    useState(true);
  const [isInviteExpired, setIsInviteExpired] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [candidateApplicationStatus, setCandidateApplicationStatus] =
    useState("");
  const [videoCardModalOpen, setVideoCardModalOpen] = useState(false);
  const [reloadDetails, setReloadDetails] = useState(false);

  useEffect(() => {
    if (selectedCandidateId) {
      getCandidateData(selectedCandidateId);
    }
  }, [selectedCandidateId, reloadDetails]);

  async function getCandidateData(candidateId) {
    setIsSelectedCandidateLoading(true);
    const res = await getCandidateDetails(candidateId, roleId);
    if (res.status === "success") {
      setSelectedCandidate({
        ...res.data,
        candidateId,
        roleId: roleId,
      });
      let applicationStatus = res.data.InterviewPool?.length
        ? res.data.InterviewPool?.[0]?.status
        : 8;
      setCandidateApplicationStatus(applicationStatus);
      if (applicationStatus === 1) {
        const expireIn = res.data.CandidateList[0].expireIn;
        let expiryDate = add(new Date(res.data.InvitePool[0].createdDate), {
          days: expireIn,
        });
        let today = new Date();
        if (differenceInDays(today, expiryDate) > expireIn) {
          setIsInviteExpired(true);
        }
      }
      let defaultReviewData = res?.data?.jobrole.interviewQuestions.map(
        (item) => {
          return {
            questionId: item.id,
            rating: 0,
          };
        }
      );
      if (res?.data?.Feedback?.length > 0) {
        res?.data?.Feedback?.forEach((item) => {
          defaultReviewData.forEach((data) => {
            if (data.questionId === item.questionId) {
              data.rating = item.rating;
            }
          });
        });
      }
      setReviewData(defaultReviewData);
    }
    setIsSelectedCandidateLoading(false);
  }

  const handleReviewData = (id, rating) => {
    let temp = [...reviewData];
    const index = temp.findIndex((item) => item.questionId === id);
    temp[index] = { ...temp[index], rating };
    setReviewData(temp);
  };

  const onBasicAnswerNavClicked = () => {
    window.scrollTo({
      top: document.getElementById("basicAnswers")?.offsetTop,
      behavior: "smooth",
    });
  };

  const handleReviewModalOpen = (e) => {
    e.preventDefault();
    setVideoCardModalOpen(true);
  };

  if (Object.keys(selectedCandidate).length === 0) return <></>;

  return (
    <div style={{ padding: "6px" }}>
      {isSelectedCandidateLoading ? (
        <Loader />
      ) : (
        <SelectedScrollContainer>
          {/* Interview Status */}
          <Card
            sx={{
              backgroundColor: "transparent",
            }}
            md={{
              borderBottom: "1px solid #e0e0e0",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02)",
            }}
          >
            <InterviewStatus
              candidate={selectedCandidate}
              status={candidateApplicationStatus}
              isInviteExpired={isInviteExpired}
              roleId={roleId}
              handleReviewClicked={handleReviewModalOpen}
            />
          </Card>
          <BlockMargin size={14} />
          {/* Profile */}
          <Card
            sx={{
              borderBottom: "1px solid #e0e0e0",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02)",
            }}
          >
            <Profile
              candidate={selectedCandidate}
              onBasicAnswerNavClicked={onBasicAnswerNavClicked}
            />
          </Card>
          <BlockMargin size={14} />
          {/* Ratings */}
          {selectedCandidate?.InterviewPool?.length > 0 && (
            <>
              {(selectedCandidate?.InterviewPool[0].status == 3 ||
                selectedCandidate?.InterviewPool[0].status == 5) && (
                <>
                  <Card
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      boxShadow:
                        "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02)",
                    }}
                  >
                    <Ratings candidate={selectedCandidate} />
                  </Card>
                  <BlockMargin size={14} />
                </>
              )}
              {/* Video Questions */}
              <Card
                sx={{
                  borderBottom: "1px solid #e0e0e0",
                  boxShadow:
                    "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02)",
                }}
              >
                <InterviewAnswers
                  candidate={selectedCandidate}
                  reviewData={reviewData}
                  handleReviewData={handleReviewData}
                  videoCardModalOpen={videoCardModalOpen}
                  setVideoCardModalOpen={setVideoCardModalOpen}
                  setReloadDetails={setReloadDetails}
                />
              </Card>
              <BlockMargin size={14} />
            </>
          )}
          <Card
            sx={{
              borderBottom: "1px solid #e0e0e0",
              boxShadow:
                "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02)",
            }}
          >
            <BasicAnswers selectedCandidate={selectedCandidate} />
          </Card>
          <BlockMargin size={14} />
          {/* <Card>
            <WhatsappCommunication
              selectedCandidateId={selectedCandidate?.candidateId}
            />
          </Card>
              <BlockMargin size={14} />
               */}

          <AdditionalDetails selectedCandidate={selectedCandidate} />
        </SelectedScrollContainer>
      )}
    </div>
  );
};

export default SelectedCandidate;
