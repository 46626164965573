import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import { Button, Checkbox, Input, Dropdown } from "components";

import Stack from "@mui/material/Stack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  InviteModalContainer,
  ModalActionButtons,
  InputWithDropDown,
  IWDBlock,
} from "assets/styles/main.styles";
import { errorMessages } from "config/constants";

const InviteCandidate = ({
  handleRequestCandidates,
  inviteCandidateData,
  handleUpdate,
  inviteFieldError,
  isSubmiting,
  isFromWebsite = false,
  isEdit = false,
}) => {
  const [fieldError, setFieldError] = useState({});

  useEffect(() => {
    if (inviteFieldError && Object.keys(inviteFieldError)?.length) {
      setFieldError(inviteFieldError);
    }
  }, [inviteFieldError]);

  const handleData = (e) => {
    validateData(e);
    if (e.target.type === "checkbox") {
      handleRequestCandidates(e.target.name, e.target.checked);
    } else {
      handleRequestCandidates(e.target.name, e.target.value);
    }
  };

  const validateData = (e) => {
    if (e.target.value === undefined || e.target.value === "") {
      setFieldError({ ...fieldError, [e.target.name]: errorMessages.required });
    } else {
      let temp = { ...fieldError };
      delete temp[e.target.name];
      setFieldError(temp);
    }
  };

  return (
    <>
      <Stack
        direction={{ sm: "column", xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={{ xs: 1, md: 2 }}
        gap={{ xs: 1, md: 2 }}
      >
        <div>
          <Input
            label="Invite Expires in( days )"
            type="number"
            placeholder="Enter Days"
            name="expireIn"
            value={inviteCandidateData?.expireIn}
            onChange={handleData}
            onBlur={validateData}
            error={!!fieldError["expireIn"]}
            errorText={fieldError["expireIn"]}
          />
        </div>
        <div>
          {isEdit ? (
            <Grid item xs={3}>
              <Button
                variant="contained"
                btnText="Update"
                onClick={handleUpdate}
                loading={isSubmiting}
                disabled={isFromWebsite ? false : false}
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <Button
                variant="contained"
                btnText={`Add Candidates`}
                onClick={handleUpdate}
                loading={isSubmiting}
              />
            </Grid>
          )}
        </div>
      </Stack>
    </>
  );
};

export default InviteCandidate;
