import React, { useState } from "react";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import {
  StatusCard,
  StatusBadge,
  StatusBadgeSmall,
} from "../candidatelist.styles";
import Card from "@mui/material/Card";
import {
  FlexRow,
  InviteModalContainer,
  ModalButtonWrapper,
  IconButtons,
} from "assets/styles/main.styles";

import ShareOutlined from "@mui/icons-material/ShareOutlined";
import {
  videoInterviewStatus,
  applicationStatuses,
  getSubmittedStatusColor,
  getBackgroundColorForStatus,
  generateShareLink,
  colors,
} from "../utils";
import { resendCandidateInvite } from "config/api.service";
import { Button, Title, Modal } from "components";
import { useAlert } from "contexts/alert.context";
import ShareModalContent from "../components/ShareModalContent";

const InterviewStatus = ({
  candidate,
  status,
  isInviteExpired,
  roleId,
  handleReviewClicked,
}) => {
  const { showAlert } = useAlert();
  const [resendInviteConfirmModalOpen, setResendInviteConfirmModalOpen] =
    useState(false);
  const [reminderConfirmModalOpen, setReminderConfirmModalOpen] =
    useState(false);
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");

  const handleResendRequest = async () => {
    setIsSendingInvite(true);
    const data = {
      candidateId: candidate?.candidateId,
      roleId,
    };
    await resendCandidateInvite(data, handleResendSuccess, handleSubmitFail);
    setIsSendingInvite(false);
  };

  const handleSendReminder = () => {
    setIsSendingReminder(true);
    const data = {
      candidateId: candidate?.candidateId,
      roleId,
    };
    // await resendCandidateInvite(data, handleResendSuccess, handleSubmitFail);
    setIsSendingReminder(false);
  };

  function handleResendSuccess() {
    showAlert("Candidate Updated Successfully", "success");
    setResendInviteConfirmModalOpen(false);
  }

  function handleSubmitFail(errorMessage) {
    showAlert(errorMessage || "Something went wrong", "error");
  }

  function handleShareButton(e, forReview = false) {
    setShareModalOpen(true);
    const link = generateShareLink(candidate?.candidateId, roleId, forReview);
    setShareLink(link);
  }

  const getVideoInterviewStatus = () => {
    if (status === 1) {
      return <>{videoInterviewStatus(status)}</>;
    } else if (status === 8) {
      return (
        <StatusBadge color={colors.rejected} style={{ marginTop: "10px" }}>
          Auto-rejected
        </StatusBadge>
      );
    } else {
      return (
        <p style={{ padding: "5px 0px", lineHeight: "1.25" }}>
          Video interview submitted
        </p>
      );
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="left"
        gap={{ md: 0, xs: 2 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {/* Status - Requested */}
          <StatusCard color={colors.applied}>
            <FlexRow
              gap={5}
              style={{
                alignItems: "flex-end",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  gap: "5px",
                }}
              >
                <h1>Job Applied</h1>
                <p style={{ padding: "0px" }}>
                  {candidate?.createdDate
                    ? format(candidate?.createdDate, "PPp")
                    : ""}
                </p>
              </div>
              <>
                {status === 1 ? (
                  <StatusBadgeSmall
                    color={colors.inviteExpired}
                    style={{ marginLeft: "10px" }}
                  >
                    {isInviteExpired ? (
                      <>Invite Expired</>
                    ) : (
                      <>
                        Invite Expiry in {candidate.CandidateList[0].expireIn}{" "}
                        days
                      </>
                    )}
                  </StatusBadgeSmall>
                ) : null}
              </>
            </FlexRow>
            <FlexRow
              gap={2}
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {getVideoInterviewStatus()}

              {status === 1 && (
                <IconButtons onClick={handleShareButton}>
                  <ShareOutlined
                    sx={{ fontSize: "14px", marginRight: "5px" }}
                  />
                  Share
                </IconButtons>
              )}
              {status === 1 && isInviteExpired && (
                <Button
                  btnText={`Resend Request`}
                  variant="outlined"
                  size="small"
                  onClick={() => setResendInviteConfirmModalOpen(true)}
                />
              )}
              {status === 1 && !isInviteExpired && (
                <Button
                  btnText={`Send Reminder`}
                  variant="outlined"
                  size="small"
                  style={{ marginTop: "10px" }}
                  onClick={() => setReminderConfirmModalOpen(true)}
                />
              )}
            </FlexRow>
          </StatusCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {/* Job Applied  */}
          <StatusCard color={getSubmittedStatusColor(status)}>
            {status === 1 || status === 8 ? (
              <>
                <h1>Video Interview</h1>
                <div style={{ padding: "5px 0px", lineHeight: "1.25" }}>
                  This stage is enabled once cadidate completed the video
                  interview
                </div>
              </>
            ) : (
              // Video Submitted
              <FlexRow gap={5} style={{ alignItems: "flex-end" }}>
                <h1>Video Submitted</h1>{" "}
                <p style={{ padding: "0px" }}>
                  {candidate?.AnswerPool?.length > 0
                    ? format(
                        candidate?.AnswerPool[candidate?.AnswerPool?.length - 1]
                          ?.createdDate,
                        "PPp"
                      )
                    : null}
                </p>
              </FlexRow>
            )}

            {status === 8 ? null : status != 1 ? (
              status === 3 || status === 5 ? (
                <FlexRow
                  gap={2}
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "5px",
                  }}
                >
                  <StatusBadgeSmall color={getBackgroundColorForStatus(status)}>
                    {applicationStatuses[status]}
                  </StatusBadgeSmall>

                  {status === 3 && (
                    // Status - FIT

                    <>
                      <IconButtons
                        onClick={handleShareButton}
                        style={{
                          color: "#4286F5",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <ShareOutlined sx={{ fontSize: "14px" }} />
                        Share
                      </IconButtons>
                      {/* <Button
                          btnText="Schedule Next Round"
                          variant="outlined"
                          size="small"
                          onClick={() => {}}
                        /> */}
                    </>
                  )}
                </FlexRow>
              ) : (
                // Status - Pending Review
                <FlexRow
                  gap={2}
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "5px",
                  }}
                >
                  <p style={{ padding: "5px 0px", lineHeight: "1.25" }}>
                    Waiting for video review
                  </p>

                  <IconButtons
                    onClick={(e) => handleShareButton(e, true)}
                    className="BtnStyle"
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    <ShareOutlined
                      sx={{ fontSize: "14px", paddingRight: "5px" }}
                    />
                    Share to Review
                  </IconButtons>
                  <IconButtons
                    onClick={handleShareButton}
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  >
                    <ShareOutlined
                      sx={{ fontSize: "14px", paddingRight: "5px" }}
                    />
                    Share
                  </IconButtons>
                  <Button
                    btnText="Review"
                    variant="contained"
                    size="small"
                    onClick={(e) => handleReviewClicked(e)}
                  />
                </FlexRow>
              )
            ) : null}
          </StatusCard>
        </Grid>
      </Grid>
      {resendInviteConfirmModalOpen && (
        <Modal
          size="sm"
          open={resendInviteConfirmModalOpen}
          close={() => setResendInviteConfirmModalOpen(false)}
          title="Invite Again"
          children={
            <InviteModalContainer>
              <Title
                level="h6"
                text="Are you sure you want to resend invite?"
              />
              <ModalButtonWrapper>
                <Button
                  variant="contained"
                  btnText="Resend Invite"
                  onClick={handleResendRequest}
                  loading={isSendingInvite}
                />
                {/* <Button variant="outlined" btnText="Cancel" onClick={() => setResendInviteConfirmModalOpen(false)} /> */}
              </ModalButtonWrapper>
            </InviteModalContainer>
          }
        />
      )}

      {reminderConfirmModalOpen && (
        <Modal
          size="sm"
          open={reminderConfirmModalOpen}
          close={() => setReminderConfirmModalOpen(false)}
          title="Invite Again"
          children={
            <InviteModalContainer>
              <Title
                level="h6"
                text="Are you sure you want to send reminder?"
              />
              <ModalButtonWrapper>
                <Button
                  variant="contained"
                  btnText="Send reminder"
                  onClick={handleSendReminder}
                  loading={isSendingReminder}
                />
              </ModalButtonWrapper>
            </InviteModalContainer>
          }
        />
      )}

      {shareModalOpen && (
        <Modal
          size="md"
          open={shareModalOpen}
          close={() => setShareModalOpen(false)}
          title="Share link"
          children={
            <>
              <ShareModalContent shareLink={shareLink} />
            </>
          }
        />
      )}
    </>
  );
};

export default InterviewStatus;
