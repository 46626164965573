import React from "react";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { BackButtonHover } from "assets/styles/main.styles";

import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
const GoBackButton = ({ sx }) => {
  const navigate = useNavigate();
  return (
    <BackButtonHover>
      <Tooltip placement="top" title="Back">
        <ArrowBackIosNewSharpIcon
          onClick={() => navigate(-1)}
          fontSize="large"
          sx={{ ...sx, borderRight: "none", ":hover": { color: "#3f50b5" } }}
        />
      </Tooltip>
    </BackButtonHover>
  );
};

export default GoBackButton;
