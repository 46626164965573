import { Login } from "@mui/icons-material";
import MainLayout from "layout/MainLayout";
import MinimalLayout from "layout/MinimalLayout";
import {
  CandidateInterview,
  CandidateOverview,
  CandidateQuestions,
  FeedbackView,
  JobDetail,
  JobListing,
  Review,
  CompletedInverview,
  Uikit,
  UserSettings,
  RecentApplications,
  Answers,
} from "pages";
import { Categories } from "pages/common";
import { Subscription } from "pages/common";
import OrgListing from "pages/Admin/OrgListing";
import { ADMIN, CANDIDATE, RECRUITER } from "./constants";
import Instructions from "pages/Candidate/CandidateInterview/Instructions";
import CandidateKYC from "pages/Candidate/CandidateKYC";
import InterviewOverview from "pages/Candidate/CandidateInterview/InterviewOverview";
import RetakeInterview from "pages/Candidate/CandidateInterview/RetakeInterview";
import InterviewSettings from "pages/Candidate/InterviewSettings";
import TestPage from "pages/common/TestPage";
import CandidatesListing from "pages/Interviewer/CandidatesListing";
import TestingComponent from "pages/common/TestingComponent";

export const routes = [
  {
    path: "/org-listings",
    isAuthorized: true,
    child: null,
    exact: true,
    role: ADMIN,
    component: <OrgListing />,
  },
  {
    path: "/job-listings",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <JobListing />,
  },
  {
    path: "/job-detail",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <CandidatesListing />,
  },
  {
    path: "/review",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <Review />,
  },
  {
    path: "/candidate-details",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <Answers />,
  },
  {
    path: "/candidate-details#BasicAnswers",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <Answers />,
  },
  {
    path: "/view-feedback",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <FeedbackView />,
  },
  {
    path: "/candidate-overview",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <CandidateOverview />,
  },
  {
    path: "/interview-settings",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <InterviewSettings />,
  },
  {
    path: "/candidate-interview/kyc",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <CandidateKYC />,
  },
  {
    path: "/candidate-questions",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <CandidateQuestions />,
  },
  {
    path: "/interview-instructions",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <Instructions />,
  },
  {
    path: "/candidate-interview-retake",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <RetakeInterview />,
  },
  {
    path: "/interview-videos",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <InterviewOverview />,
  },
  {
    path: "/candidate-interview",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <CandidateInterview />,
  },
  {
    path: "/candidate-completed-interview",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <CompletedInverview />,
  },
  {
    path: "/user-settings",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <UserSettings />,
  },
  {
    path: "/categories",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <Categories />,
  },
  {
    path: "/subscription",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <Subscription />,
  },
  {
    path: "/dashboard",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <RecentApplications />,
    // component: <TestingComponent />,
  },
  {
    path: "/global-testing",
    isAuthorized: true,
    child: null,
    exact: true,
    role: RECRUITER,
    component: <TestPage />,
  },
];

export const candidateExclusiveRoutes = [
  {
    path: "/candidate-interview",
    isAuthorized: true,
    child: null,
    exact: true,
    role: CANDIDATE,
    component: <CandidateInterview />,
  },
];
