import { Grid, Skeleton } from "@mui/material";

export const Loader = () => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={70}
          variant="rounded"
          sx={{ bgcolor: "white" }}
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={100}
          variant="rounded"
          sx={{ bgcolor: "white" }}
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={200}
          variant="rounded"
          sx={{ bgcolor: "white" }}
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={250}
          variant="rounded"
          sx={{ bgcolor: "white" }}
        ></Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton
          animation="wave"
          width="100%"
          height={300}
          variant="rounded"
          sx={{ bgcolor: "white" }}
        ></Skeleton>
      </Grid>
    </Grid>
  );
};
