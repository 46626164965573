import React from "react";
import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { ModalContainer, MOdalContent, ModalClose } from "./modal-styles";

import Fade from "@mui/material/Fade";
import Title from "components/Title";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const Modal = ({ title, size, children, open, close, hideClose, actionWithTitle, sx }) => {
  const handleClose = (reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    return close;
  };

  return (
    <ModalContainer>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => handleClose(reason)}
        aria-describedby="close"
        fullWidth
        maxWidth={size}
        sx={sx}
      >
        {hideClose ? null : (
          <ModalClose>
            <IconButton aria-label="close" onClick={close}>
              <CloseIcon />
            </IconButton>
          </ModalClose>
        )}
        <MOdalContent hideClose={hideClose ? true : false}>
          {title && <Title level="h3" text={title} />}
          {actionWithTitle && actionWithTitle}
          {children}
        </MOdalContent>
      </Dialog>
    </ModalContainer>
  );
};

export default Modal;
