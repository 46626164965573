import useWindowSize from "hooks/useWindowSize";
import { Grid, Avatar, Rating } from "@mui/material";
import { Checkbox, Title } from "components";
import { ListFlex, NameDisplay, TimeDisplay } from "assets/styles/main.styles";
import { capitalizeWord, getTimePassed, getTimePassedinMob } from "utils";
import {
  statusColorCode,
  applicationStatuses,
  bgColorForStatus,
  textColorForStatus,
  selectedColorForStatus,
} from "./utils";
import VideoThumbnailPlaceholder from "assets/images/video-thumbnail.svg";
import { format } from "date-fns";
import { StatusBadgeSmall } from "./candidatelist.styles";

export const CandidateListItem = ({
  details,
  selectedCandidatesForReviewEmail,
  setSelectedCandidatesForReviewEmail,
  selectedCandidateId,
  handleCandidateClick,
}) => {
  const vidMeta = details?.AnswerPool[0]?.vidMeta
    ? JSON.parse(details?.AnswerPool[0]?.vidMeta)
    : {};
  let thumbnail = vidMeta?.vidMeta?.assets?.thumbnail;
  // let thumbnailIsPresent = isImagePresent(thumbnail);
  let statusId = details.status;
  let createdDate = details?.createdDate;
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  let timeDisplay = isMobile
    ? getTimePassedinMob(createdDate)
    : getTimePassed(createdDate);

  return (
    <div style={{ position: "relative", overflowX: "clip" }}>
      <Grid
        container
        className="candidate-card"
        onClick={() => handleCandidateClick(details.id)}
        sx={{
          backgroundColor:
            selectedCandidateId == details.id
              ? "#CFEFFF"
              : statusColorCode(applicationStatuses[statusId], "background"),
          border: statusColorCode(applicationStatuses[statusId], "border"),
          ":hover": {
            backgroundColor: statusColorCode(
              applicationStatuses[statusId],
              "hover"
            ),
          },
          "&:hover": {
            ".tag": {
              backgroundColor: selectedColorForStatus[statusId]?.bgColor
                ? `${selectedColorForStatus[statusId]?.bgColor} !important`
                : bgColorForStatus[statusId],
              color: selectedColorForStatus[statusId]?.color
                ? `${selectedColorForStatus[statusId]?.color} !important`
                : textColorForStatus[statusId],
            },
          },
        }}
      >
        {/* Avatar */}
        <Grid item>
          <Avatar
            srcSet={
              `${thumbnail}?w=10&h=8&fit=crop&auto=format&dpr=2 2x` ||
              VideoThumbnailPlaceholder
            }
            src={
              `${thumbnail}?w=10&h=10&fit=crop&auto=format` ||
              VideoThumbnailPlaceholder
            }
            alt={details?.name}
            style={{
              alignContent: "top",
              width: isMobile ? "50px" : "65px",
              height: isMobile ? "50px" : "65px",
            }}
            loading="lazy"
          />
        </Grid>
        {/* Name and time */}
        <Grid item>
          <Grid container flexDirection="column">
            <Grid item sx={{ padding: "0px 0px 0px 16px" }}>
              <ListFlex>
                <NameDisplay style={{ margin:isMobile ? "0px 0px 4px 0px": "0px 0px 10px 0px", fontSize: isMobile ? "12px" : "14px"  }}>
                  {capitalizeWord(details?.name)}
                </NameDisplay>
              </ListFlex>
              <TimeDisplay style={{ fontSize: isMobile ? "9px" : "12px", fontWeight: "500" }}>
                {createdDate && format(createdDate, "PPp")} ({timeDisplay})
              </TimeDisplay>
            </Grid>
            <Grid
              item
              sx={{
                padding: "2px 14px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {/* <Tag
                type={applicationStatuses[statusId]}
                label={applicationStatuses[statusId]}
                size="medium"
              /> */}
              <StatusBadgeSmall
                color={bgColorForStatus[statusId]}
                textColor={textColorForStatus[statusId]}
                style={{
                  marginTop: isMobile ? "-2px" : "2px",
                  backgroundColor:
                    selectedCandidateId == details.id &&
                    selectedColorForStatus[statusId]?.bgColor
                      ? selectedColorForStatus[statusId]?.bgColor
                      : bgColorForStatus[statusId],
                  color:
                    selectedCandidateId == details.id &&
                    selectedColorForStatus[statusId]?.color
                      ? selectedColorForStatus[statusId]?.color
                      : textColorForStatus[statusId],
                }}
                className="tag"
              >
                {applicationStatuses[statusId]}
              </StatusBadgeSmall>
              {statusId === 3 || statusId === 4 || statusId === 5 ? (
                <div style={{ display: "flex", alignItems: "flex-start" , marginTop: isMobile ? "-6px" : "0px"}}>
                  <Rating size="medium" readOnly max={1} defaultValue={5} />{" "}
                  <Title
                    level="label"
                    text={(details?.rating ? details?.rating : 0) + " / 5"}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Checkbox */}
      {statusId === 2 && (
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            textAlign: "right",
          }}
        >
          <Checkbox
            size="small"
            color="secondary"
            checked={selectedCandidatesForReviewEmail?.includes(details.id)}
            onChange={(e) => {
              if (e.target.checked) {
                e.target.checked = true;
                setSelectedCandidatesForReviewEmail((prev) => [
                  ...prev,
                  details.id,
                ]);
              } else {
                const updatedSelectedForReview =
                  selectedCandidatesForReviewEmail.filter(
                    (id) => id !== details.id
                  );
                setSelectedCandidatesForReviewEmail(updatedSelectedForReview);
              }
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      )}
    </div>
  );
};
