import { Box } from "assets/styles/main.styles";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Title, Radio } from "components";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useAlert } from "contexts/alert.context";
import Tooltip from "@mui/material/Tooltip";

export default function ShareModalContent({ shareLink }) {
  const { showAlert } = useAlert();
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      showAlert("Copied!", "success");
    } catch (err) {
      showAlert("Failed to copy!", "error");
    }
  };

  return (
    <>
      <Box isGrey>
        <Grid container>
          <Grid item xs={12} spacing={5}>
            <Title level="h6" text={shareLink} />
          </Grid>
        </Grid>
      </Box>
      <Grid item xs={12} spacing={5}>
        <Stack
          direction="row"
          spacing={2}
          style={{ justifyContent: "flex-end", marginTop: "20px" }}
        >
          <Tooltip title="Open in new tab" placement="top" arrow>
            <>
              <a href={shareLink} target="_blank">
                <OpenInNewOutlinedIcon
                  sx={{
                    color: "#c4c5c5",
                    marginRight: "8px",
                    fontSize: "28px",
                    ":hover": { color: "#33ACFF" },
                  }}
                />
              </a>
            </>
          </Tooltip>
          <Tooltip title="Copy To Clipboard" placement="top" arrow>
            <>
              <ContentCopyOutlinedIcon
                onClick={() => copyToClipboard(shareLink)}
                sx={{
                  color: "#c4c5c5",
                  marginRight: "8px",
                  fontSize: "28px",
                  cursor: "pointer",
                  ":hover": { color: "#33ACFF" },
                }}
              />
            </>
          </Tooltip>
          <Tooltip title="Share via Email" placement="top" arrow>
            <>
              <EmailShareButton url={shareLink}>
                <EmailOutlinedIcon
                  sx={{
                    color: "#c4c5c5",
                    marginRight: "8px",
                    fontSize: "28px",
                    ":hover": { color: "#FF0000" },
                  }}
                />
              </EmailShareButton>
            </>
          </Tooltip>
          <Tooltip title="Share via Whatsapp" placement="top" arrow>
            <>
              <WhatsappShareButton url={shareLink}>
                <WhatsAppIcon
                  sx={{
                    color: "#c4c5c5",
                    marginRight: "8px",
                    fontSize: "28px",
                    ":hover": { color: "#25D366" },
                  }}
                />
              </WhatsappShareButton>
            </>
          </Tooltip>
        </Stack>
      </Grid>
    </>
  );
}
