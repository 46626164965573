import React, { useState } from "react";
import { Button, Title, Tag, Modal, Checkbox } from "components";
import {
  RatingDisplay,
  FromWebsiteIcon,
  NameDisplay,
  Box,
  ListFlex,
  ButtonIcon,
  TimeDisplay,
  JobRoleDisplay,
} from "assets/styles/main.styles";
import { Stack, Rating } from "@mui/material";
import { format } from "date-fns";
import { Grid } from "@mui/material";
import { capitalizeWord, getTimePassed, getTimePassedinMob } from "utils";
import ResumePreview from "components/ResumePreview/ResumePreview";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import { appUrl } from "config/constants";
import VideoThumbnailPlaceholder from "assets/images/video-thumbnail.svg";
import { statuses } from "config/constants";
import { useAlert } from "contexts/alert.context";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// import Checkbox from "@mui/material/Checkbox";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const VideoInterviewListing = ({
  data,
  isFromDashboard = false,
  setSelectedCandidatesForReviewEmail,
  selectedCandidatesForReviewEmail,
}) => {
  data.sort((a, b) => {
    const dateA = new Date(a.CandidateList?.AnswerPool[0]?.createdDate);
    const dateB = new Date(b.CandidateList?.AnswerPool[0]?.createdDate);
    return dateB - dateA;
  });

  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [candidatesForReview, setCandidatesForReview] = useState([]);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [resumePath, setResumePath] = useState("");
  const [thumbnailIsPresent, setThumbnailIsPresent] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  // const [selectedForReview, setSelectedForReview] = useState([]);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const openResumePreview = (resumeFile) => {
    if (resumeFile) {
      setResumePath(resumeFile);
      setIsResumePreviewOpen(true);
    }
  };
  const handleResumePreview = () => {
    setIsResumePreviewOpen(false);
    setResumePath("");
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
      showAlert("Copied!", "success");
    } catch (err) {
      setCopySuccess("Failed to copy!");
      showAlert("Failed to copy!", "error");
    }
  };

  const isImagePresent = async (src) => {
    var image = new Image();
    //image.src = data?.vidMeta?.assets?.thumbnail;
    image.src = src;
    if (image.complete) {
      return true;
      // setThumbnailIsPresent(true);
    } else {
      image.onload = () => {
        return true;
        setThumbnailIsPresent(true);
      };

      image.onerror = () => {
        // setThumbnailIsPresent(false);
        return false;
      };
    }
    return false;
  };

  const statusColorCode = (arg, val) => {
    const Fit = {
      background: "#F5FFFA",
      border: "1px solid #EEF9FF",
      hover: "#D7FFEB",
    };
    const Rejected = {
      background: "#FDEDED",
      border: "1px solid #FCE5E5",
      hover: "#FFD6D6",
    };
    const PendingReview = {
      background: "#FFF8F1",
      border: "1px solid #FFF2E6",
      hover: "#FFFCF8",
    };
    const regular = {
      background: "#F4FAFD",
      border: "1px solid #DBF3FF",
      hover: "#CFEFFF",
    };
    // let m =  arg === "Fit" ? Fit : arg === "Rejected" ? Rejected : arg === "Pending Review" ? PendingReview : regular;
    let m = arg === "Fit" ? Fit : arg === "Rejected" ? Rejected : regular;
    return m[val];
  };

  function generateShareLink(id, roleId) {
    const link = appUrl + "/candidate-videos?id=" + id + "&role=" + roleId;
    setShareLink(link);
  }

  const handleSelectionForReview = (e, id) => {
    e.target.checked = true;
    setSelectedCandidatesForReviewEmail((prev) => [...prev, id]);
    // handleSelectionForReviewEmail([...selectedForReview, id]);
  };

  return (
    <>
      <>
        {data.map((item, index) => {
          const vidMeta = item?.CandidateList?.AnswerPool[0]?.vidMeta
            ? JSON.parse(item?.CandidateList?.AnswerPool[0]?.vidMeta)
            : {};
          //  console.log(B, "vidMeta")
          let thumbnail = vidMeta?.vidMeta?.assets?.thumbnail;
          let thumbnailIsPresent = isImagePresent(thumbnail);
          // console.log(thumbnailIsPresent, "thumbnailIsPresent")
          let resumeFile = "";
          if (item?.CandidateList?.resumeDir.length > 0) {
            const resumeObj = JSON.parse(
              item?.CandidateList?.resumeDir[0].docMeta
            );
            resumeFile = resumeObj.Location;
          }
          const statusId = item.status;

          let fitRatingForRole = 5;
          if (item.roleMoreInfo) {
            const jobRoleMoreInfo = JSON.parse(item?.jorole?.roleMoreInfo);
            // fitRatingForRole = jobRoleMoreInfo?.fit;
            let ratingCutOff = item?.jobrole?.ratingCutOff;
            let totalCutoff =
              item?.jobrole?.interviewQuestions?.reduce(
                (a, b) => a + b.cutOf,
                0
              ) / item?.jobrole?.interviewQuestions.length;
            fitRatingForRole = ratingCutOff > 0 ? ratingCutOff : totalCutoff;
          }
          let createdDate =
            item?.CandidateList?.AnswerPool[
              item?.CandidateList?.AnswerPool.length - 1
            ]?.createdDate;
          //console.log(format(createdDate, "PPp"))
          let timeDisplay = isMobile
            ? getTimePassedinMob(createdDate)
            : getTimePassed(createdDate);
          // console.log(format(createdDate, "PPp"), timeDisplay);
          let candidate = item?.CandidateList;
          return (
            <>
              {isMobile ? (
                //Mobile
                <Grid
                  container
                  sx={{
                    backgroundColor: statusColorCode(
                      statuses[statusId],
                      "background"
                    ),
                    border: statusColorCode(statuses[statusId], "border"),
                    ":hover": {
                      backgroundColor: statusColorCode(
                        statuses[statusId],
                        "hover"
                      ),
                    },
                  }}
                >
                  <Grid item xs="auto">
                    <div>
                      {candidate?.fromWebsite && (
                        <div
                          style={{
                            position: "absolute",
                            padding: "4px 12px",
                            zIndex: 1,
                          }}
                        >
                          <FromWebsiteIcon title="Added from website">
                            www
                          </FromWebsiteIcon>
                        </div>
                      )}
                      <img
                        srcSet={
                          thumbnailIsPresent
                            ? `${thumbnail}?w=10&h=8&fit=crop&auto=format&dpr=2 2x`
                            : VideoThumbnailPlaceholder
                        }
                        src={
                          thumbnailIsPresent
                            ? `${thumbnail}?w=10&h=10&fit=crop&auto=format`
                            : VideoThumbnailPlaceholder
                        }
                        alt={candidate?.name}
                        height={80}
                        style={{
                          objectFit: "cover",
                          borderRadius: "10px",
                          padding: "6px",
                          minHeight: "80px",
                          minWidth: "142px",
                        }}
                        loading="lazy"
                        onClick={() => {
                          // navigate("/candidate-details", {
                          //   state: {
                          //     candidate_id: candidate?.id,
                          //     roleId: item.roleId,
                          //   },
                          // });
                          navigate(`/job-detail`, {
                            state: {
                              id: item.roleId,
                              roleName: item?.jobrole?.roleName,
                              candidate_id: candidate?.id,
                            },
                          });
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid container item xs>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{ padding: "0px 2px" }}
                        direction="column"
                        onClick={() => {
                          // navigate("/candidate-details", {
                          //   state: {
                          //     candidate_id: candidate?.id,
                          //     roleId: item.roleId,
                          //   },
                          // });
                          navigate(`/job-detail`, {
                            state: {
                              id: item.roleId,
                              roleName: item?.jobrole?.roleName,
                              candidate_id: candidate?.id,
                            },
                          });
                        }}
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <NameDisplay
                            margin={"8px 0px 0px 0px"}
                            style={{
                              whiteSpace: "nowrap",
                              height: "20px",
                              width: "130px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {capitalizeWord(candidate?.name)}{" "}
                          </NameDisplay>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {statusId === 3 ||
                            statusId === 4 ||
                            statusId === 5 ? (
                              <RatingDisplay>
                                {item.review?.rating}
                                <div style={{ padding: "4px" }}>
                                  <StarRoundedIcon
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                      color: "#faaf00",
                                    }}
                                  />
                                </div>
                              </RatingDisplay>
                            ) : (
                              <div></div>
                            )}
                            {item?.candidateList?.resumeDir.length > 0 ? (
                              <ButtonIcon>
                                <DescriptionOutlinedIcon
                                  fontSize="small"
                                  onClick={() => {
                                    if (resumeFile != null) {
                                      openResumePreview(resumeFile);
                                    }
                                  }}
                                />
                              </ButtonIcon>
                            ) : (
                              ""
                            )}
                          </div>
                        </Grid>

                        <JobRoleDisplay margin={"2px 0px 12px 0px"}>
                          {capitalizeWord(item?.jobrole?.roleName)}
                        </JobRoleDisplay>
                        <TimeDisplay>
                          {createdDate && format(createdDate, "PPp")}
                          <div>({timeDisplay})</div>
                        </TimeDisplay>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                //Desktop
                <div style={{ position: "relative" }}>
                  <Grid
                    container
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // navigate("/candidate-details", {
                      //   state: {
                      //     candidateId: candidate?.id,
                      //     roleId: item.roleId,
                      //   },
                      // });
                      navigate(`/job-detail`, {
                        state: {
                          id: item.roleId,
                          roleName: item?.jobrole?.roleName,
                          candidate_id: candidate?.id,
                        },
                      });
                    }}
                    sx={{
                      backgroundColor: statusColorCode(
                        statuses[statusId],
                        "background"
                      ),
                      border: statusColorCode(statuses[statusId], "border"),
                      ":hover": {
                        backgroundColor: statusColorCode(
                          statuses[statusId],
                          "hover"
                        ),
                      },
                    }}
                  >
                    <Grid item xs="auto">
                      <img
                        srcSet={
                          `${thumbnail}?w=10&h=8&fit=crop&auto=format&dpr=2 2x` ||
                          VideoThumbnailPlaceholder
                        }
                        src={
                          `${thumbnail}?w=10&h=10&fit=crop&auto=format` ||
                          VideoThumbnailPlaceholder
                        }
                        alt={candidate?.name}
                        height={100}
                        style={{
                          objectFit: "cover",
                          borderRadius: "10px",
                          padding: "6px",
                          minWidth: "178px",
                          minHeight: "100px",
                        }}
                        loading="lazy"
                      />
                    </Grid>
                    <Grid container item xs>
                      <Grid container>
                        <Grid item xs={6} sx={{ padding: "4px 16px" }}>
                          <ListFlex>
                            <NameDisplay margin={"10px 0px"}>
                              {capitalizeWord(candidate?.name)}
                            </NameDisplay>
                            <JobRoleDisplay margin={"12px 10px"}>
                              {capitalizeWord(item?.jobrole?.roleName)}
                            </JobRoleDisplay>
                            {candidate?.fromWebsite && (
                              <FromWebsiteIcon title="Added from website">
                                www
                              </FromWebsiteIcon>
                            )}
                          </ListFlex>
                          <TimeDisplay>
                            {createdDate && format(createdDate, "PPp")}
                            <div>({timeDisplay})</div>
                          </TimeDisplay>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            padding: "8px 8px",
                            textAlign: "right",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {statusId === 3 ||
                          statusId === 4 ||
                          statusId === 5 ? (
                            <>
                              <Tag
                                type={statuses[statusId]}
                                label={statuses[statusId]}
                                size="large"
                              />
                              <Rating
                                size="medium"
                                name={candidate.name}
                                value={item.review?.rating}
                                precision={0.5}
                                readOnly
                              />
                            </>
                          ) : (
                            <div></div>
                          )}
                        </Grid>

                        <Grid
                          xs={12}
                          sx={{
                            padding: "0px 8px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {statusId === 2 && (
                            <Button
                              startIcon={<VideoCameraFrontOutlinedIcon />}
                              size="small"
                              btnText="Review"
                              variant="contained"
                              width={"100px"}
                              onClick={() => {
                                // navigate("/candidate-details", {
                                //   state: {
                                //     candidateId: candidate?.id,
                                //     roleId: item?.roleId,
                                //   },
                                // });
                                navigate(`/job-detail`, {
                                  state: {
                                    id: item.roleId,
                                    roleName: item?.jobrole?.roleName,
                                    candidate_id: candidate?.id,
                                  },
                                });
                              }}
                              // onClick={() =>
                              //   navigate("/review", {
                              //     state: {
                              //       candidateId: item.id,
                              //       roleId,
                              //       fullName: item.name,
                              //       roleDetails: {
                              //         roleName: item.jobrole.roleName,
                              //         isKycRequired: item.jobrole.isKycRequired,
                              //       },
                              //     },
                              //   })
                              // }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {statusId === 2 && !isFromDashboard && (
                    <div style={{ position: "absolute", top: "0", right: "0" }}>
                      <Checkbox
                        size="small"
                        color="secondary"
                        // value={item.id}
                        checked={selectedCandidatesForReviewEmail?.includes(
                          candidate.id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleSelectionForReview(e, candidate.id);
                          } else {
                            const updatedSelectedForReview =
                              selectedCandidatesForReviewEmail.filter(
                                (id) => id !== candidate.id
                              );
                            setSelectedCandidatesForReviewEmail(
                              updatedSelectedForReview
                            );
                          }
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  )}

                  {item?.candidateList?.resumeDir.length > 0 && (
                    <ButtonIcon
                      style={{
                        position: "absolute",
                        top: "70px",
                        left: "205px",
                      }}
                    >
                      <DescriptionOutlinedIcon fontSize="small" />
                      <a
                        href="javascript:void(0)"
                        title="Copy To Clipboard"
                        style={{
                          zIndex: 2,
                        }}
                        onClick={() => {
                          if (resumeFile != null) {
                            openResumePreview(resumeFile);
                          }
                        }}
                      >
                        Resume
                      </a>
                    </ButtonIcon>
                  )}
                </div>
              )}
            </>
          );
        })}
      </>

      {shareModalOpen && (
        <Modal
          size="md"
          open={shareModalOpen}
          close={() => setShareModalOpen(false)}
          title="Share link"
          children={
            <Box isGrey>
              <Grid container>
                <Grid item xs={12}>
                  <Title level="h6" text={shareLink} />
                </Grid>

                <Stack
                  direction="row"
                  spacing={2}
                  style={{ justifyContent: "flex-end" }}
                >
                  {/* <Grid item xs={2}>
									<a href={shareLink} target="_blank">
										 <Button variant="contained" btnText="Open" style={{ display: "inline-block" }} /> 
									</a>
								</Grid> */}
                  <a
                    href={shareLink}
                    target="_blank"
                    title="Send WhatsApp Message"
                  >
                    <OpenInNewOutlinedIcon
                      sx={{
                        color: "#c4c5c5",
                        marginRight: "10px",
                        ":hover": { color: "#33ACFF" },
                      }}
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    title="Copy To Clipboard"
                    onClick={() => copyToClipboard(shareLink)}
                  >
                    <ContentCopyOutlinedIcon
                      sx={{
                        color: "#c4c5c5",
                        marginRight: "10px",
                        ":hover": { color: "#33ACFF" },
                      }}
                    />
                  </a>

                  <EmailShareButton url={shareLink}>
                    <EmailOutlinedIcon
                      sx={{
                        color: "#c4c5c5",
                        marginRight: "10px",
                        ":hover": { color: "#FF0000" },
                      }}
                    />
                  </EmailShareButton>
                  <WhatsappShareButton url={shareLink}>
                    <WhatsAppIcon
                      sx={{
                        color: "#c4c5c5",
                        marginRight: "10px",
                        ":hover": { color: "#25D366" },
                      }}
                    />
                  </WhatsappShareButton>
                </Stack>
              </Grid>
            </Box>
          }
        />
      )}
      <ResumePreview
        isResumePreviewOpen={isResumePreviewOpen}
        handleResumePreview={handleResumePreview}
        resumePath={resumePath}
      />
    </>
  );
};

export default VideoInterviewListing;
