import React, { useEffect, useRef, useState } from "react";
import logo from "../../../assets/images/logo.svg";
import { Button } from "components";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { webAuth } from "config/auth-config";
import { useNavigate } from "react-router-dom";
import {
  getCandidateInterviewDetails,
  getCandidateInterviewLoadDetails,
} from "config/api.service";
import { useInterviewDataContext } from "contexts/interviewData";
import useFullscreen from "hooks/useFullscreen";
import { useInterview } from "contexts/interview.context";

const InterviewSettings = ({}) => {
  const [audioInputs, setAudioInputs] = useState([]);
  const [videoInputs, setVideoInputs] = useState([]);
  const [selectedAudioInput, setselectedAudioInput] = useState("");
  const [selectedVideoInput, setselectedVideoInput] = useState("");
  const { setInterviewData, setAnswerData } = useInterviewDataContext();
  const [interviewUserData, setInterviewUserData] = useState({});
  const [mediaDevicesGranted, setmediaDevicesGranted] = useState("loading");

  const { screenShareStreamRef } = useInterview();

  const id = sessionStorage.getItem("uniqueInterviewId");
  const { toggle } = useFullscreen();

  const videoRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (mediaDevicesGranted) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const audioInputDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        const videoInputDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setAudioInputs(audioInputDevices);
        setVideoInputs(videoInputDevices);
        setselectedAudioInput(audioInputDevices[0].deviceId);
        setselectedVideoInput(videoInputDevices[0].deviceId);
        localStorage.setItem(
          "deviceIds",
          JSON.stringify({
            videoDeviceId: videoInputDevices[0].deviceId,
            audioDeviceId: audioInputDevices[0].deviceId,
          })
        );
      });
    }
  }, [mediaDevicesGranted]);

  const handleAudioChange = (e) => {
    setselectedAudioInput(e.target.value);
  };

  const handleVideoChange = (e) => {
    console.log(e.target.value);

    setselectedVideoInput(e.target.value);
  };

  const initializeCamera = async () => {
    try {
      const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));

      const stream = await navigator.mediaDevices.getUserMedia({
        video: deviceIds ? deviceIds.videoDeviceId : true,
        audio: true,
      });

      setmediaDevicesGranted(true);

      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
      setmediaDevicesGranted(false);
    }
  };

  useEffect(() => {
    initializeCamera();
  }, []);

  useEffect(() => {
    if (selectedVideoInput) {
      navigator.mediaDevices
        .getUserMedia({
          video: { deviceId: selectedVideoInput },
          audio: { deviceId: selectedAudioInput },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            localStorage.setItem(
              "deviceIds",
              JSON.stringify({
                videoDeviceId: selectedVideoInput,
                audioDeviceId: selectedAudioInput,
              })
            );
          }
        })
        .catch((error) => {
          console.error("Error accessing media devices.", error);
        });
    }
  }, [selectedAudioInput, selectedVideoInput]);

  const checkUser = async (email) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        webAuth.client.userInfo(token, (err, result) => {
          if (err) {
            console.log(err);
          } else {
            if (result.email != email) {
              localStorage.clear();
              navigate(`/login-failed`, { replace: true });
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCandidateInfo = (data) => {
    // localStorage.clear();
    // localStorage.removeItem("uniqueInterviewId");
    const isSubmitted = data?.CandidateList?.InterviewPool[0]?.isSubmitted;
    const Interviewstatus = data?.CandidateList?.InterviewPool[0]?.status;
    checkUser(data?.CandidateList?.email);
    if (isSubmitted) {
      alert("You have already submitted the interview!");
    } else {
      if (Interviewstatus == 6) {
        (async () =>
          await getCandidateInterviewLoadDetails(id, (response) => {
            const answers = response.map((item) => {
              return {
                vidMeta: JSON.parse(item.vidMeta).vidMeta,
                isRecorded: true,
                isSubmitted: true,
                question: JSON.parse(item.vidMeta).question,
                retake: JSON.parse(item.vidMeta).retake,
                qId: item.qId,
              };
            });
            setAnswerData(answers);
          }))();
        navigate("/candidate-interview", { replace: true });
      }
      setInterviewUserData(data);
      setInterviewData(data); // storing in the custom hook to be used in the other pages
    }
    // localStorage.setItem("interviewData", JSON.stringify(data));
  };

  useEffect(() => {
    (async () => {
      if (id) {
        await getCandidateInterviewDetails(id, handleCandidateInfo);
      }
    })();
  }, [id]);

  const handleAgreeTermsCondition = async () => {
    const isFromWebsite = interviewUserData?.CandidateList?.fromWebsite;
    if (isFromWebsite) {
      if (interviewUserData?.jobrole?.isKycRequired) {
        navigate("/candidate-interview/kyc");
        toggle();
        return;
      }
      navigate("/interview-instructions");
    } else {
      navigate("/candidate-questions");
    }
  };

  useEffect(() => {
    async function startScreenCapture() {
      if (screenShareStreamRef.current) {
        return;
      }

      try {
        // Capture the screen using getDisplayMedia
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        if (stream) {
          screenShareStreamRef.current = stream;
        }
      } catch (error) {
        console.error("Error accessing screen capture: ", error);
      }
    }

    // startScreenCapture();
  }, []);

  return (
    <div style={{ minHeight: "100vh", overflow: "hidden" }}>
      <div
        style={{
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
          height: "5rem",
          padding: window.innerWidth > 600 ? "1rem 0 0" : "1rem 1rem 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            width: window.innerWidth > 600 ? "40vw" : "100%",
            height: window.innerWidth > 600 ? "3rem" : "2.5rem",
          }}
        >
          {/* <img style={{ height: "100%", objectFit: "contain" }} src={logo} alt="logo" /> */}
          <p style={{ fontSize: 20, fontWeight: "bold" }}>Device Settings</p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minimumHeight: "92.5vh",
          padding: window.innerWidth > 600 ? "3rem" : "1rem",
          paddingBottom: "3rem",
        }}
      >
        <div
          style={{
            width: window.innerWidth > 600 ? "40vw" : "100%",
            aspectRatio: 1920 / 1080,
            marginBottom: window.innerWidth > 600 ? "2rem" : "1rem",
            backgroundColor: "grey",
            zIndex: "-1",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            muted
            width="100%"
            height="100%"
            style={{ transform: "scaleX(-1)", objectFit: "cover" }}
          />
          {/* <canvas ref={canvasRef} width="640" height="480" style={{ display: "none" }}></canvas> */}
        </div>

        <p
          style={{
            fontSize: window.innerWidth > 600 ? 24 : 18,
            fontWeight: "bold",
            marginBottom: "2rem",
            width: "100%",
            textAlign: window.innerWidth > 600 ? "center" : "start",
          }}
        >
          Check your audio and video settings
        </p>

        {mediaDevicesGranted === "loading" ? null : mediaDevicesGranted ? (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: "3rem",
                width: window.innerWidth > 600 ? "40vw" : "100%",
                gap: "2rem",
                flexDirection: window.innerWidth > 600 ? "row" : "column",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="Microphone">Microphone</InputLabel>
                <Select
                  labelId="Microphone"
                  id="Microphone"
                  value={selectedAudioInput ? selectedAudioInput : ""}
                  defaultValue={selectedAudioInput ? selectedAudioInput : ""}
                  label="Microphone"
                  onChange={handleAudioChange}
                  InputLabelProps={{ shrink: selectedAudioInput }}
                >
                  {audioInputs.map((item) => (
                    <MenuItem key={item} value={item.deviceId}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="Camera">Camera</InputLabel>
                <Select
                  labelId="Camera"
                  id="Camera"
                  value={selectedVideoInput ? selectedVideoInput : ""}
                  defaultValue={selectedVideoInput ? selectedVideoInput : ""}
                  label="Camera"
                  onChange={handleVideoChange}
                  InputLabelProps={{ shrink: selectedVideoInput }}
                >
                  {videoInputs.map((item) => (
                    <MenuItem key={item} value={item.deviceId}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <Button
                variant="contained"
                btnText="Join Now"
                onClick={handleAgreeTermsCondition}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "3rem",
              width: window.innerWidth > 600 ? "40vw" : "100%",
              gap: "2rem",
              flexDirection: window.innerWidth > 600 ? "row" : "column",
            }}
          >
            <p style={{ color: "red", fontWeight: "bold" }}>
              Camera access was denied previously. Please enable it in your
              browser settings.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InterviewSettings;
