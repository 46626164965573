import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { Button, Input, Title } from "components";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import InviteCandidate from "../components/InviteCandidate";
import { inviteCandidate } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { errorMessages } from "config/constants";
import {
  Box,
  CandidateWrapper,
  CandidateItemList,
  CandidateItem,
} from "assets/styles/main.styles";

const textTemplateMessage =
  "Congratulations on being shortlisted for the position. We kindly request you to attend a video interview by clicking on the provided link in the email you will receive. Please ensure that you attend the interview before it expires.";

const AddCandidate = ({ handleClose, roleId, sheetData }) => {
  const [inviteFieldError, setInviteFieldError] = useState({});
  const [candidateList, setCandidateList] = useState([{ id: 0 }]);
  const { showAlert } = useAlert();
  const [fieldError, setFieldError] = useState({
    0: { fullName: null, email: null, countryCode: null, mobileNumber: null },
  });
  const [inviteDetails, setInviteDetails] = useState({
    message: textTemplateMessage,
    expireIn: "3",
    isResumeRequired: true,
    sendRemider: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addItemToList = () => {
    const id = new Date().getTime();
    setCandidateList([...candidateList, { id }]);
    setFieldError({
      ...fieldError,
      [candidateList.length]: {
        fullName: null,
        email: null,
        countryCode: null,
        mobileNumber: null,
      },
    });
    console.log(id, "add");
  };

  function handleDeleteClick(index) {
    let temp = [...candidateList];
    temp.splice(index, 1);
    setCandidateList(temp);
  }

  const updateCandidateData = (e, index) => {
    validateData(e, index);
    let temp = [...candidateList];
    temp[index] = { ...temp[index], [e.target.name]: e.target.value };
    setCandidateList(temp);
  };

  const handleInviteCandidatesList = (res) => {
    showAlert("Added Candidates Successfully", "success");
    handleClose();
  };

  const handleErrorOnSubmit = (errorMessage) => {
    showAlert(errorMessage || "Something went wrong", "error");
  };

  const handleSubmit = async (type) => {
    let temp = { ...fieldError };
    let haveError = false;
    for (let i = 0; i < candidateList.length; i++) {
      let tempData = candidateList[i];
      if (tempData.fullName === undefined || tempData.fullName === "") {
        temp[i] = { ...temp[i], fullName: errorMessages.required };
        haveError = true;
      }
      if (tempData.email === undefined || tempData.email === "") {
        temp[i] = { ...temp[i], email: errorMessages.required };
        haveError = true;
      }
      // if (tempData.countryCode === undefined || tempData.countryCode === "") {
      //   temp[i] = { ...temp[i], countryCode: "" };
      //   haveError = true;
      // }
      if (tempData.mobileNumber === undefined || tempData.mobileNumber === "") {
        temp[i] = { ...temp[i], mobileNumber: errorMessages.required };
        haveError = true;
      }
    }
    let inviteError = { ...inviteFieldError };
    if (inviteDetails.message.trim() === "") {
      inviteError = { ...inviteError, message: errorMessages.required };
      haveError = true;
    } else {
      delete inviteError.message;
    }
    if (inviteDetails.expireIn === null || inviteDetails.expireIn === "") {
      inviteError = { ...inviteError, expireIn: errorMessages.required };
      haveError = true;
    } else {
      delete inviteError.expireIn;
    }
    if (haveError) {
      setInviteFieldError(inviteError);
      setFieldError(temp);
      showAlert("some input fields have invalid data", "error");
      return;
    }
    const data = {
      data: candidateList.map((item) => {
        const splittedName = item.fullName.split(" ");
        return {
          email: item.email,
          name: item.fullName,
          firstName: splittedName.length > 1 ? splittedName[0] : item.fullName,
          lastName: splittedName.length > 1 ? splittedName[1] : "",
          phoneNumber: item.mobileNumber,
          whatsappNo: item.mobileNumber,
          resumeMeta: "",
        };
      }),
      roleId: Number(roleId),
      request: type !== "add",
      inviteDetails,
    };
    type === "add" && delete data.inviteDetails;
    setIsSubmitting(true);
    await inviteCandidate(
      data,
      handleInviteCandidatesList,
      handleErrorOnSubmit
    );
    setIsSubmitting(false);
  };

  const handleInviteCandidates = (name, value) => {
    setInviteDetails({ ...inviteDetails, [name]: value });
  };

  useEffect(() => {
    if (sheetData?.length) {
      setCandidateList([...candidateList, ...sheetData]);
    }
  }, [sheetData]);

  const validateData = (e, index) => {
    let temp = { ...fieldError };
    if (e.target.name === "fullName") {
      temp[index] = {
        ...temp[index],
        fullName: e.target.value ? null : errorMessages.required,
      };
    } else if (e.target.name === "email") {
      temp[index] = {
        ...temp[index],
        email: e.target.value ? null : errorMessages.required,
      };
    }
    // else if (e.target.name === "countryCode") {
    //   temp[index] = {
    //     ...temp[index],
    //     countryCode: e.target.value ? null : "",
    //   };
    // }
    else if (e.target.name === "mobileNumber") {
      temp[index] = {
        ...temp[index],
        mobileNumber: e.target.value ? null : errorMessages.required,
      };
    }
    setFieldError(temp);
  };

  return (
    <div>
      <CandidateWrapper>
        <Box isGrey>
          <CandidateItemList>
            {candidateList.map((item, i) => (
              <CandidateItem key={item.id}>
                {i > 0 ? (
                  <Divider
                    variant="middle"
                    flexItem
                    sx={{ margin: "20px 0 10px 0" }}
                  />
                ) : (
                  ""
                )}
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className="candidate-list"
                >
                  <Grid item xs={12} md={4}>
                    <Title level="label" text="Full Name" />
                    <Input
                      name="fullName"
                      value={item.fullName}
                      onChange={(e) => updateCandidateData(e, i)}
                      error={!!fieldError[i]["fullName"]}
                      errorText={fieldError[i]["fullName"]}
                      onBlur={(e) => validateData(e, i)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Title level="label" text="Email" />
                    <Input
                      name="email"
                      value={item.email}
                      onChange={(e) => updateCandidateData(e, i)}
                      error={!!fieldError[i]["email"]}
                      errorText={fieldError[i]["email"]}
                      onBlur={(e) => validateData(e, i)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container spacing={1} alignItems="center">
                      {/* <Grid item xs={3}>
                        <Input
                          type="number"
                          placeholder={"+91"}
                          name="countryCode"
                          value={item.countryCode}
                          onChange={(e) => updateCandidateData(e, i)}
                          error={!!fieldError[i]["countryCode"]}
                          errorText={fieldError[i]["countryCode"]}
                          onBlur={(e) => validateData(e, i)}
                        />
                      </Grid> */}
                      <Grid item xs={8}>
                        <Title level="label" text="Phone" />
                        <Input
                          type="number"
                          placeholder={"Enter mobile number"}
                          name="mobileNumber"
                          value={item.mobileNumber}
                          onChange={(e) => updateCandidateData(e, i)}
                          error={!!fieldError[i]["mobileNumber"]}
                          errorText={fieldError[i]["mobileNumber"]}
                          onBlur={(e) => validateData(e, i)}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteClick(i)}
                          className="delete-button"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CandidateItem>
            ))}
          </CandidateItemList>
        </Box>
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-end"
          alignItems={"flex-end"}
          spacing={2}
        >
          <Button
            startIcon={<AddIcon />}
            btnText="Add More Candidate"
            onClick={addItemToList}
            className="add-button"
          />
        </Grid>
      </CandidateWrapper>
      <InviteCandidate
        handleRequestCandidates={handleInviteCandidates}
        inviteCandidateData={inviteDetails}
        handleUpdate={handleSubmit}
        handleResend={() => {}}
        inviteFieldError={inviteFieldError}
        isSubmiting={isSubmitting}
        isEdit={false}
      />
    </div>
  );
};

export default AddCandidate;
