import { useState, useEffect } from "react";
import QuestionAnswers from "../components/QuestionAnswers";
import { Carousel } from "@mantine/carousel";
import { Grid } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Modal, Title } from "components";
import { BlockMargin, ReviewModalHeader } from "assets/styles/main.styles";
import VideoCard from "../components/VideoCard";
import { useUser } from "contexts/user.context";
import { useAlert } from "contexts/alert.context";
import { useNavigate } from "react-router-dom";
import { saveReview, getScreenshot } from "config/api.service";

const InterviewAnswers = ({
  candidate,
  reviewData,
  handleReviewData,
  videoCardModalOpen,
  setVideoCardModalOpen,
  setReloadDetails,
}) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestionDetails, setSelectedQuestionDetails] = useState({});
  const [selectedQuestionNo, setSelectedQuestionNo] = useState(0);
  const [comment, setComment] = useState("");
  const [remainingCharCount, setRemainingCharCount] = useState(0);
  const [currentQuestionId, setcurrentQuestionId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [screenshotSelected, setscreenshotSelected] = useState(null);
  const [showScreenshot, setshowScreenshot] = useState(false);
  const [screenshots, setscreenshots] = useState([]);
  const [factorRatings, setFactorRatings] = useState({
    attitude: 0,
    communication: 0,
    professionalism: 0,
    teamfit: 0,
    culturalfit: 0,
    businessacumen: 0,
    readingFromScreen: false,
  });

  useEffect(() => {
    if (videoCardModalOpen) {
      setIsModalOpen(true);
      setSelectedQuestionDetails({});
      setSelectedQuestionNo(0);
    }
  }, [videoCardModalOpen]);

  useEffect(() => {
    async function getScreenshots() {
      const data = {
        inviteId: candidate.InvitePool ? candidate.InvitePool[0].inviteId : "",
      };

      await getScreenshot(data, successCallback, errorCallback);

      function successCallback(res) {
        setscreenshots(res);
      }

      function errorCallback(err) {
        console.log(err);
      }
    }

    candidate.InvitePool && getScreenshots();
  }, [candidate]);

  const handleSuccess = () => {
    showAlert("Successfully reviewed", "success");
    setIsModalOpen(false);
    setVideoCardModalOpen(false);
    if (candidate.roleId == undefined) {
      navigate(-1);
    }
  };

  const handleComment = (e) => {
    if (e.target.value.length <= 500) {
      setComment(e.target.value);
      setRemainingCharCount(e.target.value.length);
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (let reviewElement of reviewData) {
      if (reviewElement.rating === undefined || !reviewElement.rating) {
        isValid = false;
      }
    }
    Object.keys(factorRatings).forEach((key) => {
      if (factorRatings[key] === 0) {
        isValid = false;
      }
    });

    if (isValid) {
      const data = {
        reviewData,
        comment,
        factorRatings,
      };
      data.candidateId = candidate.candidateId;
      data.roleId = candidate.roleId;
      setIsSubmitting(true);

      await saveReview(data, handleSuccess, handleFailedRequest);
      setIsSubmitting(false);
      setReloadDetails(true);
    } else {
      showAlert(
        "Please rate all the questions, factors and also add comment",
        "error"
      );
    }
  };

  const handleFailedRequest = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
    setIsModalOpen(false);
    setVideoCardModalOpen(false);
  };

  function onChangeQuestion(currentQuestion) {
    currentQuestion
      ? setcurrentQuestionId(currentQuestion.qId)
      : setcurrentQuestionId(null);
  }

  if (candidate?.AnswerPool?.length == 0) return <></>;

  return (
    <div style={{ padding: "1rem" }}>
      <Title level="h4" text="Video Interview Answers" />
      <BlockMargin size={10} />
      <Grid container spacing={4}>
        {candidate?.AnswerPool?.map((item, i) => {
          const vidMeta = JSON.parse(item?.vidMeta);
          const data = {
            qId: item.qId,
            ...vidMeta,
          };
          return (
            <Grid
              item
              sm={12}
              md={6}
              lg={4}
              xl={3}
              key={i}
              sx={{
                width: "100%",
              }}
            >
              <QuestionAnswers
                data={data}
                reviewData={reviewData}
                currentQuestionNo={i + 1}
                feedback={candidate?.Feedback}
                handleModalOpen={() => {
                  setIsModalOpen(true);
                  setSelectedQuestionDetails(data);
                  setSelectedQuestionNo(i + 1);
                }}
                isReviewerLogin={false}
              />
            </Grid>
          );
        })}
      </Grid>
      {isModalOpen && (
        <Modal
          size="md"
          open={isModalOpen}
          close={() => {
            setIsModalOpen(false);
            setVideoCardModalOpen(false);
          }}
          title={<ReviewModalHeader></ReviewModalHeader>}
          // sx={{ zIndex: 1000 }}
          children={
            <>
              <div style={{ position: "relative" }}>
                <VideoCard
                  selectedQuestionDetails={selectedQuestionDetails}
                  selectedQuestionNo={selectedQuestionNo}
                  totalQuestions={
                    candidate?.jobrole?.interviewQuestions?.length
                  }
                  interviewDetails={candidate?.AnswerPool}
                  fromFeedback={candidate?.Feedback > 0}
                  reviewData={reviewData}
                  handleReviewData={handleReviewData}
                  comment={comment}
                  handleComment={handleComment}
                  handleSubmit={handleSubmit}
                  remainingCharCount={remainingCharCount}
                  onChangeQuestion={onChangeQuestion}
                  setFactorRatings={setFactorRatings}
                  factorRatings={factorRatings}
                  status={candidate?.InterviewPool[0]?.status}
                />
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default InterviewAnswers;
