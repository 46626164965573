import { useState, useEffect } from "react";
import { ReviewCardContainer, VideoContainer } from "assets/styles/main.styles";
import { Button, Title } from "components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardMedia from "@mui/material/CardMedia";

const SingleInterviewQuestion = ({
  selectedQuestionDetails,
  selectedQuestionNo,
  interviewDetails,
}) => {
  const [data, setData] = useState(selectedQuestionDetails);
  const [serialNumber, setSerialNumber] = useState(selectedQuestionNo);
  const handleNext = () => {
    if (interviewDetails.length > serialNumber) {
      setSerialNumber(serialNumber + 1);
    }
  };

  const handlePrev = () => {
    setSerialNumber(serialNumber - 1);
  };

  const interviews = interviewDetails.map((item) => ({
    qId: item.qId,
    ...JSON.parse(item?.vidMeta),
  }));

  useEffect(() => {
    const newCurrentData = interviews[serialNumber - 1];
    if (newCurrentData) {
      setData(newCurrentData);
    }
  }, [serialNumber]);

  return (
    <>
      {Object.keys(data).length > 0 && (
        <ReviewCardContainer>
          <>
            <Title
              style={{
                marginRight: "50px",
                marginBottom: "10px",
              }}
              level="h4"
              lineheight={1.5}
              text={`${serialNumber}. ${data?.question}`}
            />

            <Grid
              container
              direction={"row"}
              justifyContent="space-between"
              alignItems={"flex-end"}
            >
              <Stack direction="row" spacing={1} marginTop={"10px"}>
                <Button
                  variant={"outlined"}
                  btnText="Prev"
                  startIcon={<ArrowBackOutlinedIcon />}
                  onClick={handlePrev}
                />
                <Button
                  variant={"contained"}
                  btnText="Next"
                  onClick={handleNext}
                  endIcon={<ArrowForwardIcon />}
                />
              </Stack>
            </Grid>
            {data?.vidMeta?.assets?.mp4 && (
              <Grid sx={{ marginTop: "10px" }}>
                <VideoContainer>
                  <CardMedia
                    component="video"
                    controls
                    src={data?.vidMeta?.assets?.mp4}
                    autoPlay
                  />
                </VideoContainer>
              </Grid>
            )}
          </>
        </ReviewCardContainer>
      )}
    </>
  );
};

export default SingleInterviewQuestion;
