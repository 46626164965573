import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Table } from "components";
import {
  TableButtonContainer,
  TableContainer,
} from "assets/styles/main.styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDateDMY } from "utils";
import {format } from "date-fns";
const SubscriptionListingTable = ({
  isCompleteList = true,
  filter,
  hideSearch,
  handleClickEdit,
  handleDelete,
  reloadList,
  data,
  loading,
}) => {
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState([]);

  useEffect(() => {
    setSubscriptionData(data);
  }, [data]);

  // "id": 7,
  //               "plan": "One Dollar Plan",
  //               "description": "1 dollar for 3 months",
  //               "orgId": 70,
  //               "subscriptionDate": "2024-06-19T05:32:32.000Z",
  //               "expiryDate": "2024-06-19T05:32:32.000Z",
  //                "amt": "1",
  //                "currency": "USD",
  //                "sign": "$",
  const columns = React.useMemo(
    () => [
      {
        Header: "Plan",
        accessor: "plan",
      },
      {
        Header: "Amount",
        accessor: "amt",
        Cell: (row) => {
          const sign = row.row.original.sign
          return (
            <>
              {row.row.original.sign}{row.row.original.amt}
            </>
          );
        },
      },
      {
        Header: "Subscription Date",
        accessor: "subscriptionDate",
        Cell: (row) => {
          const date = new Date(row.row.original.subscriptionDate);
          return (
            <>
              {row.row.original.subscriptionDate
                ?  format(row.row.original.subscriptionDate, "PPp")
                : ""}
            </>
          );
        },
      },
      {
        Header: "Expiry Date",
        accessor: "expiryDate",
        Cell: (row) => {
          const date = new Date(
            new Date(row.row.original.expiryDate).getTime() +
              row.row.original.duration * 24 * 60 * 60 * 1000
          );
          return (
            <>
              {row.row.original.expiryDate
                ?  format(date, "PPp")
                : ""}
            </>
          );
        },
      },
      {
        id: "action",
        Header: "",
        accessor: "Action",
        Cell: (row) => {
          return (
            <>
              {row.row.original.isAssigned
                ? null
                : row.row.original.name !== "General" && (
                    <TableButtonContainer isFlex>
                      <Button
                        className="editbtn"
                        size="small"
                        btnText="Renew"
                        variant="contained"
                        onClick={() => handleClickEdit(row.row.original)}
                        
                      />
                      {/* <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        className="deletebtn"
                        variant="secondary"
                        onClick={() => handleDelete(row.row.original.id)}
                        sx={{
                          "& .MuiButton-startIcon": {
                            marginRight: 0,
                          },
                        }}
                      /> */}
                    </TableButtonContainer>
                  )}
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [navigate, subscriptionData]
  );

  return (
    <div className="table-container">
      <TableContainer>
        <Table
          columns={columns}
          data={isCompleteList ? subscriptionData : subscriptionData.slice(0, 5)}
          isLoading={loading}
          defaultFilter={filter}
          hideSearch={hideSearch}
          hideFilter
          numOfLoadingBarCols={3}
        />
      </TableContainer>
    </div>
  );
};

export default SubscriptionListingTable;
