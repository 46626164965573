import React, { useState, useEffect } from "react";
import { Title, Input, Button } from "components";
import { Grid, Rating, CardMedia, Container } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  VideoContainer,
  ReviewCardContainer,
  RemainingCharCount,
  ModalCommentWrapper,
  BlockMargin,
  FlexRow,
} from "assets/styles/main.styles";
import Stack from "@mui/material/Stack";
import { RatingValues } from "config/constants";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MessageIcon from "@mui/icons-material/Message";
import Icon from "@mui/material/Icon";

function VideoCard({
  selectedQuestionDetails,
  selectedQuestionNo,
  interviewDetails,
  fromFeedback,
  reviewData,
  handleReviewData,
  comment,
  handleComment,
  handleSubmit,
  remainingCharCount,
  onChangeQuestion,
  setFactorRatings,
  factorRatings,
  status = 2,
}) {
  const [currentData, setCurrentData] = useState(selectedQuestionDetails);
  const [serialNumber, setSerialNumber] = useState(selectedQuestionNo);
  const [newRating, setNewRating] = useState(0);
  const [hover, setHover] = React.useState(-1);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [currentReviewData, setCurrentReviewData] = useState(null);
  const [reviewCompleted, setReviewCompleted] = useState(false);

  useEffect(() => {
    if (status == 3 || status == 5) {
      setReviewCompleted(true);
    }
  }, [status]);

  const interviews = interviewDetails.map((item) => ({
    qId: item.qId,
    ...JSON.parse(item?.vidMeta),
  }));

  useEffect(() => {
    onChangeQuestion(isLastQuestion ? null : currentData);
  }, [currentData, isLastQuestion]);

  useEffect(() => {
    if (selectedQuestionNo > 0) {
      setSerialNumber(selectedQuestionNo);
      const currentReviewData = reviewData.find(
        (item) => item.questionId === currentData?.qId
      );
      setNewRating(currentReviewData?.rating);
      setCurrentReviewData(currentReviewData);
    } else {
      let lastReviewedQuestion = reviewData.findIndex((item) => {
        return item.rating == 0;
      });
      if (lastReviewedQuestion == -1) {
        lastReviewedQuestion = 0;
      }
      if (lastReviewedQuestion < reviewData.length) {
        setSerialNumber(lastReviewedQuestion + 1);
      }
    }
  }, [selectedQuestionNo]);

  useEffect(() => {
    const newCurrentData = interviews[serialNumber - 1];
    if (newCurrentData) {
      setCurrentData(newCurrentData);
      if (fromFeedback) {
        setNewRating(newCurrentData?.rating);
      } else {
        const currentReviewData = reviewData.find(
          (item) => item.questionId === newCurrentData?.qId
        );
        setNewRating(currentReviewData?.rating);
        setCurrentReviewData(currentReviewData);
      }
    }
    checkIfLastQuestion(serialNumber);
  }, [serialNumber]);

  const handleSave = (givenRating) => {
    handleReviewData(currentData?.qId, givenRating);
  };

  const handleNext = () => {
    if (interviewDetails.length > serialNumber) {
      setSerialNumber(serialNumber + 1);
      if (!fromFeedback) {
        setNewRating(0);
      }
    }
    checkIfLastQuestion(serialNumber + 1);
  };

  const handlePrev = () => {
    setSerialNumber(serialNumber - 1);
    if (!fromFeedback) {
      setNewRating(0);
    }
  };

  const checkIfLastQuestion = (currentQuestion) => {
    if (interviewDetails.length < currentQuestion) {
      setIsLastQuestion(true);
    }
  };

  const backFromComment = () => {
    setSerialNumber(serialNumber - 1);
    setIsLastQuestion(false);
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${RatingValues[value]}`;
  }

  const commentPage = () => {
    return (
      <ModalCommentWrapper>
        <Title level="h6" text="Other Rating Factors" />
        <div
          style={{
            display: "grid",
            gap: 15,
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <FactorRating
            heading="Attitude"
            value={factorRatings.attitude}
            handleSelection={(e) => {
              if (!reviewCompleted) {
                setFactorRatings({
                  ...factorRatings,
                  attitude: Number(e.target.textContent),
                });
              }
            }}
          />
          <FactorRating
            heading="Communication"
            value={factorRatings.communication}
            handleSelection={(e) =>
              setFactorRatings({
                ...factorRatings,
                communication: Number(e.target.textContent),
              })
            }
          />

          <FactorRating
            heading="Professionalism"
            value={factorRatings.professionalism}
            handleSelection={(e) =>
              setFactorRatings({
                ...factorRatings,
                professionalism: Number(e.target.textContent),
              })
            }
          />
          <FactorRating
            heading="Team fit"
            value={factorRatings.teamfit}
            handleSelection={(e) =>
              setFactorRatings({
                ...factorRatings,
                teamfit: Number(e.target.textContent),
              })
            }
          />
          <FactorRating
            heading="Cultural Fit"
            value={factorRatings.culturalfit}
            handleSelection={(e) =>
              setFactorRatings({
                ...factorRatings,
                culturalfit: Number(e.target.textContent),
              })
            }
          />
          <FactorRating
            heading="Business Acumen"
            value={factorRatings.businessacumen}
            handleSelection={(e) =>
              setFactorRatings({
                ...factorRatings,
                businessacumen: Number(e.target.textContent),
              })
            }
          />
        </div>
        <BlockMargin />

        <Title level="h6" text="Candidate feedback" />
        <Grid container spacing={2} alignItems="flex-end">
          {/* <Icon
            color="disabled"
            sx={{
              position: "absolute",
              left: 0,
              width: "5em",
              height: "5em",
              top: "14.5rem",
            }}
          >
            <MessageIcon sx={{ fontSize: "4rem" }} />
          </Icon> */}
          <Grid item xs={12}>
            <Input
              placeholder="Example:  Comments by: [ Your Name ] | Comments about the candidate."
              name="overall-comments"
              isMulti
              value={comment}
              onChange={handleComment}
              minRows={2}
              maxLength={500}
              readOnly={reviewCompleted}
            />
            <RemainingCharCount>
              {remainingCharCount ?? 0} / 500
            </RemainingCharCount>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            <Grid item xs="auto">
              <FormControlLabel
                readOnly={reviewCompleted}
                onChange={() =>
                  setFactorRatings({
                    ...factorRatings,
                    readingFromScreen: !factorRatings.readingFromScreen,
                  })
                }
                control={<Checkbox />}
                label={
                  <Title
                    level="label"
                    text="Check this box if the candidate seems to be reading from the screen."
                  />
                }
              />
            </Grid>
            <Grid
              item
              xs
              alignSelf="flex-end"
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "row-reverse",
              }}
            >
              <Button
                variant="contained"
                btnText="Submit Feedback"
                onClick={handleSubmit}
                disabled={reviewCompleted}
              />
              <Button
                variant="outlined"
                btnText="Prev"
                startIcon={<ArrowBackOutlinedIcon />}
                onClick={backFromComment}
              />
            </Grid>
          </Grid>
        </Grid>
      </ModalCommentWrapper>
    );
  };

  return (
    <>
      {Object.keys(currentData).length > 0 && (
        <ReviewCardContainer>
          {!isLastQuestion ? (
            <>
              <Title
                style={{
                  marginRight: "50px",
                  marginBottom: "10px",
                }}
                level="h4"
                lineheight={1.5}
                text={`${serialNumber}. ${currentData?.question}`}
              />

              <Grid
                container
                direction={"row"}
                justifyContent="space-between"
                alignItems={"flex-end"}
              >
                <Stack direction="row" spacing={1}>
                  <Rating
                    size="large"
                    sx={{
                      fontSize: "2.5rem",
                    }}
                    name="hover-feedback"
                    value={newRating ?? 0}
                    precision={1}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setNewRating(newValue);
                      handleSave(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    readOnly={fromFeedback || reviewCompleted}
                  />
                  {newRating !== null && (
                    <span style={{ paddingTop: "8px", fontSize: "1.5rem" }}>
                      {RatingValues[hover !== -1 ? hover : newRating]}
                    </span>
                  )}
                </Stack>

                <Stack direction="row" spacing={1} marginTop={"10px"}>
                  <Button
                    variant={"outlined"}
                    btnText="Prev"
                    startIcon={<ArrowBackOutlinedIcon />}
                    onClick={handlePrev}
                    disabled={serialNumber < 2}
                  />
                  <Button
                    variant={"contained"}
                    btnText="Next"
                    onClick={handleNext}
                    endIcon={<ArrowForwardIcon />}
                    disabled={
                      !reviewCompleted
                        ? newRating == null || newRating === 0
                        : interviewDetails.length == serialNumber
                        ? true
                        : false
                    }
                  />
                </Stack>
              </Grid>
              {currentData?.vidMeta?.assets?.mp4 && (
                <Grid sx={{ marginTop: "10px" }}>
                  <VideoContainer>
                    <CardMedia
                      component="video"
                      controls
                      src={currentData?.vidMeta?.assets?.mp4}
                      autoPlay
                    />
                  </VideoContainer>
                </Grid>
              )}
            </>
          ) : (
            <>{commentPage()}</>
          )}
        </ReviewCardContainer>
      )}
    </>
  );
}

export default VideoCard;

const FactorRating = ({ heading, value, handleSelection }) => {
  const ratings = Array.from({ length: 5 }, (_, i) => i + 1);
  const colors = ["#E30000", "#FE7900", "#FFCF15", "#85CC03", "#00BF11"];
  return (
    <div>
      <Title text={heading} level="label" />
      <FlexRow gap={5}>
        {ratings.map((i) => (
          <Box
            sx={{
              height: "30px",
              width: "30px",
              backgroundColor: value === i ? colors[i - 1] : "#D7D7D7",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ":hover": {
                backgroundColor: colors[i - 1],
              },
            }}
            onClick={handleSelection}
          >
            {i}
          </Box>
        ))}
      </FlexRow>
    </div>
  );
};
