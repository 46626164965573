import { Outlet } from "react-router-dom";
import Container from "@mui/material/Container";
import { Layout, LayoutMain, ContainerMargin } from "./layout.styles";
import { useLocation } from "react-router-dom";
import { Footer, Header, HeaderAdmin } from "components";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import HeaderMobile from "components/HeaderMobile";

export default function MainLayout() {
  const location = useLocation();
  const { pathname } = location;
  const url = pathname.split("/");
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const navigate = useNavigate();

  let token = localStorage.getItem("idToken");

  function checkTokenValidation() {
    if (token == null) {
      return false;
    }
    let decoded = jwtDecode(token);
    if (Date.now() >= decoded?.exp * 1000) {
      return false;
    }
    return true;
  }

  if (!checkTokenValidation()) {
    localStorage.clear();
    navigate("/");
  }

  return (
    <Layout>
      {isMobile ? (
        <HeaderMobile isAdmin={url[1] === "org-listings"} />
      ) : url[1] === "org-listings" ? (
        <HeaderAdmin />
      ) : (
        <Header />
      )}
      {url[1] === "candidate-interview" ||
      url[1] === "interview-instructions" ? (
        <>
          <Container maxWidth={false} style={{ padding: 0 }}>
            <LayoutMain>
              <Outlet />
            </LayoutMain>
          </Container>
        </>
      ) : (
        <ContainerMargin>
          <Container maxWidth={false} style={{ padding: 0 }}>
            <LayoutMain>
              <Outlet />
            </LayoutMain>
          </Container>
        </ContainerMargin>
      )}
      <Footer />
    </Layout>
  );
}
