import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Dialog, Table, Input } from "components";
import { TableButtonContainer, Box } from "assets/styles/main.styles";
import { Grid } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { getOrganizations, generateApiFromAdmin } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { daysInBetween } from "../../../../../utils";
import { format } from "date-fns";
import { subscriptionStatus } from "../../../../../config/constants";
import IconButton from "@mui/material/IconButton";
import ApiIcon from "@mui/icons-material/Api";
// import ViewTripleModal from "../ViewTripleModal";

const OrganizationListingTable = ({
  isCompleteList = true,
  handleClickEdit,
  handleClickOpen,
  isTableUpdated,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const [showAPIGenModal, setShowAPIGenModal] = useState(false);
  const [selectedOrgDetails, setSelectedOrgDetails] = useState(null);
  const [apiKey, setApiKey] = useState("");

  const handleOpenAPIModal = (orgDetails) => {
    if (orgDetails?.apiKey != null) {
      setApiKey(orgDetails?.apiKey);
    } else {
      setApiKey("");
    }
    setShowAPIGenModal(true);
    setSelectedOrgDetails(orgDetails);
  };

  const handleButtonGeneration = async () => {
    generateApiFromAdmin(
      {
        email: selectedOrgDetails?.orgEmail,
        id: selectedOrgDetails?.id,
      },
      successCallback,
      errorCallback
    );
  };

  const successCallback = (data) => {
    setApiKey(data?.apiKey);
    showAlert("API generated successfully", "success");
  };

  const errorCallback = (error) => {
    showAlert(error, "error");
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "orgName",
        width: 250,
        Cell: (row) => {
          return (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => handleClickEdit(row.row.original.id)}
            >
              <img
                src={row.row.original.orgLogo}
                alt="logo"
                style={{
                  width: "auto",
                  height: "32px",
                  maxHeight: "15px",
                  objectFit: "contain",
                }}
              />
              {row.row.original.orgName}
            </div>
          );
        },
      },
      {
        Header: "Subscribed Plan",
        accessor: "planName",
        Cell: ({ row, value }) => {
          return <div>{value ? value : ""}</div>;
        },
      },
      {
        Header: "Active Job Roles",
        accessor: "activeJobRoles",
        Cell: ({ row }) => {
          return <>{row.original.activeJobRoles}</>;
        },
      },
      {
        Header: "Job Applications",
        accessor: "jobApplications",
        Cell: ({ row }) => {
          return <>{row.original.jobApplications}</>;
        },
      },
      {
        Header: "Video Interviews",
        accessor: "videoInterviews",
        Cell: ({ row, value }) => {
          return <>{value}</>;
        },
      },

      {
        Header: "Status",
        accessor: "subStatus",
        Cell: ({ row, value }) => {
          return <div>{value ? subscriptionStatus[value] : ""}</div>;
        },
      },
      {
        Header: "Renewal Date",
        accessor: "subEndDate",
        Cell: (row) => {
          const date = new Date(row.row.original.subEndDate);
          return <>{row.row.original.subEndDate ? format(date, "PPp") : ""}</>;
        },
      },
      {
        Header: "Days To Expiry",
        Cell: ({ row }) => {
          const days = daysInBetween(
            new Date(),
            new Date(row.original.subEndDate)
          );
          return <>{row.original.subEndDate ? days + " days" : ""}</>;
        },
      },
      {
        Header: "Email",
        accessor: "orgEmail",
        Cell: (row) => {
          return <>{row.row.original.orgEmail}</>;
        },
      },
      {
        Header: "Created On",
        accessor: "createdDate",
        Cell: (row) => {
          const date = new Date(row.row.original.createdDate);
          return <>{row.row.original.createdDate ? format(date, "PPp") : ""}</>;
        },
      },
      {
        id: "action",
        Header: "",
        accessor: "Action",
        width: 100,
        Cell: (row) => {
          return (
            <>
              {row.row.original.isAssigned ? null : (
                <TableButtonContainer isFlex>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleClickEdit(row.row.original.id)}
                    size="small"
                    color="primary"
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton
                    aria-label="api"
                    onClick={() => handleOpenAPIModal(row.row.original)}
                    size="small"
                    color="default"
                  >
                    <ApiIcon />
                  </IconButton>
                </TableButtonContainer>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [navigate, data]
  );

  const handleData = (res) => {
    // console.log("result", res);
    const response = res.map((res) => {
      return {
        ...res,
        planName: res.subscriptionPlan?.name,
        subStartDate: res.subscription?.startDate,
        subEndDate: res.subscription?.endDate,
        subStatus: res.subscription?.status,
      };
    });
    setData(response);
  };

  useEffect(() => {
    getAllOrganizations();
  }, [isTableUpdated]);

  const getAllOrganizations = async () => {
    setLoading(true);
    await getOrganizations(handleData, () =>
      showAlert("Failed to get organizations", "error")
    );
    setLoading(false);
  };

  return (
    <div className="table-container">
      <Table
        columns={columns}
        data={isCompleteList ? data : data.slice(0, 5)}
        isLoading={loading}
        // hidePagination={isCompleteList ? false : true}
        hideFilter
        numOfLoadingBarCols={3}
      />
      {showAPIGenModal && (
        <Dialog
          size="md"
          open={showAPIGenModal}
          close={() => setShowAPIGenModal(false)}
          title={"API key generation"}
          children={
            <>
              <Box isGrey>
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={10}>
                    <Input
                      label={""}
                      placeholder="API Key"
                      name="api_key"
                      value={apiKey}
                      readOnly
                      className="apiKeyInput"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      btnText="Generate"
                      onClick={handleButtonGeneration}
                    />
                  </Grid>
                </Grid>
                {apiKey == "" && (
                  <p>Please click generate button to generate an API key</p>
                )}
              </Box>
            </>
          }
        />
      )}
    </div>
  );
};

export default OrganizationListingTable;
