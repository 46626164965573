import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
  padding: 8px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 65px;
  z-index: 999;
  position: relative;
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  margin-right: 40px;
`;

export const MenuListing = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 10px;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;
