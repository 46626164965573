import { webAuth } from "config/auth-config";
import useAuthValidate from "hooks/useAuthValidate";
import { createContext, useContext, useEffect, useState } from "react";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const { userDetails, error, isLoading, isLoadingRef } = useAuthValidate();
  return (
    <UserContext.Provider
      value={{ userDetails, error, isLoading, isLoadingRef }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
