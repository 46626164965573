import { useUser } from "contexts/user.context";
import useLocalStorage from "hooks/useLocalStorage";
import useRoleAccess from "hooks/useRoleAccess";
import { Loading } from "pages";
import { useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ADMIN, CANDIDATE, RECRUITER } from "./constants";

export const PrivateRouteComponent = ({ child, roles }) => {
  const { userDetails, isLoading } = useUser();
  const { hasAccess } = useRoleAccess(roles);

  const PAGE = useCallback(() => {
    if (isLoading) {
      return <Loading />;
    } else if (userDetails && hasAccess) {
      return <>{child}</>;
    }
    return <Navigate to={"/"} />;
  }, [child, hasAccess, isLoading, userDetails]);
  return <PAGE />;
};

export const GuestRouteComponent = ({ child }) => {
  const { userDetails, isLoading } = useUser();
  const { hasAccess } = useRoleAccess([RECRUITER, CANDIDATE, ADMIN]);
  const [value] = useLocalStorage("accessToken");
  const inviteId = sessionStorage.getItem("uniqueInterviewId");
  const location = useLocation();

  const PAGE = useCallback(() => {
    if (isLoading) {
      return <Loading />;
    } else if (userDetails && hasAccess) {
      if (userDetails?.userRoles.includes(CANDIDATE)) {
        if (inviteId) {
          return <Navigate to={"/candidate-overview"} />;
        }
      }
      if (userDetails?.userRoles.includes(ADMIN)) {
        return <Navigate to={"/org-listings"} />;
      }
      if (userDetails?.userRoles.includes(RECRUITER)) {
        return <Navigate to={"/dashboard"} />;
      }
      // else {
      //    return <Navigate to={"/candidate-overview"} />;
      // }
    } else if (value && !userDetails) {
      return <Loading />;
    }
    return <>{child}</>;
  }, [isLoading, userDetails, hasAccess, value, child]);

  return (
    <>
      <PAGE />
    </>
  );
};
