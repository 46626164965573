import styled from "styled-components";

export const Layout = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
export const LayoutMain = styled.div`
  padding: 0 0;
`;

export const ContainerMargin = styled.div`
  padding-top: 64px;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;
