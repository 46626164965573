import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appUrl } from "config/constants";
import { Button, Table, Tag, Title, Modal } from "components";
import {
  TableButtonContainer,
  FlexRow,
  TableContainer,
  ActiveJobBadge,
  InActiveJobBadge,
  ActionLink,
  Box,
} from "assets/styles/main.styles";
import { Tooltip, Grid, Stack } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import ShareIcon from "@mui/icons-material/Share";
import {
  getJobRoles,
  getSubmittedCandidatesCountByJobrole,
} from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { formatDateDMY } from "utils";
import Chip from "@mui/material/Chip";
import { format } from "date-fns";
import ShareModalContent from "./ShareModalContent";
// import ViewTripleModal from "../ViewTripleModal";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import { encrypt } from "utils/encrypt";

const CountBadge = ({ text, color, isRounded = true }) => {
  if (typeof text == "number" && text < 1) {
    return;
  }
  return (
    <>
      {text != undefined && (
        <Chip
          color={color}
          size="small"
          label={`${text}`}
          style={{
            marginRight: "5px",
            borderRadius: isRounded ? "50%" : "16px",
          }}
          sx={{
            "& .css-wjsjww-MuiChip-label": {
              overflow: "unset",
            },
          }}
        />
      )}
    </>
  );
};

const SUPPORT_CONTENT = (
  <div style={{ marginLeft: "20px" }}>
    <FlexRow>
      <CountBadge color="success" text="Pending reviews" isRounded={false} />
      <CountBadge color="primary" text="New candidates" isRounded={false} />
      <CountBadge
        color="warning"
        text="Video Interview attempted"
        isRounded={false}
      />
    </FlexRow>
  </div>
);

const JobListingTable = ({
  isCompleteList = true,
  filter,
  hideSearch,
  hideFilter,
  handleClickOpen,
  handleClickEdit,
  listUpdated,
  handleClickEmbed,
  handleCloseEmbed,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget, 223);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    console.log("closw", 223);
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");

  const [copySuccess, setCopySuccess] = useState("");
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
      showAlert("Copied!", "success");
    } catch (err) {
      setCopySuccess("Failed to copy!");
      showAlert("Failed to copy!", "error");
    }
  };
  const generateShareLink = (roleId) => {
    let cipheredData = encrypt(roleId);
    const link = appUrl + "/info-candidate?id=" + cipheredData;
    setShareLink(link);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Job Title",
        accessor: "roleName",
        Cell: (row) => {
          const {
            submittedCandidates,
            newCandidates,
            reviewedCandidates,
            displayOnWeb,
          } = row.row.original;
          return (
            <>
              {displayOnWeb ? (
                <Tooltip
                  placement="bottom-start"
                  title="Displayed on career page"
                  arrow
                >
                  <ActiveJobBadge></ActiveJobBadge>
                </Tooltip>
              ) : (
                <Tooltip
                  placement="bottom-start"
                  title="Hidden on website career page"
                  arrow
                >
                  <InActiveJobBadge></InActiveJobBadge>
                </Tooltip>
              )}
              <div
                className="table-nav-link"
                onClick={() =>
                  navigate(`/job-detail`, {
                    state: {
                      id: row.row.original.id ?? row.row.original.roleId,
                      roleName: row.row.original.roleName,
                    },
                  })
                }
                style={{ marginRight: "5px" }}
              >
                {row.row.original.roleName}
              </div>
              {/* <CountBadge color="success" text={submittedCandidates} />
              <CountBadge color="primary" text={newCandidates} />
              <CountBadge color="warning" text={reviewedCandidates} /> */}
            </>
          );
        },
      },
      {
        Header: "Candidates",
        accessor: "assignedCandidates",
      },
      {
        Header: "Video Interview Completed",
        accessor: "submittedCandidates",
        width: 200,
        Cell: (row) => {
          const { submittedCandidates, newCandidates, reviewedCandidates } =
            row.row.original;
          return (
            <div sx={{ textAlign: "center" }}>
              {/* <CountBadge color="success" text={submittedCandidates} /> */}
              {submittedCandidates}
            </div>
          );
        },
      },

      {
        Header: "Created on",
        accessor: "createdDate",
        Cell: (row) => {
          const date = new Date(row.row.original.createdDate);
          return (
            <>
              {row.row.original.createdDate
                ? format(row.row.original.createdDate, "PPp")
                : ""}
            </>
          );
        },
      },
      {
        Header: "Category",
        accessor: "category.name",
      },
      {
        id: "action",
        Header: "",
        accessor: "Action",
        Cell: (row) => {
          return (
            <>
              {row.row.original.isAssigned ? null : (
                <TableButtonContainer isFlex>
                  {/* <Button
										startIcon={
											<ShareOutlinedIcon
												sx={{
													color: "#c4c5c5",
													marginTop: "5px",
													":hover": { color: "#3f50b5" },
												}}
											/>
										}
										size="small"
										btnText=""
										className="showOnHover"
										variant="text"
										title="Job Apply Link"
										onClick={() => {
											const { id } = row.row.original;
											setShareModalOpen(true);
											generateShareLink(id);
										}}
									/> */}

                  <a
                    className="showOnHover"
                    href="javascript:void(0)"
                    onClick={() => {
                      const { id } = row.row.original;
                      setShareModalOpen(true);
                      generateShareLink(id);
                    }}
                    title="Job Apply Link"
                  >
                    <ShareOutlinedIcon
                      sx={{
                        color: "#c4c5c5",
                        marginTop: "5px",
                        marginRight: "20px",
                        ":hover": { color: "#3f50b5" },
                      }}
                    />
                  </a>
                  <a
                    className="showOnHover"
                    href="javascript:void(0)"
                    onClick={() => handleClickEdit(row.row.original.id)}
                    title="Edit"
                  >
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: "#c4c5c5",
                        marginTop: "5px",
                        ":hover": { color: "#3f50b5" },
                      }}
                    />
                  </a>

                  {/* <Button
										startIcon={<CodeIcon />}
										size="small"
										btnText="Embed Button"
										onClick={() => handleClickEmbed(row.row.original.id)}
									/> */}
                </TableButtonContainer>
              )}
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [navigate, data]
  );

  const handleJobList = (res) => {
    const assignedJobs = res?.assignedJobList?.map((item) => {
      return {
        ...item,
        isAssigned: true,
      };
    });

    const createdJobs = res?.createdJobList?.map((item) => {
      return {
        ...item,
        isAssigned: false,
      };
    });

    let temp = [...assignedJobs, ...createdJobs];
    setData(temp);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getJobRoles(handleJobList, () =>
        showAlert("Failed to get jobs", "error")
      );
      setLoading(false);
    })();
  }, [listUpdated]);

  return (
    <div className="table-container">
      <TableContainer>
        <Table
          columns={columns}
          data={isCompleteList ? data : data.slice(0, 5)}
          isLoading={loading}
          hidePagination
          defaultFilter={filter}
          hideSearch={hideSearch}
          hideFilter
          numOfLoadingBarCols={3}
          dropdownFilter={true}
          // supportContent={SUPPORT_CONTENT}
        />
      </TableContainer>
      {shareModalOpen && (
        <Modal
          size="md"
          open={shareModalOpen}
          close={() => setShareModalOpen(false)}
          title="Share link"
          children={<ShareModalContent shareLink={shareLink} />}
          // children={
          //   <Box isGrey>

          //     <Grid container justifyContent="space-between">
          //       <Grid item xs={8}>
          //         <Title level="h6" text={shareLink} />
          //       </Grid>

          //       <Stack
          //         direction="row"
          //         spacing={2}
          //         style={{ justifyContent: "flex-end" }}
          //       >
          //         <a
          //           href="javascript:void(0)"
          //           title="Copy To Clipboard"
          //           onClick={() => copyToClipboard(shareLink)}
          //         >
          //           <ContentCopyOutlinedIcon
          //             sx={{
          //               color: "#c4c5c5",
          //               marginRight: "10px",
          //               ":hover": { color: "#33ACFF" },
          //             }}
          //           />
          //         </a>

          //         <EmailShareButton url={shareLink}>
          //           <EmailOutlinedIcon
          //             sx={{
          //               color: "#c4c5c5",
          //               marginRight: "10px",
          //               ":hover": { color: "#FF0000" },
          //             }}
          //           />
          //         </EmailShareButton>
          //         <WhatsappShareButton url={shareLink}>
          //           <WhatsAppIcon
          //             sx={{
          //               color: "#c4c5c5",
          //               marginRight: "10px",
          //               ":hover": { color: "#25D366" },
          //             }}
          //           />
          //         </WhatsappShareButton>
          //       </Stack>
          //     </Grid>
          //   </Box>
          // }
        />
      )}
    </div>
  );
};

export default JobListingTable;
