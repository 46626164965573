import React, { useState, useEffect } from "react";
import {
  PageHeader,
  DashBoardCard,
  Table,
  Button,
  Card,
  Title,
  Modal,
  Ta,
} from "components";
import { Box, LoadingContainer, ButtonIcon } from "assets/styles/main.styles";

import { useLocation, useNavigate } from "react-router-dom";
import {
  getRecentVideoInterviews,
  getInvitedCandidates,
  getRecentApplications,
  testEmail,
} from "config/api.service";
import { format } from "date-fns";
import { Grid } from "@mui/material";
import InterviewSvg from "assets/images/illustrations/interview.svg";
import { useAlert } from "contexts/alert.context";
import { getJobRoles } from "config/api.service";
import DashboardCountCards from "components/DashboardCountCards";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import JobListingSidePanel from "../JobListing/components/JobListingSidePanel";
import VideoInterviewListing from "../../Interviewer/JobListing/components/VideoInterviewListing";
import RecentApplicationListing from "../JobListing/components/RecentApplicationListing";
import useWindowSize from "hooks/useWindowSize";
import "components/SkeletonLoaders/TableLoader/loader.css";

export const Loader = ({ numOfLoadingBarCols = 3 }) => {
  return (
    <>
      {new Array(numOfLoadingBarCols).fill(0).map((_, index) => {
        return (
          <Grid
            container
            spacing={2}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <Grid item xs="auto">
              <div
                class="bar"
                style={{
                  borderRadius: "10px",
                  minWidth: "178px",
                  minHeight: "100px",
                  backgroundColor: "#e7e7e7",
                }}
              ></div>
            </Grid>
            <Grid container item xs>
              <div
                class="bar"
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                  padding: "6px",
                  backgroundColor: "#e7e7e7",
                  width: "100%",
                }}
              ></div>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const RecentApplications = () => {
  const [data, setData] = useState([]);
  const [applicantsData, setApplicantsData] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobRoleData, setJobRoleData] = useState([]);
  const { showAlert } = useAlert();
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const [
    selectedCandidatesForReviewEmail,
    setSelectedCandidatesForReviewEmail,
  ] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const handleJobList = (res) => {
    const assignedJobs = res?.assignedJobList?.map((item) => {
      return {
        ...item,
        isAssigned: true,
      };
    });
    const createdJobs = res?.createdJobList?.map((item) => {
      return {
        ...item,
        isAssigned: false,
      };
    });
    let temp = [...assignedJobs, ...createdJobs];
    const newData = temp.filter((item) => item.displayOnWeb !== false);
    setJobRoleData(newData);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getJobRoles(handleJobList, () =>
        showAlert("Failed to get jobs", "error")
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    GetRecentVideoInterviews();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div sx={{ p: 3 }}>{children}</div>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const GetRecentVideoInterviews = async () => {
    setIsDataLoading(true);
    await getRecentVideoInterviews(
      (data) => {
        setData(data);
        setIsDataLoading(false);
      },
      (error) => {
        setError(error);
        setIsDataLoading(false);
      }
    );
    await getRecentApplications(
      (data) => {
        setApplicantsData(data);
      },
      (error) => setError(error)
    );
  };

  const handleSelectionForReviewEmail = (data) => {
    console.log("recent data", data);
  };

  return (
    <div style={{ padding: "1rem" }}>
      {!isMobile && <DashboardCountCards />}

      {/* <LocationComponent /> */}
      <Grid container spacing={2} style={{ marginTop: "-36px" }}>
        <Grid item lg={9} sm={12} xs={12}>
          <Grid item xs={12}>
            <Box style={{ padding: isMobile ? "10px" : "20px" }}>
              <div style={{ marginBottom: "20px" }}>
                <Title level="h4" text="Last 7 Days Activities" />
              </div>

              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="candidate-listing-table-tabs"
              >
                <Tab label="Video Interviews" {...a11yProps(0)} />
                <Tab label="Applications" {...a11yProps(1)} />
              </Tabs>
              <div>
                <CustomTabPanel
                  value={value}
                  index={0}
                  style={{ padding: isMobile ? "0px" : "8px 0px" }}
                >
                  {isDataLoading ? (
                    <Loader />
                  ) : data.length > 0 ? (
                    <VideoInterviewListing
                      data={data}
                      isFromDashboard={true}
                      setSelectedCandidatesForReviewEmail={
                        setSelectedCandidatesForReviewEmail
                      }
                      selectedCandidatesForReviewEmail={
                        selectedCandidatesForReviewEmail
                      }
                    />
                  ) : (
                    <Title
                      level="h6"
                      text="No Video interviews."
                      style={{ padding: "30px 15px" }}
                    />
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <RecentApplicationListing />
                </CustomTabPanel>
              </div>
            </Box>
          </Grid>
        </Grid>
        <Grid item lg={3} sm={12} xs={12}>
          <JobListingSidePanel jobRoleData={jobRoleData} />
        </Grid>
      </Grid>
    </div>
  );
};

export default RecentApplications;
