import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import useFullscreen from "hooks/useFullscreen";
import { Button, Input, Title } from "components";
import { SectionContainer, Box, CompanyLogo, ContentCenter, BlockMargin, CandidateCompanyLogo, ActionRightButton, FileUploadLabel, RemainingCharCount, BaseQuestionLabel, FlexRowAlignCenter, FlexRow } from "assets/styles/main.styles";
import { errorMessages } from "config/constants";
import { useAlert } from "contexts/alert.context";
import { saveBaseQuestion } from "config/api.service";
import { useInterviewDataContext } from "contexts/interviewData";

const CandidateQuestions = () => {
	const navigate = useNavigate();
	const { toggle } = useFullscreen();
	const { interviewData } = useInterviewDataContext();
	const [interviewDetails, setInterviewDetails] = useState([]);
	const [resumeFile, setResumeFile] = useState(null);
	const [questionAnswerData, setQuestionAnswerData] = useState([]);
	const [fieldError, setFieldError] = useState({});
	const [isSubmiting, setIsSubmiting] = useState(false);
	const { showAlert } = useAlert();
	const [remainingCharCount, setRemainingCharCount] = useState({});

	useEffect(() => {
		if (interviewData) {
			setInterviewDetails(interviewData.jobrole.baseQuestions);
			setQuestionAnswerData(
				interviewData.jobrole.baseQuestions.map((item) => {
					return {
						id: item.qid,
						answer: "",
					};
				})
			);
		}
	}, [interviewData]);

	const baseQuestionSubmitSuccess = () => {
		showAlert("Successfully submitted", "success");
		if (interviewData?.jobrole?.isKycRequired) {
			navigate("/candidate-interview/kyc");
			toggle();
			return;
		}
		navigate("/interview-instructions");
		toggle();
	};

	const baseQuestionSubmitError = () => {
		showAlert("Base question submission failed", "error");
	};

	const StartInterview = async () => {
		let temp = { ...fieldError };
		if (resumeFile === null && interviewData.isResumeRequired === true) {
			temp = { ...temp, resume: errorMessages.required };
		} else {
			delete temp.resume;
		}
		for (let qa of questionAnswerData) {
			if (qa.answer.trim() === "") {
				temp = { ...temp, [qa.id]: errorMessages.required };
			} else {
				delete temp[qa.id];
			}
		}
		if (Object.keys(temp).length > 0) {
			setFieldError(temp);
			showAlert("please fill all required fields");
		} else {
			let data = new FormData();
			data.append("resume", resumeFile);
			for (let i = 0; i < questionAnswerData.length; i++) {
				data.append(`qaList[${i}][qid]`, questionAnswerData[i].id);
				data.append(`qaList[${i}][answer]`, questionAnswerData[i].answer);
				data.append(`inviteUUID`, sessionStorage.getItem("uniqueInterviewId") ?? interviewData?.inviteUUID);
				data.append(`candidateId`, interviewData?.CandidateList?.id);
			}
			setIsSubmiting(true);
			await saveBaseQuestion(data, baseQuestionSubmitSuccess, baseQuestionSubmitError);
			setIsSubmiting(false);
		}
	};

	const handleFileUpload = (e) => {
		const sizeInBytes = e.target.files[0].size;
		const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setResumeFile(e.target.files[0]);
		let temp = { ...fieldError };
		delete temp.resume;
		if (sizeInMB > 5) {
			temp = { ...temp, resume: errorMessages.fileSize };
		}
		setFieldError(temp);
	};

	const handleQuestionAnswerChange = (e, index) => {
		let temp = [...questionAnswerData];
		temp[index] = { ...temp[index], answer: e.target.value };
		validate(e, temp[index].id);
		setQuestionAnswerData(temp);
	};

	const validate = (e, id) => {
		if (e.target.value.trim() === "") {
			setFieldError({ ...fieldError, [id]: errorMessages.required });
		} else {
			let temp = { ...fieldError };
			delete temp[id];
			setFieldError(temp);
		}
	};

	const validateData = (e) => {
		if (e.target.value === undefined || e.target.value === "") {
			setFieldError({
				...fieldError,
				[e.target.name]: errorMessages.required,
			});
		} else {
			let temp = { ...fieldError };
			delete temp[e.target.name];
			setFieldError(temp);
		}
	};

	return (
		<SectionContainer>
			<Container maxWidth="lg" style={{ marginTop: "40px" }}>
				<Box>
					<FlexRow
						gap={20}
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<div gap={10} style={{ width: "70%" }}>
							<Title level="h2" text={` ${interviewData?.jobrole?.roleName}`} />
							<Title level="label" text={`Experience: ${interviewData?.jobrole?.experience} years`} />{" "}
						</div>
						<div
							gap={10}
							style={{
								width: "30%",
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}>
							<CompanyLogo src={interviewData?.jobrole?.organization?.orgLogo} alt={interviewData?.jobrole?.organization?.orgName} />
						</div>
					</FlexRow>
				</Box>
				<Box>
					<Title level="h4" text="Please answer the following questions" style={{ marginBottom: "20px" }} />
					<Grid container spacing={5} alignItems="center" className="candidate-list" style={{ marginBottom: "20px" }}>
						{interviewDetails?.map((item, i) => {
							return (
								<Grid item md={4} sm={12} xs={12} key={item.qid}>
									<BaseQuestionLabel>{item.question + " *"}</BaseQuestionLabel>
									<Input
										onChange={(e) => {
											if (e.target.value.length > 200) {
												e.target.value = e.target.value.slice(0, 200);
											}
											handleQuestionAnswerChange(e, i);
											setRemainingCharCount({
												...remainingCharCount,
												[item.qid]: e.target.value.length,
											});
										}}
										onBlur={(e) => validate(e, item.qid)}
										errorText={fieldError[item.qid]}
										minRows={2}
										maxRows={4}
										multiline
										maxLength={200}
									/>
									<RemainingCharCount>{remainingCharCount?.[item.qid] ?? 0} / 500</RemainingCharCount>
								</Grid>
							);
						})}
					</Grid>
					<BlockMargin>
						<Grid container spacing={2} alignItems="center" justifyContent="center">
							<Grid item xs={12}>
								<FileUploadLabel>Upload Resume</FileUploadLabel>
							</Grid>
							<Grid item xs={12}>
								<input
									type="file"
									label="Resume"
									required
									onChange={handleFileUpload}
									placeholder="Upload your resume"
									name="resume"
									accept=".pdf,.doc,.docx"
									error={!!fieldError["resume"]}
									errorText={fieldError["resume"]}
									onBlur={(e) => validateData(e)}
									// focusOnly
								/>
							</Grid>
						</Grid>
						<Title level={"label"} text={"(Allowed files: pdf, doc. Max size: 5MB)"} />
						{fieldError["resume"] && <h4 style={{ color: "red", paddingTop: "5px" }}>{fieldError["resume"]}</h4>}
					</BlockMargin>

					<ActionRightButton>
						<Button btnText="Start my Video Interview" variant="contained" onClick={StartInterview} disabled={isSubmiting} loading={isSubmiting} />
					</ActionRightButton>
				</Box>
			</Container>
		</SectionContainer>
	);
};

export default CandidateQuestions;
