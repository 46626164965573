const IS_LOCAL = window.location.href.includes("http://local");
const IS_DEVELOPMENT = window.location.href.includes("https://devapp");

export const appUrl = IS_LOCAL
  ? "http://local.auth:3000"
  : IS_DEVELOPMENT
  ? "https://devapp.kapiree.com"
  : "https://app.kapiree.com";
export const baseUrl = IS_LOCAL
  ? "http://localhost:3002"
  : IS_DEVELOPMENT
  ? "https://devapi.kapiree.com"
  : "https://api.kapiree.com";
export const candidateAppUrl = IS_LOCAL
  ? "http://local.auth:3000/candidateLogin"
  : IS_DEVELOPMENT
  ? "https://devapp.kapiree.com/candidateLogin"
  : "https://app.kapiree.com/candidateLogin";

export const CANDIDATE = "CANDIDATE";
export const RECRUITER = "RECRUITER";
export const ADMIN = "ADMIN";
export const getPaginationArr = () => [
  { id: 5, optionText: 5 },
  { id: 10, optionText: 10 },
  { id: 20, optionText: 20 },
];
export const questionTypes = [
  {
    id: "option a",
    optionText: "Normal",
  },
  // {
  //   id: 'option b',
  //   optionText: 'Code snippet',
  // },
  {
    id: "option c",
    optionText: "Surprise",
  },
];

export const timeLimitOptions = [
  {
    id: "option a",
    optionText: 0.5,
  },
  {
    id: "option b",
    optionText: 1,
  },
  {
    id: "option c",
    optionText: 2,
  },
  {
    id: "option d",
    optionText: 3,
  },
  {
    id: "option e",
    optionText: 4,
  },
  {
    id: "option f",
    optionText: 5,
  },
];

export const retakeOptions = [
  {
    id: "option a",
    optionText: 0,
  },
  {
    id: "option b",
    optionText: 1,
  },
  {
    id: "option c",
    optionText: 2,
  },
  {
    id: "option d",
    optionText: 3,
  },
];

export const programmingLanguageOptions = [
  {
    id: "option a",
    optionText: "Java",
  },
  {
    id: "option b",
    optionText: "Javascript",
  },
  {
    id: "option c",
    optionText: "C#",
  },
  {
    id: "option d",
    optionText: "candidatePreference",
  },
];

export const keyName = ["name", "Name", "NAME", "FULL NAME", "Full Name"];

export const keyEmail = [
  "email",
  "Email",
  "EMAIL",
  "E-mail",
  "E-MAIL",
  "e-mail",
];

export const keyCountryCode = ["countryCode", "Country Code", "country code"];

export const keyMobileNo = [
  "mobile number",
  "mobile",
  "mobileNumber",
  "mobileNo",
  "Mobile No",
  "Mobile Number",
  "MOBILE NO",
  "phone",
  "phoneNo",
  "phone number",
];

export const errorMessages = {
  required: "This field is required",
  invalid: "Invalid input value",
  fileSize: "File size should be less than 5MB",
  email: "Enter a valid email",
  onlyAlphablets: "Please enter only alphabets",
  fileType: "Uploaded file type is not allowed",
};

export const statuses = {
  0: "Request Interview",
  1: "Requested",
  2: "Pending Review",
  3: "Fit",
  4: "Maybe",
  5: "Rejected",
  6: "Attempted",
  7: "Invite Sent",
  8: "Auto-rejected",
  9: "Invite Expired",
};

export const RatingValues = {
  1: "Not satisfied",
  2: "It was okay",
  3: "Liked it",
  4: "Really liked it",
  5: "It was amazing",
};

export const questionType = {
  NORMAL: "Normal",
  CODE_SNIPPET: "Code snippet",
  HIDDEN_QUESTION: "Hidden question",
  Camera: "Camera",
};

export const recordingStatus = {
  STAND_BY: "standBy",
  START_RECORDING: "startRecording",
  STOP_RECORDING: "stopRecording",
};

export const logoClip = {
  Safari: { clip: "inset(0% 0% 0% 80%)", x: "-90px" },
  Opera: { clip: "inset(0% 20% 0% 60%)", x: "-60px" },
  Firefox: { clip: "inset(0% 40% 0% 40%)", x: "-40px" },
  "Google Chrome": { clip: "inset(0% 80% 0% 0%)", x: "10px" },
  Edge: { clip: "inset(0% 60% 0% 20%)", x: "-10px" },
  Default: { clip: "inset(0% 0% 0% 0%)", x: "-60px" },
};

export const permissions = [
  {
    userType: "hr",
    authType: "regular",
    display: [
      "resume",
      "kyc",
      "videos",
      "basicQuestions",
      "share",
      "location",
      "broswer",
      "deviceType",
    ],
  },
  {
    userType: "reviewer1",
    authType: "otpBased",
    display: ["resume", "videos"],
  },
  {
    userType: "reviewer2",
    authType: "otpBased",
    display: ["resume", "videos"],
  },
  {
    userType: "corporateHr",
    authType: "otpBased",
    display: ["resume", "videos"],
  },
  {
    userType: "public",
    authType: "notRequired",
    display: ["videos"],
  },
];

export const subscriptionStatus = {
  1: "Active",
  2: "Expired",
};
