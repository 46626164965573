import React, { useState, useEffect } from "react";
import { Card } from "components";
import { CardBlock } from "assets/styles/main.styles";

import { useAlert } from "contexts/alert.context";

import {
  getTotalCandidates,
  getTotalCandidatesByRole,
} from "config/api.service";
import { Grid } from "@mui/material";

const DashboardCountCards = ({ roleId = null }) => {
  const [totalcount, setTotalcount] = useState([]);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (roleId) {
      (async () => {
        await getTotalCandidatesByRole(roleId, getTotalCount, () =>
          showAlert("Failed to get jobs", "error")
        );
      })();
    } else {
      (async () => {
        await getTotalCandidates(getTotalCount, () =>
          showAlert("Failed to get jobs", "error")
        );
      })();
    }
  }, [roleId]);

  const getTotalCount = (res) => {
    setTotalcount(res);
  };

  return (
    <CardBlock>
      <Grid container spacing={2} alignItems="center" marginTop="10px">
      <Grid item xs={12} sm={6} md={3}>
          <Card
            isNumbered
            count={totalcount.totalCandidates}
            cardTitle={"Total Applicants"}
            statusTagLabel={""}
          />
        </Grid>
        {/* <Grid item xs>
          <Card
            isNumbered
            count={totalcount.hrInvited}
            cardTitle={"Invited by HR"}
            statusTagLabel={""}
          />
        </Grid> */}
       <Grid item xs={12} sm={6} md={3}>
          <Card
            isNumbered
            count={totalcount.interviewCompleted}
            cardTitle={"Video Interview Completed"}
            statusTagLabel={""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            isNumbered
            count={totalcount.pending}
            cardTitle={"Review Pending"}
            statusTagLabel={""}
          />
        </Grid>
        {/* <Grid item >
          <Card
            isNumbered
            count={totalcount.reviewCompleted}
            cardTitle={"Review Completed"}
            statusTagLabel={""}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Card
            isNumbered
            count={totalcount.shortlisted}
            cardTitle={"Shortlisted"}
            statusTagLabel={""}
          />
        </Grid>
      </Grid>
    </CardBlock>
  );
};

export default DashboardCountCards;
