import React, { useEffect, useMemo, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { InputLabel } from "@mui/material";
import Rating from "@mui/material/Rating";
import {
  Title,
  Input,
  Checkbox,
  CheckboxListing,
  DragItemList,
  Button,
} from "components";
import {
  Box,
  BlockMargin,
  TooltipTitle,
  TooltipText,
  CreateJobContainer,
  SelectLabel,
  SelectWrapper,
  RatingBlockCutoff,
  FlexRow,
} from "assets/styles/main.styles";
import HelpIcon from "@mui/icons-material/Help";
import {
  createJobRoles,
  getJobRoleDetails,
  updateJobRoles,
  getCategory,
} from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { errorMessages } from "config/constants";
import { TextField } from "@mui/material";
import { getBaseQuestions } from "config/api.service";
import { RatingValues } from "config/constants";

const CreateJob = ({ handleClose, id }) => {
  const initialInterviewQuestionData = {
    sortOrder: 1,
    question: "",
    timeLimit: 2,
    retake: 0,
    type: "Surprise",
    cutOf: 2,
  };

  const [hover, setHover] = React.useState(-1);
  const [roleMoreInfo, setRoleMoreInfo] = useState({ notFit: 0, fit: 0 });
  const [roleDetails, setRoleDetails] = useState({});
  const [baseQuestions, setBaseQuestions] = useState([]);
  const [removedBaseQuestions, setRemovedBaseQuestions] = useState([]);
  const [removedInterviewQuestions, setRemovedInterviewQuestions] = useState(
    []
  );
  const interviewQuestionsRef = useRef([initialInterviewQuestionData]);
  const { showAlert } = useAlert();
  const [fieldErrors, setFieldErrors] = useState({});
  const [interviewValidationError, setInterviewValidationError] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedBaseQuestions, setSelectedBaseQuestions] = useState([]);
  const [templateBaseQuestions, setTemplateBaseQuestions] = useState([]);
  const [totalCutOff, setTotalCutOff] = useState(0);
  const [showNoticePeriodDropdown, setShowNoticePeriodDropdown] =
    useState(false);

  useMemo(async function getCategories() {
    getCategory(function (result) {
      const generalId = result.find(
        (category) => category.name == "General"
      )?.id;
      setRoleDetails({ ...roleDetails, categoryId: generalId });
      setCategoriesData(result);
    });
  }, []);

  const handleRoleDetails = (e) => {
    validateInput(e);
    setRoleDetails({ ...roleDetails, [e.target.name]: e.target.value });
  };

  const handleSelectedBaseQuestions = (e, data) => {
    let temp = [...selectedBaseQuestions];
    // let tempRemovedBaseQuestions = [...removedBaseQuestions];
    // let removedData;
    let i = 0;
    if (data.qid) {
      i = temp.findIndex((item) => item === data.qid);
    }
    if (e.target.checked) {
      temp.push(data.qid);
      const removedBaseQuestionsTemp = removedBaseQuestions.filter(
        (item) => item !== data.qid
      );
      setRemovedBaseQuestions(removedBaseQuestionsTemp);
    } else {
      temp.splice(i, 1);
      if (!data.qid.toString().startsWith("n_")) {
        setRemovedBaseQuestions([...removedBaseQuestions, data.qid]);
      }
    }
    if (data.qid.toString().startsWith("n_")) {
      temp.push(data.qid);
    }
    // if (e.target.checked) {
    //   temp.push(data);
    //   if (i && i !== -1) {
    //     removedData = tempRemovedBaseQuestions.splice(i, 1);
    //   }
    // } else {
    //   if (i !== -1) {
    //     removedData = temp.splice(i, 1);
    //   }
    //   tempRemovedBaseQuestions.push(data);
    // }
    // setBaseQuestions(temp);
    setSelectedBaseQuestions(temp);
    // if (removedData?.qid) {
    //   setRemovedBaseQuestions([...removedBaseQuestions, removedData.qid]);
    // }
  };

  const handleNewBaseQuestions = (questions) => {
    setBaseQuestions(questions);
  };

  const handleInterviewQuestions = (data) => {
    interviewQuestionsRef.current = data.map((item) => {
      return {
        sortOrder: item.sortOrder,
        question: item.question,
        timeLimit: item.timeLimit,
        retake: item.retake,
        type: item.type,
        cutOf: item.cutOf,
      };
    });
  };

  const handleDeletedInterviewQuestions = (id) => {
    setRemovedInterviewQuestions([...removedInterviewQuestions, id]);
  };

  const handleRoleExtraDetails = (e) => {
    validateInput(e);
    let temp = { ...roleMoreInfo };
    temp = { ...temp, [e.target.name]: e.target.value };
    setRoleMoreInfo(temp);
  };
  const handleFitValueChange = (value) => {
    let temp = { ...roleMoreInfo };
    temp = { ...temp, ["fit"]: value };
    setRoleMoreInfo(temp);
  };

  const handleSubmitError = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
  };

  const handleCreateSuccess = () => {
    showAlert("Successfully created", "success");
    closeFunction();
  };

  const handleUpdateSuccess = () => {
    showAlert("Successfully updated", "success");
    closeFunction();
  };

  const handleSubmit = async () => {
    if (
      onRoleDetailsSubmitValidations() ||
      onSubmitInterviewQuestionValidations()
    ) {
      showAlert("Please fill all mandatory fields", "error");
      return;
    }

    if (baseQuestions.length == 0) {
      showAlert("Please select at least 1 text based question", "error");
      return;
    }

    if (!interviewQuestionsRef.current.length) {
      showAlert("Add at least one video interview question", "error");
      return;
    }

    if (totalCutOff === 0) {
      showAlert("Please select a cutoff for the review", "error");
      return;
    }

    let selectedScreeningQuestions = baseQuestions.filter((item) => {
      return selectedBaseQuestions.includes(item.qid);
    });

    let data = {
      roleDetails: {
        ...roleDetails,
        roleMoreInfo: JSON.stringify(roleMoreInfo),
        eta: new Date(),
        resumeRequired: true,
        totalCutOff,
      },
      baseQuestions: selectedScreeningQuestions,
      interviewQuestions: interviewQuestionsRef.current,
    };

    if (id) {
      data.removedBaseQuestion = removedBaseQuestions;
      data.removedInterviewQuestion = removedInterviewQuestions;
      setIsSubmitting(true);
      await updateJobRoles(data, handleUpdateSuccess, handleSubmitError);
      setIsSubmitting(false);
    } else {
      setIsSubmitting(true);
      await createJobRoles(data, handleCreateSuccess, handleSubmitError);
      setIsSubmitting(false);
    }
  };

  const handleGetRoleDetails = async (res) => {
    setBaseQuestions([...res.baseQuestions]);
    setRoleDetails({ ...res.roleDetails });
    interviewQuestionsRef.current = [...res.interviewQuestions];
    let ratingCutOff = res.roleDetails?.ratingCutOff;
    if (ratingCutOff) {
      setTotalCutOff(ratingCutOff);
      setHover(Math.floor(ratingCutOff));
    } else {
      setTotalCutOffonEdit(res.interviewQuestions);
    }
    setRoleMoreInfo(JSON.parse(res.roleDetails.roleMoreInfo));
    handleGettingBaseQuestions(res.baseQuestions);
    if (res?.roleDetails?.noticePeriod != null) {
      setShowNoticePeriodDropdown(true);
    }
  };

  const setTotalCutOffonEdit = (interviewQuestions) => {
    let totalCutof = 0;
    interviewQuestions.map((item) => {
      totalCutof += item.cutOf;
    });
    let totalQuestions = interviewQuestions.length;
    let value = Number(totalCutof) / totalQuestions;
    setTotalCutOff(value);
    setHover(Math.floor(value));
  };

  const handleGettingBaseQuestions = async (baseQ) => {
    await getBaseQuestions(
      (template) => {
        handleGetBaseQuestions(template, baseQ ?? []);
      },
      () => console.log("Failed to get base questions")
    );
  };

  const handleSetRoleDetails = (data) => {
    setRoleDetails(data);
  };

  useEffect(() => {
    if (id) {
      getJobRoleDetails(handleGetRoleDetails, id, () =>
        console.log("Failed to fetch")
      );
    } else {
      interviewQuestionsRef.current = [initialInterviewQuestionData];
      handleSetRoleDetails({
        roleName: "",
        roleDiscription: "",
        experience: "",
        resumeRequired: true,
        eta: new Date(),
        isKycRequired: false,
        roleMoreInfo: "",
        ccToRecruiter: true,
        emailToReviewer: true,
        categoryId: "",
        noticePeriod: "",
      });
      handleGettingBaseQuestions([]);
    }
  }, [id]);

  const handleGetBaseQuestions = (res, baseQues) => {
    // const addedBaseQuestion = [...baseQuestions].filter((list) => {
    //   return !res.map((item) => item.question).includes(list.question);
    // });
    // const newQuestionList = [...res, ...addedBaseQuestion];
    // setQuestionList(newQuestionList);
    const baseQuesQuesions = baseQues.map((item) => item.question);
    const resQuestions = res.filter(
      (item) => !baseQuesQuesions.includes(item.question)
    );
    const selectedQuestions = baseQues.map((item) => item.qid);
    const totalQuestions = [...resQuestions, ...baseQues];
    setTemplateBaseQuestions(res);
    setBaseQuestions(totalQuestions);
    setSelectedBaseQuestions(selectedQuestions);
  };

  const closeFunction = () => {
    handleClose();
  };

  const validateInput = (e) => {
    if (e.target.name) {
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.required,
        });
      } else if (
        e.target.name === "fit" &&
        (e.target.value <= roleMoreInfo.notFit || e.target.value > 5)
      ) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.invalid,
        });
      } else if (
        e.target.name === "notFit" &&
        (e.target.value >= roleMoreInfo.fit || e.target.value < 1)
      ) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.invalid,
        });
      } else {
        let temp = { ...fieldErrors };
        delete temp[e.target.name];
        setFieldErrors(temp);
      }
    }
  };

  const onRoleDetailsSubmitValidations = () => {
    if (roleDetails.roleName === undefined || roleDetails.roleName === "") {
      setFieldErrors({ ...fieldErrors, roleName: errorMessages.required });
      return true;
    } else if (
      roleDetails.roleDiscription === undefined ||
      roleDetails.roleDiscription === ""
    ) {
      setFieldErrors({
        ...fieldErrors,
        roleDiscription: errorMessages.required,
      });
      return true;
    } else if (
      roleDetails.experience === undefined ||
      roleDetails.experience === ""
    ) {
      setFieldErrors({ ...fieldErrors, experience: errorMessages.required });
      return true;
      // } else if (
      //   roleMoreInfo.fit === undefined ||
      //   roleMoreInfo.experience === ""
      // ) {
      //   setFieldErrors({ ...fieldErrors, fit: errorMessages.required });
      //   return true;
      // } else if (
      //   roleMoreInfo.fit <= roleMoreInfo.notFit ||
      //   roleMoreInfo.fit > 5
      // ) {
      //   setFieldErrors({ ...fieldErrors, fit: errorMessages.invalid });
      //   return true;
      // } else if (
      //   roleMoreInfo.notFit === undefined ||
      //   roleMoreInfo.notFit === ""
      // ) {
      //   setFieldErrors({ ...fieldErrors, notFit: errorMessages.required });
      //   return true;
      // } else if (
      //   roleMoreInfo.notFit >= roleMoreInfo.fit ||
      //   roleMoreInfo.notFit < 1
      // ) {
      //   setFieldErrors({ ...fieldErrors, notFit: errorMessages.invalid });
      //   return true;
    } else if (
      roleDetails.categoryId === undefined ||
      roleDetails.categoryId === ""
    ) {
      setFieldErrors({ ...fieldErrors, categoryId: errorMessages.required });
      return true;
    } else if (roleDetails.noticePeriod == "") {
      setFieldErrors({ ...fieldErrors, noticePeriod: errorMessages.required });
      return true;
    }
  };

  const onSubmitInterviewQuestionValidations = () => {
    let errorInterviewQuestions = [];
    for (let element of interviewQuestionsRef.current) {
      if (element.question === undefined || element.question === "") {
        errorInterviewQuestions = [
          ...errorInterviewQuestions,
          element.sortOrder,
        ];
      }
    }
    if (errorInterviewQuestions.length) {
      setInterviewValidationError(errorInterviewQuestions);
      return true;
    } else {
      setInterviewValidationError([]);
      return false;
    }
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${RatingValues[value]}`;
  }

  return (
    <Grid>
      <BlockMargin>
        <Grid
          container
          columnSpacing={{ md: 3, sm: 1, xs: 1 }}
          rowSpacing={{ md: 3, sm: 1, xs: 1 }}
        >
          <Grid item xs={12} md={3}>
            <Checkbox
              checked={roleDetails?.disabled === true}
              label="Disable job"
              onChange={(event) =>
                setRoleDetails({
                  ...roleDetails,
                  disabled: event.target.checked,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Checkbox
              checked={roleDetails?.displayOnWeb === true}
              label="Display on website"
              onChange={(event) =>
                setRoleDetails({
                  ...roleDetails,
                  displayOnWeb: event.target.checked,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={5} textAlign="right">
            <Button
              variant="contained"
              btnText={id ? "Update" : "Create New"}
              onClick={() => handleSubmit()}
              loading={isSubmitting}
              disabled={baseQuestions.length == 0}
              style={{ width: "100px" }}
            />
          </Grid>
        </Grid>
        <Box isGrey>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={6}>
              <InputLabel>
                <SelectLabel>
                  Category <span style={{ color: "red" }}>*</span>
                </SelectLabel>
              </InputLabel>
              <SelectWrapper>
                <select
                  onChange={handleRoleDetails}
                  name="categoryId"
                  onBlur={validateInput}
                  value={roleDetails?.categoryId}
                  className={!!fieldErrors["categoryId"] ? "error" : ""}
                >
                  <option value="">
                    <em>select category</em>
                  </option>
                  {categoriesData.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </select>
              </SelectWrapper>
            </Grid>
            <Grid item xs={6}>
              <Input
                label={
                  <>
                    Job Title <span style={{ color: "red" }}>*</span>
                  </>
                }
                placeholder="Enter job title"
                name="roleName"
                onChange={handleRoleDetails}
                value={roleDetails?.roleName}
                onBlur={validateInput}
                error={!!fieldErrors["roleName"]}
                errorText={fieldErrors["roleName"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={
                  <>
                    Experience Required(Yrs){" "}
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                placeholder="Enter experience"
                name="experience"
                min="0"
                onChange={handleRoleDetails}
                value={roleDetails?.experience}
                onBlur={validateInput}
                error={!!fieldErrors["experience"]}
                errorText={fieldErrors["experience"]}
                type="number"
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Input
                label="Job ID"
                placeholder="Enter Job ID"
                name="jobId"
                onChange={handleRoleDetails}
              />
            </Grid> */}
            <Grid item sm={6} display={{ xs: "none", lg: "block" }}>
              {/* <Grid container spacing={3}> */}
              {/* <Grid item xs={6} className="label-grid">
                  <Input
                    label={
                      <>
                        <span>Not fit</span>
                        <Tooltip
                          title={
                            <>
                              <TooltipTitle> Not Fit</TooltipTitle>
                              <TooltipText>
                                Value below which candidates are a not fit for
                                the role.
                              </TooltipText>
                            </>
                          }
                          arrow
                        >
                          <HelpIcon className="help-icon" />
                        </Tooltip>
                      </>
                    }
                    placeholder="Enter Text"
                    name="notFit"
                    type="number"
                    value={roleMoreInfo.notFit}
                    onChange={handleRoleExtraDetails}
                    onBlur={validateInput}
                    error={!!fieldErrors["notFit"]}
                    errorText={fieldErrors["notFit"]}
                    inputprops={{
                      inputProps: { min: "0", max: "5", step: "1" },
                    }}
                  />
                </Grid> */}
              {/* <Grid item xs={6} className="label-grid"> */}
              {/* <Input
                label={
                  <>
                    <span>Minimum Overall Cutoff</span>
                    <Tooltip
                      title={
                        <>
                          <TooltipTitle>Minimum Overall Cutoff</TooltipTitle>
                          <TooltipText>
                            Value above which candidates are a perfect fit for
                            the role. Please select 1 to 5
                          </TooltipText>
                        </>
                      }
                      arrow
                    >
                      <HelpIcon className="help-icon" />
                    </Tooltip>
                  </>
                }
                placeholder="Enter Text"
                name="fit"
                type="number"
                value={roleMoreInfo.fit ?? ""}
                onBlur={validateInput}
                error={!!fieldErrors["fit"]}
                errorText={fieldErrors["fit"]}
                inputprops={{
                  inputProps: { min: "0", max: "5", step: "1" },
                }}
                onChange={handleRoleExtraDetails}
                max="5"
                maxLength="5"
              /> */}
              {/* </Grid> */}
              {/* </Grid> */}
            </Grid>
            <Grid item xs={12}>
              {/* <Input
                label="Job Description"
                placeholder="Enter Text"
                name="roleDiscription"
                isMulti
                onChange={handleRoleDetails}
                value={roleDetails.roleDiscription}
                error={!!fieldErrors["roleDiscription"]}
                errorText={fieldErrors["roleDiscription"]}
                onBlur={validateInput}
              /> */}

              <Title text="Job Description" level="label" />
              <TextField
                rows={5}
                multiline
                variant="outlined"
                fullWidth
                hiddenLabel
                placeholder="Enter Job description"
                name="roleDiscription"
                onChange={handleRoleDetails}
                value={roleDetails.roleDiscription}
                error={!!fieldErrors["roleDiscription"]}
                errorText={fieldErrors["roleDiscription"]}
                onBlur={validateInput}
                sx={{
                  background: "#fcfcfb",
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                checked={roleDetails?.isKycRequired === true}
                label="Require candidate to capture photo with ID proof ( KYC)"
                onChange={(e) => {
                  setRoleDetails({
                    ...roleDetails,
                    isKycRequired: e.target.checked,
                  });
                }}
              />
              {/* <Checkbox
								checked={roleDetails?.resumeRequired === true}
								label="Require Resume"
								onChange={(e) => {
									setRoleDetails({
										...roleDetails,
										resumeRequired: e.target.checked,
									});
								}}
							/> */}
              <Checkbox
                checked={roleDetails?.ccToRecruiter === true}
                label="CC to Recruiter"
                onChange={(e) => {
                  setRoleDetails({
                    ...roleDetails,
                    ccToRecruiter: e.target.checked,
                  });
                }}
              />
              <Checkbox
                checked={roleDetails?.emailToReviewer === true}
                label="Email to Reviewer"
                onChange={(e) => {
                  setRoleDetails({
                    ...roleDetails,
                    emailToReviewer: e.target.checked,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </BlockMargin>
      <Title text="Define mandatory Criteria for this role" level="label" />
      <FlexRow style={{ alignItems: "center", padding: "20px" }}>
        <Checkbox
          label="Notice period"
          disabled={id != null}
          checked={
            id != null
              ? roleDetails?.noticePeriod != null
                ? true
                : false
              : showNoticePeriodDropdown
          }
          onChange={(e) => {
            setShowNoticePeriodDropdown(!showNoticePeriodDropdown);
          }}
        />
        {showNoticePeriodDropdown && (
          <SelectWrapper>
            <select
              onChange={(e) => {
                setRoleDetails({
                  ...roleDetails,
                  noticePeriod: e.target.value,
                });
              }}
              required
              disabled={id != null}
              name="noticePeriod"
              onBlur={validateInput}
              value={roleDetails?.noticePeriod}
              className={!!fieldErrors["noticePeriod"] ? "error" : ""}
            >
              <option value="">
                <em>Select days</em>
              </option>
              {[7, 15, 30, 60, 90].map((d) => (
                <option key={d} value={d}>
                  {d} days
                </option>
              ))}
            </select>
          </SelectWrapper>
        )}
      </FlexRow>
      <BlockMargin>
        <Title level="h4" text="Add text based questions" />
      </BlockMargin>
      <BlockMargin>
        <Box isGrey>
          <CheckboxListing
            baseQuestions={baseQuestions}
            isEdit={id != null}
            selectedBaseQuestions={selectedBaseQuestions}
            templateBaseQuestions={templateBaseQuestions}
            handleSelectedBaseQuestions={handleSelectedBaseQuestions}
            handleNewBaseQuestions={handleNewBaseQuestions}
          />
        </Box>
      </BlockMargin>

      <Title level="h4" text="Add video interview questions" />
      <Grid container alignItems="center">
        <Grid item>
          <InputLabel size="large" shrink>
            <span style={{ fontWeight: "600" }}>Minimum Overall Cutoff</span>
            <Tooltip
              title={
                <>
                  <TooltipTitle>Minimum Overall Cutoff</TooltipTitle>
                  <TooltipText>
                    Value above which candidates are a perfect fit for the role.
                  </TooltipText>
                </>
              }
              arrow
            >
              <HelpIcon className="help-icon" />
            </Tooltip>{" "}
          </InputLabel>
        </Grid>
        <Grid item>
          <RatingBlockCutoff>
            <Rating
              name="simple-controlled"
              value={totalCutOff ?? 0}
              precision={1}
              size="large"
              getLabelText={getLabelText}
              sx={{ paddingTop: "3px" }}
              onChange={(event, newValue) => {
                setTotalCutOff(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(id && newHover === -1 ? totalCutOff : newHover);
              }}
            />
            {totalCutOff !== null && (
              <span style={{ paddingTop: "0px" }}>
                {RatingValues[hover !== -1 ? hover : totalCutOff]}
              </span>
            )}
          </RatingBlockCutoff>
        </Grid>
      </Grid>

      <DragItemList
        handleInterviewQuestions={handleInterviewQuestions}
        interviewQuestionsRef={interviewQuestionsRef}
        handleDeletedInterviewQuestions={handleDeletedInterviewQuestions}
        interviewValidationError={interviewValidationError}
        isEdit={id != null}
      />
      <BlockMargin />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          btnText={id ? "Update" : "Create New"}
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          disabled={baseQuestions.length == 0}
          style={{ width: "100px" }}
        />
      </div>
    </Grid>
  );
};

export default CreateJob;
