import { useRef, useState } from "react";
import DraggableList from "react-draggable-list";
import Grid from "@mui/material/Grid";

import { Button, DragItem } from "components";
import { DragList } from "./dragitemlist.styles";

import AddIcon from "@mui/icons-material/Add";

const DragItemList = ({
  interviewQuestionsRef,
  handleDeletedInterviewQuestions,
  interviewValidationError,
  isEdit = false,
}) => {
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const containerRef = useRef();

  const _onListChange = (newList) => {
    interviewQuestionsRef.current = newList;
    setShouldUpdate((prev) => !prev);
  };

  const addItemToList = () => {
    const id = new Date().getTime();
    interviewQuestionsRef.current = [
      ...interviewQuestionsRef.current,
      {
        id,
        sortOrder: interviewQuestionsRef.current?.length + 1,
        question: "",
        timeLimit: 2,
        retake: 0,
        type: "Surprise",
        cutOf: 3,
      },
    ];
    setShouldUpdate((prev) => !prev);
  };

  function handleDeleteClick(id) {
    const removeItem = interviewQuestionsRef.current.filter((item) => {
      handleDeletedInterviewQuestions(item.id);
      return item.sortOrder !== id;
    });
    interviewQuestionsRef.current = removeItem;
    setShouldUpdate((prev) => !prev);
  }

  const handleData = (data) => {
    const i = interviewQuestionsRef.current.findIndex(
      (item) => item.sortOrder === data.sortOrder
    );
    let temp = [...interviewQuestionsRef.current];
    temp[i] = data;
    interviewQuestionsRef.current = temp;
  };

  return (
    <div>
      <DragList ref={containerRef} style={{ touchAction: "pan-y" }}>
        <DraggableList
          itemKey="id"
          template={(items) => (
            <DragItem
              items={items}
              handleData={handleData}
              del={handleDeleteClick}
              interviewQuestionsRef={interviewQuestionsRef}
              interviewValidationError={interviewValidationError}
              isEdit={isEdit}
            />
          )}
          list={interviewQuestionsRef?.current ?? []}
          onMoveEnd={(newList) => _onListChange(newList)}
          container={() => containerRef.current}
        />
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            startIcon={<AddIcon />}
            btnText="Add video question"
            onClick={addItemToList}
            className="add-button"
          />
        </Grid>
      </DragList>
    </div>
  );
};

export default DragItemList;
