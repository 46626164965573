import { useState, useMemo, useEffect } from "react";
import { Table, Title } from "components";
import { Card } from "@mui/material";
import { format } from "date-fns";
import { getSubscriptionDetails } from "config/api.service";
import { subscriptionStatus } from "config/constants";

const Subscriptions = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubscriptions();
  }, []);

  const getSubscriptions = async () => {
    setLoading(true);
    await getSubscriptionDetails(handleData);
    setLoading(false);
  };

  const handleData = (data) => {
    setData(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Subscribed Plan",
        accessor: "subscriptionPlan.name",
      },
      {
        Header: "Amount",
        accessor: "subscriptionPlan.amount",
        align: "right",
        width: 50,
        Cell: ({ value }) => {
          return <>{value ? "$ " + value : ""}</>;
        },
      },

      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }) => {
          const date = new Date(value);
          return <>{value ? format(date, "PPp") : ""}</>;
        },
      },

      {
        Header: "Renewal Date",
        accessor: "endDate",
        Cell: ({ value }) => {
          const date = new Date(value);
          return <>{value ? format(date, "PPp") : ""}</>;
        },
      },
      // {
      //   id: "action",
      //   Header: "Action",
      //   accessor: "Action",
      //   Cell: (row) => {
      //     return <></>;
      //   },
      //   disableSortBy: true,
      // },

      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => {
          return (
            <>{subscriptionStatus[value] ? subscriptionStatus[value] : ""}</>
          );
        },
      },
    ],
    [data]
  );

  return (
    <>
      <Title
        level="h3"
        text="Subscription Details"
        style={{ marginBottom: "0.5rem" }}
      />
      <Card sx={{ padding: "20px" }}>
        <Table
          columns={columns}
          data={data}
          isLoading={loading}
          hidePagination
          hideSearch
          hideFilter
          numOfLoadingBarCols={3}
        />
      </Card>
    </>
  );
};

export default Subscriptions;
