import React, { createRef, useEffect, useRef, useState } from "react";
import DraggableList from "react-draggable-list";
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { DragContainer, LabelTitle } from "./dragitem.styles";
import { Input, Dropdown, Title } from "components";
import { Box } from "assets/styles/main.styles";

import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  errorMessages,
  programmingLanguageOptions,
  questionTypes,
  retakeOptions,
  timeLimitOptions,
} from "config/constants";

const DragItem = ({
  items,
  del,
  handleData,
  interviewValidationError,
  isEdit,
}) => {
  const { onMouseDown, onTouchStart } = items.dragHandleProps;
  const [inputValues, setInputValues] = useState(items.item);
  const [fieldErrors, setFieldErrors] = useState({});
  const data = items.item;

  const handleSetFieldErrors = (data) => {
    setFieldErrors({ ...fieldErrors, ...data });
  };

  useEffect(() => {
    if (interviewValidationError.length) {
      if (interviewValidationError?.includes(data.sortOrder)) {
        handleSetFieldErrors({ question: errorMessages.required });
      }
    }
  }, [interviewValidationError]);

  const handleInputValues = (e) => {
    validateInput(e);
    if (e.target.name === "type" && e.target.value === "Hidden question") {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value,
        retake: 0,
      });
    } else {
      setInputValues({ ...inputValues, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (!Object.is(data, inputValues)) {
      handleData(inputValues);
    }
  }, [inputValues]);

  const validateInput = (e) => {
    if (e.target.name) {
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.required,
        });
      } else if (e.target.name === "cutOf" && e.target.value > 5) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.invalid,
        });
      } else if (e.target.name === "cutOf" && e.target.value < 0) {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.invalid,
        });
      } else {
        let temp = { ...fieldErrors };
        delete temp[e.target.name];
        setFieldErrors(temp);
      }
    }
  };

  return (
    <DragContainer>
      <Box isGrey className="box-padding">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DragIndicatorRoundedIcon
              className="drag-handle"
              onTouchStart={(e) => {
                e.preventDefault();
                console.log("touchStart");
                e.target.style.backgroundColor = "blue";
                document.body.style.overflow = "hidden";
                onTouchStart(e);
              }}
              onMouseDown={(e) => {
                console.log("mouseDown");
                document.body.style.overflow = "hidden";
                onMouseDown(e);
              }}
              onTouchEnd={(e) => {
                e.target.style.backgroundColor = "black";
                document.body.style.overflow = "visible";
              }}
              onMouseUp={() => {
                document.body.style.overflow = "visible";
              }}
            />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => del(items.item.sortOrder)}>
              <DeleteIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12} className="question-padding">
            <Input
              label="Video Question"
              placeholder="Enter question here"
              name="question"
              onChange={(e) => handleInputValues(e)}
              value={inputValues.question ?? null}
              onBlur={validateInput}
              error={!!fieldErrors["question"]}
              errorText={fieldErrors["question"]}
            />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ marginTop: "10px" }}>
            <Dropdown
              label="Question type"
              onChange={(e) => handleInputValues(e)}
              value={inputValues.type ?? null}
              name="type"
              options={questionTypes}
              onBlur={validateInput}
              errorText={fieldErrors["type"]}
            />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ marginTop: "10px" }}>
            <Dropdown
              label="Recording time limit(in mins)"
              onChange={(e) => handleInputValues(e)}
              value={inputValues.timeLimit ?? null}
              name="timeLimit"
              placeholder="Choose time limit"
              options={timeLimitOptions}
              onBlur={validateInput}
              errorText={fieldErrors["timeLimit"]}
            />
          </Grid>
          <Grid item xs={2} sx={{ marginTop: "10px" }}>
            {inputValues.type !== "Surprise" && (
              <Dropdown
                label="Retakes"
                placeholder="Choose no of retakes"
                onChange={(e) => handleInputValues(e)}
                value={inputValues.retake ?? null}
                name="retake"
                options={retakeOptions}
                onBlur={validateInput}
                errorText={fieldErrors["retake"]}
                isDisabled={inputValues.type === "Hidden question"}
              />
            )}
          </Grid>
          {inputValues.type === "Code snippet" && (
            <Grid item xs={2}>
              <Dropdown
                label="Change coding language"
                name="preferredCodingLanguage"
                onChange={(e) => handleInputValues(e)}
                value={inputValues.preferredCodingLanguage}
                options={programmingLanguageOptions}
                onBlur={validateInput}
                errorText={fieldErrors["preferredCodingLanguage"]}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </DragContainer>
  );
};

export default DragItem;
