import React, { useEffect, useState, useRef } from "react";
import Collapse from "@mui/material/Collapse";
import { getJobRoles } from "config/api.service";
import { Button } from "components";
import { ScrollContainer } from "../candidatelist.styles";

const JobRoleFilterModal = ({
  jobRoleFilterModalOpen,
  setJobRoleFilterModalOpen,
  handleJobRoleFilter,
}) => {
  const [jobRoles, setJobRoles] = useState([]);
  const collapseRef = useRef(null);
  useEffect(() => {
    const handler = (event) => {
      if (!collapseRef.current) {
        return;
      }
      // if click was not inside of the element. "!" means not
      // in other words, if click is outside the modal element
      if (!collapseRef.current.contains(event.target)) {
        setJobRoleFilterModalOpen(false);
      }
    };
    // the key is using the `true` option
    // `true` will enable the `capture` phase of event handling by browser
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    (async () => {
      await getJobRoles(
        (res) => {
          setJobRoles(res.createdJobList);
        },
        () => {}
      );
    })();
  }, []);
  return (
    <Collapse
      in={jobRoleFilterModalOpen}
      timeout="auto"
      ref={collapseRef}
      unmountOnExit
      sx={{
        width: "250px",
        border: "1px solid #ccc",
        padding: "10px",
        borderRadius: "4px",
        position: "absolute",
        bgcolor: "background.paper",
        top: "100%",
        zIndex: 1000,
        overflow: "auto",
        maxHeight: 400,
        " &::-webkit-scrollbar ": {
          width: "10px",
          background: "transparent",
          "&-track": {
            background: "rgba(0, 0, 0, 0.1)",
          },
          "&-thumb": {
            background: "rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
          },
        },
      }}
    >
      <ScrollContainer>
        {jobRoles?.map((item) => {
          return (
            <Button
              key={item.id}
              variant="link"
              btnText={item.roleName}
              style={{
                justifyContent: "flex-start",
              }}
              sx={{
                textTransform: "capitalize",
                "&:hover": { color: "#2E6AE0" },
              }}
              onClick={() => handleJobRoleFilter(item)}
            />
          );
        })}
      </ScrollContainer>
    </Collapse>
  );
};

export default JobRoleFilterModal;
