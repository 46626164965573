import { format } from "date-fns";
import { getTimePassed } from "utils";
import {
  BackButton,
  CIBBlock,
  SubtitleInfo,
  FromWebsiteIcon,
  RatingBlock,
  BodyTextFeedback,
  BlockMargin,
} from "assets/styles/main.styles";
import Avatar from "@mui/material/Avatar";
import { stringAvatar, capitalizeWord, isCandidatePassed } from "utils";
import { Title } from "components";
import useWindowSize from "hooks/useWindowSize";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import { RatingValues } from "config/constants";

const ProfileHeader = ({ data }) => {
  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 768;
  const { overallRating } = isCandidatePassed(
    data?.Feedback,
    data?.jobrole?.interviewQuestions,
    data?.jobrole?.ratingCutOff
  );
  let thumbnail = "";

  if (data?.AnswerPool?.length > 0) {
    const vidMeta = data?.AnswerPool[data?.AnswerPool?.length - 1]?.vidMeta;
    const vidMet = JSON.parse(vidMeta);

    thumbnail = vidMet.vidMeta.assets.thumbnail;
  }

  function getDisplayTime() {
    let displayTime = "";
    if (data?.AnswerPool?.length > 0) {
      const vidMeta = data?.AnswerPool[data?.AnswerPool?.length - 1]?.vidMeta;
      const vidMet = JSON.parse(vidMeta);
      displayTime =
        format(vidMet?.vidMeta?.createdAt, "PPp") +
        "(" +
        getTimePassed(vidMet?.vidMeta?.createdAt) +
        ")";
    } else if (data?.createdDate) {
      displayTime =
        format(data?.createdDate, "PPp") +
        "(" +
        getTimePassed(data?.createdDate) +
        ")";
    }
    return displayTime;
  }

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-start" xs={12}>
      <Grid item md={12} lg={5}>
        <BackButton>
          <div style={{ position: "relative" }}>
            <Avatar
              src={thumbnail && thumbnail}
              sx={{
                width: 90,
                height: 90,
                fontSize: 42,
                color: "#A2DFFF",
                border: "2px solid #C1EAFF",
                bgcolor: "#E0F5FF",
                flex: isMobile ? 1 : "unset",
              }}
            >
              {stringAvatar(data?.name)}
            </Avatar>
            {data?.fromWebsite && (
              <div
                style={{
                  zIndex: 10,
                  position: "absolute",
                  marginLeft: "28px",
                  marginTop: "-14px",
                }}
              >
                <FromWebsiteIcon title="Added from website">
                  www
                </FromWebsiteIcon>
              </div>
            )}
          </div>

          <CIBBlock>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flexWrap: "wrap",
                marginBottom: isMobile ? "1rem" : 0,
              }}
            >
              <Title
                level="h2"
                text={data?.name}
                paddingTop={"40px"}
                color="black"
              />{" "}
            </div>
            <span
              style={{
                color: "gray",
                paddingTop: "8px",
                paddingBottom: "8px",
                fontWeight: "800",
              }}
            >{`${
              data?.jobrole ? capitalizeWord(data?.jobrole?.roleName) : ""
            }`}</span>

            <SubtitleInfo>
              <strong>{getDisplayTime()}</strong>
            </SubtitleInfo>
          </CIBBlock>
        </BackButton>
      </Grid>
      {data?.Feedback?.length > 0 && (
        <Grid item lg={2} sm={12}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            verticalAlign={"center"}
            sx={{ alignItems: "center" }}
          >
            <div>
              <RatingBlock
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {[...Array(5)].map((_, index) => {
                  const isFilled = overallRating >= index + 1;
                  const isHalfFilled =
                    overallRating > index && overallRating < index + 1;
                  const color =
                    isHalfFilled || isFilled
                      ? "#faaf00"
                      : "rgba(0, 0, 0, 0.25)";
                  const Icon = isHalfFilled
                    ? StarHalfRoundedIcon
                    : isFilled
                    ? StarRoundedIcon
                    : StarBorderRoundedIcon;
                  return (
                    <Icon
                      key={index}
                      style={{
                        width: "60px",
                        height: "60px",
                        color: color,
                        textAlign: "center",
                      }}
                    ></Icon>
                  );
                })}
              </RatingBlock>
            </div>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            verticalAlign={"center"}
            sx={{ alignItems: "center" }}
            paddingBottom={"30px"}
          >
            <Title
              level="h4"
              text={RatingValues[Math.round(overallRating)]}
            ></Title>
          </Stack>
        </Grid>
      )}
      <Grid container justifyContent={"center"}>
        {data?.reviewComments?.length > 0 && (
          <BlockMargin size={30} style={{ textAlign: "center" }}>
            <Grid container>
              <Grid item xs={12}>
                <BodyTextFeedback>
                  {data?.reviewComments[0]?.comments}
                </BodyTextFeedback>
              </Grid>
            </Grid>
          </BlockMargin>
        )}
      </Grid>
    </Grid>
  );
};

export default ProfileHeader;
