import React from "react";

import { Button as MuiButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { ButtonContainer } from "./button.styles";

const Button = ({
  btnText,
  variant,
  onClick,
  loading,
  size,
  loaderColor = "white",
  ...rest
}) => {
  return (
    <ButtonContainer>
      <MuiButton
        disableripple="true"
        size={size ? size : "medium"}
        variant={variant}
        onClick={onClick}
        {...rest}
      >
        {loading ? (
          <div className="btn-progress">
            <CircularProgress
              size={20}
              style={{
                color: loaderColor,
              }}
            />
          </div>
        ) : (
          btnText
        )}
      </MuiButton>
    </ButtonContainer>
  );
};

export default Button;
