export const IsAccesible = (userRoles, roles) => {
  let isAccesible = false;
  for (let role of roles) {
    if (userRoles.includes(role)) {
      isAccesible = true;
    }
  }
  return isAccesible;
};

export const IsJsonString = (objString) => {
  try {
    JSON.parse(objString);
  } catch (_) {
    return false;
  }
  return true;
};

export const LoggoutCleanUp = () => {
  localStorage.clear();
};

export const FormatDate = (date) => {
  let minutes = date.getMinutes();
  let hours = date.getHours();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return `${date.getDay()}-${date.getMonth()}-${date.getFullYear()} at ${strTime}`;
};

export const DataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const addZeroIfSingleDigit = (digit) => {
  if (digit) {
    return digit.toString().length < 2 ? `0${digit.toString()}` : digit;
  }
  if (digit === 0) return "00";
  return "";
};

export const formatDateDMY = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${day}-${month}-${year}   ${addZeroIfSingleDigit(
    hours
  )}:${addZeroIfSingleDigit(minutes)}`;
};

export const capitalizeWord = (word) => {
  if (word) {
    let words = word.toLowerCase().split(" ");
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return "";
};

export function stringAvatar(name) {
  if (name) {
    let nameArray = name.split(" ");
    return `${nameArray[0][0] || ""}${nameArray?.[1] ? nameArray?.[1][0] : ""}`;
  }
  return;
}

export function getTimePassed(createdDate) {
  let currenTime = new Date();
  const diffInMs = currenTime - new Date(createdDate);
  const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
  const diffInYears = Math.floor(diffInMonths / 12);
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInHours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
  const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  let timeDisplay = "";
  if (diffInYears >= 1) {
    timeDisplay =
      diffInYears === 1
        ? `${diffInYears} year ago`
        : `${diffInYears} years ago`;
  } else if (diffInMonths >= 1) {
    timeDisplay =
      diffInMonths === 1
        ? `${diffInMonths} month ago`
        : `${diffInMonths} months ago`;
  } else if (diffInDays >= 1) {
    timeDisplay =
      diffInDays === 1 ? `${diffInDays} day ago` : `${diffInDays} days ago`;
  } else if (diffInHours >= 1) {
    timeDisplay =
      diffInHours === 1
        ? `${diffInHours} hour ago`
        : `${diffInHours} hours ago`;
  } else if (diffInMinutes >= 1) {
    timeDisplay =
      diffInMinutes === 1
        ? `${diffInMinutes} min ago`
        : `${diffInMinutes} min ago`;
  } else {
    timeDisplay = "Just now";
  }
  return timeDisplay;
}

export function getTimePassedinMob(createdDate) {
  let currenTime = new Date();
  const diffInMs = currenTime - new Date(createdDate);
  const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));
  const diffInYears = Math.floor(diffInMonths / 12);
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const diffInHours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);
  const diffInMinutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  let timeDisplay = "";
  if (diffInYears >= 1) {
    timeDisplay = `${diffInYears}Y`;
  } else if (diffInMonths >= 1) {
    timeDisplay = `${diffInMonths}M`;
  } else if (diffInDays >= 1) {
    timeDisplay = `${diffInDays}d`;
  } else if (diffInHours >= 1) {
    timeDisplay = `${diffInHours}h`;
  } else if (diffInMinutes >= 1) {
    timeDisplay = `${diffInMinutes}m`;
  } else {
    timeDisplay = "now";
  }
  return timeDisplay;
}

export const isCandidatePassed = (
  ratingsOfEachQuestions,
  cutOfOfEachQuestions,
  ratingCutOff
) => {
  const totalRatings = ratingsOfEachQuestions?.reduce(
    (a, b) => a + b.rating,
    0
  );
  const overallRating = totalRatings / cutOfOfEachQuestions?.length;
  const totalCutOf =
    ratingCutOff > 0
      ? ratingCutOff
      : cutOfOfEachQuestions?.reduce((a, b) => a + b.cutOf, 0);
  const totalCufOfRating = totalCutOf / cutOfOfEachQuestions?.length;
  const isPassed = overallRating >= totalCufOfRating;
  return { isPassed, overallRating };
};

function getOperatingSystem(window) {
  let operatingSystem = "Not known";
  if (window.navigator.appVersion.indexOf("Win") !== -1) {
    operatingSystem = "Windows OS";
  }
  if (window.navigator.appVersion.indexOf("Mac") !== -1) {
    operatingSystem = "MacOS";
  }
  if (window.navigator.appVersion.indexOf("X11") !== -1) {
    operatingSystem = "UNIX OS";
  }
  if (window.navigator.appVersion.indexOf("Linux") !== -1) {
    operatingSystem = "Linux OS";
  }

  return operatingSystem;
}

function getBrowser(window) {
  let currentBrowser = "Not known";
  if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
    currentBrowser = "Google Chrome";
  } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    currentBrowser = "Mozilla Firefox";
  } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
    currentBrowser = "Internet Exployer";
  } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
    currentBrowser = "Edge";
  } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
    currentBrowser = "Safari";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "Opera";
  } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
    currentBrowser = "YaBrowser";
  } else {
    console.log("Others");
  }

  return currentBrowser;
}

function getDevice(window) {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  let device = regex.test(window.navigator.userAgent);
  if (device) {
    return "Mobile";
  } else {
    return "Desktop";
  }
}

// export const OS = (window) => getOperatingSystem(window);
export const currentBrowser = (window) => getBrowser(window);
export const Device = (window) => getDevice(window);

export function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export const daysInBetween = (date1, date2) => {
  var oneDay = 1000 * 60 * 60 * 24;
  var diffDays = Math.round(
    Math.abs(date2.getTime() - date1.getTime()) / oneDay
  );
  return date2.getTime() > date1.getTime() ? diffDays : diffDays * -1;
};

export const menuNotNeededPage = (url) => {
  return (
    url === "candidate-overview" ||
    url === "candidate-questions" ||
    url === "candidate-interview" ||
    url === "interview-videos" ||
    url === "info-candidate" ||
    url === "reg-submit" ||
    url === "candidate-videos" ||
    url === "candidate-basequestions" ||
    url === "login-failed" ||
    url === "reviewer-login-enc" ||
    url === "candidate-completed-interview" ||
    url === "candidate-profile" ||
    url === "application-success" ||
    url === "interview-settings"
  );
};
