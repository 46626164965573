import { FooterIcons } from "../candidatelist.styles";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { logoClip } from "config/constants";
import browsers from "assets/images/browser.png";

const AdditionalDetails = ({ selectedCandidate }) => {
  const { device, location, browser } = selectedCandidate;
  return (
    <FooterIcons>
      {device && (
        <Tooltip title={device} placement="top">
          <IconButton style={{ marginTop: "-10px" }}>
            {" "}
            {device === "Desktop" ? (
              <DesktopWindowsOutlinedIcon alt="Desktop" />
            ) : (
              <SmartphoneOutlinedIcon alt="Mobile" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {location && (
        <Tooltip title={location} placement="top">
          <IconButton style={{ marginTop: "-10px", marginLeft: "0px" }}>
            <LocationOnOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}

      {browser && (
        <img
          src={browsers}
          alt="browser"
          height={"25px"}
          width={"auto"}
          style={{
            position: "relative",
            cursor: "pointer",
            marginTop: "-2px",
            clipPath: logoClip[browser]?.clip,
            marginLeft: logoClip[browser]?.x,
          }}
        />
      )}
    </FooterIcons>
  );
};

export default AdditionalDetails;
