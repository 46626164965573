import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";

import { Button, Title } from "components";
import {
  SectionContainer,
  Box,
  ContentCenter,
  BlockMargin,
  CheckList,
  CheckListItem,
  FlexColumn,
  PaddingBlock,
} from "assets/styles/main.styles";
import LeftColumnImg from "assets/images/image-371.svg";
import useAuthValidate from "hooks/useAuthValidate";
import PoweredComponent from "components/PoweredComponent/PoweredComponent";
import EmailImage from "assets/images/email_image.png";
import useWindowSize from "hooks/useWindowSize";

const CompletedInverview = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  return (
    <div style={{ padding: "1rem" }}>
      {!isMobile && <BlockMargin size={50}></BlockMargin>}
      <SectionContainer>
        <Container maxWidth="md">
          <Box>
            <PaddingBlock padding={40}>
              <FlexColumn gap={40}>
                <ContentCenter>
                  <BlockMargin size={1}>
                    <Title
                      level="h2"
                      text="Video interview is successfully completed."
                    />
                  </BlockMargin>
                </ContentCenter>
                <Grid
                  container
                  spacing={{ md: 10, sm: 5, xs: 1 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={6} textAlign="center">
                    {/* <img src={LeftColumnImg} alt={LeftColumnImg} /> */}
                    <img
                      src={EmailImage}
                      alt="Email Abstract"
                      height={250}
                      style={{ width: "100%", objectFit: "contain" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} alignSelf={"center"}>
                    <CheckList>
                      {/* <CheckListItem>
                      Our team will be reviewing your interview and will be
                      providing the feedbacks
                    </CheckListItem> */}
                      <CheckListItem>
                        Once evaluation is done,you will be intimated by mail.
                      </CheckListItem>
                      <CheckListItem>
                        This process might take 1-3 days based on the
                        availability of the reviewer.
                      </CheckListItem>
                      <CheckListItem>
                        If there is any issue with the video quality/audio
                        quality our team may reschedule the interview.
                      </CheckListItem>
                    </CheckList>
                  </Grid>
                </Grid>
                <div
                  style={{
                    width: "220px",
                    margin: "0 auto",
                  }}
                >
                  <PoweredComponent />
                </div>
              </FlexColumn>
            </PaddingBlock>
          </Box>
        </Container>
      </SectionContainer>
    </div>
  );
};

export default CompletedInverview;
