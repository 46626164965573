import { useState, useEffect, useRef } from "react";
import Collapse from "@mui/material/Collapse";
import { Input, Tag, Title } from "components";

import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Button } from "components";
import { FlexRow } from "assets/styles/main.styles";
import {
  applicationStatuses,
  bgColorForStatus,
  textColorForStatus,
  selectedColorForStatus,
} from "../utils";
import { StatusBadgeSmall } from "../candidatelist.styles";
import useWindowSize from "hooks/useWindowSize";

const FilterModal = ({
  isFilterModalOpen,
  setIsFilterModalOpen,
  handleApplyFilter,
  handleClearFilter,
  selectedTabId,
}) => {
  const [selectedFilterOptions, setSelectedFilterOptions] = useState({});
  const collapseRef = useRef(null);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  useEffect(() => {
    const handler = (event) => {
      if (!collapseRef.current) {
        return;
      }
      // if click was not inside of the element. "!" means not
      // in other words, if click is outside the modal element
      if (!collapseRef.current.contains(event.target)) {
        setIsFilterModalOpen(false);
      }
    };
    // the key is using the `true` option
    // `true` will enable the `capture` phase of event handling by browser
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  const applyFilter = () => {
    handleApplyFilter(selectedFilterOptions);
  };
  const clearFilter = () => {
    setSelectedFilterOptions({});
    handleClearFilter();
  };

  useEffect(() => {
    setSelectedFilterOptions({});
  }, [selectedTabId]);

  return (
    <Collapse
      in={isFilterModalOpen}
      timeout="auto"
      unmountOnExit
      ref={collapseRef}
      sx={{
        width: "400px",
        padding: "20px",
        border: "2px solid #ccc",
        bgcolor: "background.paper",
        zIndex: 1000,
        position: "absolute",
        overflow: "auto",
        marginTop: "5em",
        left: isMobile ? "2em" : "15em",
        borderRadius: "4px",
      }}
    >
      <form>
        <Input
          placeholder="Search by Candidate"
          onChange={(e) =>
            setSelectedFilterOptions({
              ...selectedFilterOptions,
              search: e.target.value,
            })
          }
          style={{
            border: ".1px solid #EAEAEA",
            backgroundColor: "#EAEAEA",
            marginBottom: "10px",
          }}
          // value={selectedFilterOptions?.search}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Title level="h4" text="Status" style={{ padding: "10px 0" }} />
            {applicationStatuses &&
              Object.keys(applicationStatuses).map((item) => {
                return (
                  <FlexRow
                    key={item}
                    gap={5}
                    style={{ alignItems: "center", margin: 0 }}
                  >
                    <Checkbox
                      size="small"
                      color="secondary"
                      checked={selectedFilterOptions?.status?.includes(item)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          e.target.checked = true;
                          let newStatuses = [];
                          if (selectedFilterOptions?.status) {
                            newStatuses = selectedFilterOptions?.status;
                          }
                          newStatuses.push(item);
                          setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            status: newStatuses,
                          });
                        } else {
                          const filteredStatus =
                            selectedFilterOptions?.status?.filter(
                              (item1) => item1 !== item
                            );
                          setSelectedFilterOptions({
                            ...selectedFilterOptions,
                            status: filteredStatus,
                          });
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <StatusBadgeSmall
                      color={bgColorForStatus[item]}
                      textColor={textColorForStatus[item]}
                      style={{
                        backgroundColor: selectedColorForStatus[item]?.bgColor
                          ? selectedColorForStatus[item]?.bgColor
                          : bgColorForStatus[item],
                        color: selectedColorForStatus[item]?.color
                          ? selectedColorForStatus[item]?.color
                          : textColorForStatus[item],
                      }}
                      className="tag"
                    >
                      {applicationStatuses[item]}
                    </StatusBadgeSmall>
                    {/* <Tag
													type={applicationStatuses[item]}
													label={applicationStatuses[item]}
													size="medium"
												/> */}
                    {/* <Title level="label" text={applicationStatuses[item]} /> */}
                  </FlexRow>
                );
              })}
          </Grid>
          <Grid item xs={6}>
            <Title
              level="h4"
              text="Notice Period"
              style={{ padding: "10px 0" }}
            />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group"
              name="row-radio-buttons-group"
              value={selectedFilterOptions?.noticePeriod}
              onChange={(e) =>
                setSelectedFilterOptions({
                  ...selectedFilterOptions,
                  noticePeriod: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="All"
                sx={{
                  width: "100%",
                  paddingLeft: "10px",
                  "> .MuiTypography-root": {
                    fontSize: "14px",
                  },
                }}
              />
              {[7, 15, 30, 60, 90].map((item, index) => {
                return (
                  <FormControlLabel
                    value={item}
                    control={<Radio />}
                    label={`${item} Days`}
                    key={index}
                    sx={{
                      width: "100%",
                      paddingLeft: "10px",
                      "> .MuiTypography-root": {
                        fontSize: "14px",
                      },
                    }}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
        </Grid>
        <hr
          style={{
            border: "none",
            borderTop: "1px solid #ccc",
            margin: "20px 0",
          }}
        />
        <FlexRow gap={5} style={{ justifyContent: "flex-end" }}>
          <Button
            btnText="Clear All"
            variant="text"
            size="small"
            onClick={clearFilter}
          />
          <Button
            btnText="Apply"
            variant="contained"
            color="primary"
            size="small"
            onClick={applyFilter}
          />
        </FlexRow>
      </form>
    </Collapse>
  );
};

export default FilterModal;
