import { decrypt } from "utils/encrypt";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Modal } from "components";
import { Box, ReviewModalHeader } from "assets/styles/main.styles";
import { getCandidateDetails } from "config/api.service";
import ResumePreview from "components/ResumePreview/ResumePreview";
import Container from "@mui/material/Container";
import { Header, Footer } from "components";
import InterviewQuestions from "./InterviewQuestions";
import ProfileHeader from "./ProfileHeader";
import ProfileNavbar from "./ProfileNavbar";
import SingleInterviewQuestion from "./SingleInterviewQuestion";

function Profile() {
  let [searchParams] = useSearchParams();
  let data = searchParams.get("id");

  let { id, role } = decrypt(data.replaceAll(" ", "+"));
  const [candidateData, setCandidateData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [resumeFile, setResumeFile] = useState("");
  const [selectedQuestionDetails, setSelectedQuestionDetails] = useState({});
  const [selectedQuestionNo, setSelectedQuestionNo] = useState(0);
  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    if (id && role) {
      async function getCandidateData() {
        const res = await getCandidateDetails(id, role);
        if (res.status === "success") {
          setResumeFile(
            res.data?.resumeDir?.length > 0
              ? JSON.parse(res.data?.resumeDir[0]?.docMeta).Location
              : null
          );
          setCandidateData(res.data);
          let defaultReviewData = res?.data?.jobrole.interviewQuestions.map(
            (item) => {
              return {
                questionId: item.id,
                rating: 0,
              };
            }
          );
          if (res?.data?.Feedback?.length > 0) {
            res?.data?.Feedback?.forEach((item) => {
              defaultReviewData.forEach((data) => {
                if (data.questionId === item.questionId) {
                  data.rating = item.rating;
                }
              });
            });
          }
          setReviewData(defaultReviewData);
        }
      }
      getCandidateData();
    }
  }, [id, role]);

  return (
    <>
      <Header />
      <Container maxWidth={false} style={{ padding: 0 }}>
        <div style={{ padding: "64px" }}>
          <Box>
            <ProfileHeader data={candidateData} />
            <ProfileNavbar
              resumeFile={resumeFile}
              handleResumePreview={() => {
                setIsResumePreviewOpen(true);
              }}
            />
            <Grid container spacing={4} sx={{ marginTop: "10px" }}>
              {candidateData?.AnswerPool?.map((item, i) => {
                const vidMeta = JSON.parse(item?.vidMeta);
                const data = {
                  qId: item.qId,
                  ...vidMeta,
                };
                return (
                  <Grid item sm={12} md={6} lg={4} xl={3} key={i}>
                    <InterviewQuestions
                      data={data}
                      currentQuestionNo={i + 1}
                      handleModalOpen={() => {
                        setIsModalOpen(true);
                        setSelectedQuestionDetails(data);
                        setSelectedQuestionNo(i + 1);
                      }}
                      reviewData={reviewData}
                      feedback={candidateData?.Feedback}
                    />
                  </Grid>
                );
              })}
            </Grid>
            {isModalOpen && (
              <Modal
                size="md"
                open={isModalOpen}
                close={() => setIsModalOpen(false)}
                title={<ReviewModalHeader></ReviewModalHeader>}
                sx={{ zIndex: 1000 }}
                children={
                  <SingleInterviewQuestion
                    selectedQuestionDetails={selectedQuestionDetails}
                    selectedQuestionNo={selectedQuestionNo}
                    interviewDetails={candidateData?.AnswerPool}
                  />
                }
              />
            )}
          </Box>

          <ResumePreview
            isResumePreviewOpen={isResumePreviewOpen}
            handleResumePreview={() => {
              setIsResumePreviewOpen(false);
            }}
            resumePath={resumeFile}
          />
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default Profile;
