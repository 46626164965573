import { useState } from "react";
import useWindowSize from "hooks/useWindowSize";
import Card from "@mui/material/Card";
import { VideoContainer, PlayButton } from "assets/styles/main.styles";
import CardMedia from "@mui/material/CardMedia";
import VideoThumbnailPlaceholder from "assets/images/video-thumbnail.svg";
import Play from "assets/images/play.png";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { RatingValues } from "config/constants";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

const InterviewQuestions = ({
  data,
  currentQuestionNo,
  handleModalOpen,
  reviewData,
  feedback,
}) => {
  const [thumbnailIsPresent, setThumbnailIsPresent] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const questionFeedback = feedback?.find(
    (item) => item.questionId === data?.qId
  );
  const isImagePresent = () => {
    var image = new Image();
    image.src = data?.vidMeta?.assets?.thumbnail;
    if (image.complete) {
      setThumbnailIsPresent(true);
    } else {
      image.onload = () => {
        setThumbnailIsPresent(true);
      };

      image.onerror = () => {
        setThumbnailIsPresent(false);
      };
    }
  };

  isImagePresent();
  return (
    <div style={{ position: "relative" }}>
      <Card
        sx={{
          width: "100%",
          cursor: "pointer",
          "&:hover": {
            transform: "translateY(-1px)",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
          },
        }}
        variant="outlined"
        elevation={6}
        onClick={() => {
          handleModalOpen();
        }}
      >
        <VideoContainer>
          {thumbnailIsPresent ? (
            <CardMedia
              sx={{ height: 230, width: "100%" }}
              image={data?.vidMeta.assets.thumbnail}
              title=""
            />
          ) : (
            <CardMedia
              sx={{ height: 230, width: "100%" }}
              image={VideoThumbnailPlaceholder}
              title=""
            />
          )}
          <PlayButton>
            <img src={Play} alt="play" />
          </PlayButton>
        </VideoContainer>
        <CardContent sx={{ paddingBottom: 0 }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              maxHeight: "12vh",
              margin: 0,
              fontSize: "1rem",
              whiteSpace: isMobile ? "wrap" : "nowrap",
              overflow: "hidden",
              width: "100%",
              textOverflow: "ellipsis",
            }}
          >
            {`${currentQuestionNo}. ${data?.question}`}
          </Typography>
        </CardContent>
        <div
          style={{
            padding: "20px",
          }}
        >
          {feedback.length === reviewData.length ? (
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Stack direction="row" spacing={2}>
                  <Rating
                    name="hover-feedback"
                    value={questionFeedback?.rating}
                    precision={1}
                    readOnly
                  />
                  {questionFeedback?.rating !== null && (
                    <span style={{ paddingTop: "5px" }}>
                      {RatingValues[questionFeedback?.rating]}
                    </span>
                  )}
                </Stack>
              </Grid>
            </Grid>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default InterviewQuestions;
