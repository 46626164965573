import auth0 from "auth0-js";

// dev auth
// export const AUTH0_DOMAIN = "auth.kapiree.com";
// export const AUTH0_CLIENT_ID = "6rpZqMh7mmpx8EBdqGKnIbWBG1MFG33R";

// prod auth
export const AUTH0_DOMAIN = "auth.kapiree.com";
export const AUTH0_CLIENT_ID = "TSBSb0S9ZFeVQ1rwjspPql4gu0gLqkwW";

export const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
});
