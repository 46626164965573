import styled from "styled-components";

export const StatusCard = styled.div`
  border-top: 6px solid;
  border-top-color: ${(props) => props.color};
  padding: 0.5em 1rem;
  background-color: #fff;
  color: #7e7e7e;
  height: 100%;
  // height: 100%;
  h1 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    padding-top: 1em;
  }
  @media (max-width: 768px) {
    border-top: none;
    border-left: 6px solid ${(props) => props.color};
    border-radius: 0;
    margin-bottom: 10px;

    max-height: 70px;
  }
`;

export const StatusBadge = styled.div`
  background-color: ${(props) => props.color};
  padding: 0.5rem 1rem;
  display: inline-flex;
  color: black;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  border-radius: 5px;
  text-transform: upppercase;
`;

export const StatusBadgeSmall = styled(StatusBadge)`
  color: ${(props) => props.textColor || "#111"};
  margin-top: 6px;
  padding: 0.25rem 0.5rem;
`;

export const OtherRatingsWrapper = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    label {
      display: block;
    }
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 700px;
  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    &-track {
      background: rgba(0, 0, 0, 0.1);
    }
    &-thumb {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }
`;

export const SelectedScrollContainer = styled(ScrollContainer)`
  //max-height: 900px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
  }
`;

export const SmallText = styled.p`
  font-size: 13px;
`;

export const CandidateListContainer = styled.div`
  min-width: 390px;
  max-width: 390px;
  flex: 0 0 auto;
  overflow-x: hidden;
  .candidate-card {
    cursor: pointer;
    display: flex;
    padding: 8px 2px 4px 8px;
    border-radius: 10px;
    margin: 6px 0px;
    width: 340px;
    scroll-width: none;
    overflow-x: clip;
  }
  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    .candidate-card {
      width: 100%;
    }
  }
`;
