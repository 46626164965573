import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useRef, useState } from "react";

export const InterviewContext = createContext(null);

export const InterviewProvider = ({ children }) => {
  const [isQuestionLoader, setIsQuestionLoader] = useState(false);
  const screenShareStreamRef = useRef(null);
  return (
    <>
      <InterviewContext.Provider value={{ isQuestionLoader, setIsQuestionLoader, screenShareStreamRef }}>
        {children}
      </InterviewContext.Provider>
    </>
  );
};

export const useInterview = () => useContext(InterviewContext);
