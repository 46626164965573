import styled, { css } from "styled-components";

export const CardContainer = styled.div`
  padding: 20px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  height: ${(props) => (props.num ? "auto" : "125px")};
  position: relative;
  min-height: 75px;
  text-align: center;
`;

export const CardImg = styled.img`
  max-width: 100%;
  display: block;
`;

export const CardText = styled.h5(
  ({
    theme: {
      colors: { primaryBlue, blueGrey },
      fontSize,
      fontWeight,
    },
  }) => css`
    font-weight: ${fontWeight.light};
    font-size: ${fontSize.f14};
    line-height: 19px;
    color: ${blueGrey};
    transition: 0.3s all ease-in-out;
  `
);

export const TitleContainer = styled.div`
  margin-top: 10px;
  p {
    line-height: 18px;
  }
`;

export const NumberCount = styled.h5(
  ({
    theme: {
      colors: { primaryBlue, red, orange, green },
      fontWeight,
    },
  }) => css`
    font-weight: ${fontWeight.regular};
    font-size: 2.5rem;
    // line-height: 66px;
    padding-top: 0;
    min-height: 20px;
    flex-shrink: 0;
    color: ${(props) => {
      switch (props.styledColor) {
        case "Total Applicants":
          return `#33ACFF`;
        case "Invited by HR":
          return `#8633FF`;
        case "Video Interview Completed":
          return `#FF33AC`;
        case "Review Pending":
          return `${red}`;
        case "Shortlisted":
          return `#3AC419`;
        default:
          return `${primaryBlue}`;
      }
    }};
  `
);

export const HrsText = styled.span(
  ({
    theme: {
      colors: { primaryBlue },
      fontSize,
      fontWeight,
    },
  }) => css`
    font-weight: ${fontWeight.semibold};
    font-size: ${fontSize.f16};
    line-height: 16px;
    color: ${primaryBlue};
    margin-left: 2px;
    float: left;
  `
);

export const TagBlock = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
`;
