import React from "react";
import { Grid } from "@mui/material";
import { Title } from "components";
import { BlockMargin } from "assets/styles/main.styles";

const BasicAnswers = ({ selectedCandidate }) => {
  return (
    <div style={{ padding: "1rem" }} id="basicAnswers">
      <Title level="h4" text="Basic Question Answers" />
      <BlockMargin size={30} />
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        style={{ paddingLeft: "1rem" }}
      >
        <>
          <Grid
            item
            sm={6}
            style={{
              backgroundColor: "#F1F1F1",
              borderRadius: "4px 0px 0px 4px",
              padding: "8px 8px 8px 20px",
            }}
            className="hide-on-mobile"
          >
            <Title
              level="label"
              text="Question"
              style={{ fontSize: "12px", fontWeight: "900" }}
            />
          </Grid>
          <Grid
            item
            sm={6}
            style={{
              backgroundColor: "#F1F1F1",
              borderRadius: "0px 4px  4px 0px",
              padding: "8px 8px 8px 20px",
            }}
            className="hide-on-mobile"
          >
            <Title
              level="label"
              text="Answer"
              style={{ fontSize: "12px", fontWeight: "900" }}
            />
          </Grid>
        </>

        <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
          {selectedCandidate?.jobrole?.baseQuestions?.map((item, index) => (
            <React.Fragment key={index}>
              <>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{
                    fontSize: "14px",
                    border: "1px solid #F1F1F1",
                    padding: "8px 8px 8px 20px",
                    lineHeight: "1.7",
                  }}
                >
                  {item.question}
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    border: "1px solid #F1F1F1",
                    padding: "8px 8px 8px 20px",
                    lineHeight: "1.7",
                  }}
                >
                  {selectedCandidate?.BaseQuestionsAnswers[index]?.answer ??
                    " "}
                </Grid>
              </>
            </React.Fragment>
          ))}
        </div>
      </Grid>
    </div>
  );
};

export default BasicAnswers;
