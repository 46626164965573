import React, { useState, useEffect } from "react";
import { PageHeader, Dialog, Title, Button } from "components";
import AddIcon from "@mui/icons-material/Add";
import { Box, ModalButtonWrapper } from "assets/styles/main.styles";
import SubscriptionListingTable from "./components/SubscriptionListingTable";
import CreateSubscription from "./components/CreateSubscription";
import { deleteSubscription, getSubscription } from "config/api.service";
import { useAlert } from "contexts/alert.context";

const Subscription = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showAlert } = useAlert();

  useEffect(() => {
    setLoading(true);
    getCategories();
  }, []);

  async function getCategories() {
    getSubscription(function (result) {
      setData(result);
      setLoading(false);
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEdit = (details) => {
    setIsEdit(true);
    setSelectedSubscription(details);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSubscription({});
    getCategories();
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setIsDeleteOpen(true);
  };

  const deleteConfirmed = async () => {
    deleteSubscription({ id: deleteId }, successCallback, errorCallback);
  };

  const successCallback = () => {
    showAlert("Subscription deleted successfully", "success");
    handleIsDelete();
    getCategories();
  };

  const errorCallback = (msg) => {
    handleIsDelete();
    showAlert(msg, "error");
  };

  const handleIsDelete = () => {
    setIsDeleteOpen(false);
  };

  return (
    <div style={{ padding: "2rem 1rem" }}>
      <PageHeader
        buttonIcon={<AddIcon />}
        pageTitleText="Subscription"
        // btnText="Create New Subscription"
        // onClick={handleClickOpen}
      />
      <Box>
        <SubscriptionListingTable
          isCompleteList
          handleClickOpen={handleClickOpen}
          handleClickEdit={handleClickEdit}
          handleDelete={handleDelete}
          data={data}
          isLoading={loading}
          hideSearch
          hidePagination
        />
      </Box>

      {isDeleteOpen && (
        <Dialog
          size="md"
          open={isDeleteOpen}
          close={handleIsDelete}
          title={<>Delete Subscription ?</>}
          children={
            <>
              <Title level="h6" text="Once deleted, cannot revert back. Are you sure ?" />
              <ModalButtonWrapper>
                <Button variant="contained" btnText="Yes" onClick={deleteConfirmed} />
                <Button variant="outlined" btnText="No" onClick={handleIsDelete} />
              </ModalButtonWrapper>
            </>
          }
        />
      )}
    </div>
  );
};

export default Subscription;
