import { webAuth } from "config/auth-config";
import { appUrl } from "config/constants";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoggoutCleanUp } from "utils";
import useLocalStorage from "./useLocalStorage";

const useAuthValidate = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value] = useLocalStorage("accessToken");
  const location = useLocation();
  const isLoadingRef = useRef(true);
  const navigate = useNavigate();

  const failedValidation = useCallback((err) => {
    setIsLoading(false);
    isLoadingRef.current = false;
    if (err?.statusCode == 429) return;

    console.error("AUTH0 Validation Failed : ", err?.statusCode);
    LoggoutCleanUp();
    setError(err);
    navigate("/");
  }, []);

  const getUserAuth = useCallback(
    (token) => {
      try {
        webAuth.client.userInfo(token, (err, result) => {
          if (err) {
            failedValidation(err);
          } else {
            setUserDetails(result);
            setIsLoading(false);
            isLoadingRef.current = false;
            localStorage.setItem("userId", result.sub);
          }
        });
      } catch (error) {
        failedValidation(error);
      }
    },
    [failedValidation]
  );

  const processHash = useCallback(
    (hash) => {
      webAuth.parseHash({ hash }, (err, result) => {
        if (err) {
          console.log(err);
        } else {
          const tempKeys = Object.keys(result);
          for (let element of tempKeys) {
            localStorage.setItem([element], result[element]);
          }
          if (result["accessToken"]) {
            getUserAuth(result["accessToken"]);
          }
        }
      });
    },
    [getUserAuth]
  );

  useEffect(() => {
    if (location?.hash && !userDetails) {
      // setIsLoading(true);
      isLoadingRef.current = true;
      processHash(location.hash);
    } else {
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  }, [location, processHash, userDetails]);

  useEffect(() => {
    if (value) {
      // setIsLoading(true);
      isLoadingRef.current = true;
      getUserAuth(value);
    } else {
      setUserDetails(null);
    }
  }, [value]);

  const logOutHandler = useCallback(() => {
    LoggoutCleanUp();
    setUserDetails(null);
    webAuth.logout(
      {
        redirectUri: appUrl,
        realm: "Username-Password-Authentication",
      },
      (err, result) => {
        if (err) {
          console.log("failed to logout");
          return;
        }
      }
    );
  }, []);

  return {
    userDetails,
    error,
    isLoading,
    isLoadingRef: isLoadingRef.current,
    logOutHandler,
  };
};

export default useAuthValidate;
