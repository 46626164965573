import { useState } from "react";
import { Avatar, Grid, Stack } from "@mui/material";
import { Title, Modal } from "components";
import { stringAvatar, getTimePassed } from "utils";
import { format } from "date-fns";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import Collapse from "@mui/material/Collapse";
import ResumePreview from "components/ResumePreview/ResumePreview";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Divider from "@mui/material/Divider";
import {
  SubtitleInfo,
  ButtonIconCenterAligned,
} from "assets/styles/main.styles";
import { SmallText } from "../candidatelist.styles";

const linkStyles = {
  textDecoration: "solid",
  color: "#4286F5",
  cursor: "pointer",
};

const Profile = ({ candidate, onBasicAnswerNavClicked }) => {
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);
  const [contactCardOpen, setContactCardOpen] = useState(false);
  if (!candidate.candidateId) return <></>;
  let resumeFile =
    candidate?.resumeDir?.length > 0
      ? JSON.parse(candidate?.resumeDir[0]?.docMeta).Location
      : null;

  let kycFile =
    candidate?.KycPool?.length > 0
      ? JSON.parse(candidate?.KycPool[0]?.kycMeta).Location
      : null;
  const vidMeta = candidate?.AnswerPool[0]?.vidMeta
    ? JSON.parse(candidate?.AnswerPool[0]?.vidMeta)
    : {};
  let thumbnail = vidMeta?.vidMeta?.assets?.thumbnail;
  function getDisplayTime() {
    let displayTime = "";
    if (candidate?.AnswerPool?.length > 0) {
      const vidMeta =
        candidate?.AnswerPool[candidate?.AnswerPool?.length - 1]?.vidMeta;
      const vidMet = JSON.parse(vidMeta);
      displayTime =
        format(vidMet?.vidMeta?.createdAt, "PPp") +
        "(" +
        getTimePassed(vidMet?.vidMeta?.createdAt) +
        ")";
    } else if (candidate?.createdDate) {
      displayTime =
        format(candidate?.createdDate, "PPp") +
        "(" +
        getTimePassed(candidate?.createdDate) +
        ")";
    }
    return displayTime;
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Grid container justifyContent="flex-start" spacing={4} alignItems="left">
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={{ md: 4, xs: 2 }}
            direction={{ md: "row", xs: "column" }}
            textAlign={{ sm: "center", md: "left" }}
          >
            <Grid item xs="auto">
              <Avatar
                src={thumbnail && thumbnail}
                sx={{
                  width: "134px",
                  height: "134px",
                  fontSize: "42px",
                  color: "#A2DFFF",
                  border: "2px solid #C1EAFF",
                  bgcolor: "#E0F5FF",
                  objectFit: "contain",
                }}
              >
                {stringAvatar(candidate?.name)}
              </Avatar>
            </Grid>
            <Grid item xs>
              <Title
                level="h2"
                text={candidate?.name}
                paddingTop={"40px"}
                color="black"
              />
              <SubtitleInfo>{getDisplayTime()}</SubtitleInfo>
              {candidate?.noticePeriod && (
                <SubtitleInfo>
                  Notice Period: {candidate?.noticePeriod} days
                </SubtitleInfo>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={{ md: 2, xs: 1 }}
            minWidth={{ md: "260px", xs: "100%" }}
            alignItems={{ md: "flex-end", xs: "flex-start" }}
            justifyContent={{ md: "flex-end", xs: "center" }}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <ButtonIconCenterAligned>
              <DescriptionOutlinedIcon
                sx={{
                  color: resumeFile ? "#4286F5" : "lightgray",
                  cursor: resumeFile ? "pointer" : "not-allowed",
                  paddingRight: "5px",
                }}
              />
              <div>
                <div
                  style={{
                    textDecoration: "solid",
                    marginTop: " 4px",
                    color: resumeFile ? "#4286F5" : "lightgray",
                    cursor: resumeFile ? "pointer" : "not-allowed",
                  }}
                  disabled={resumeFile == "" || resumeFile == null}
                  onClick={() => setIsResumeModalOpen(true)}
                >
                  Resume
                </div>
              </div>
            </ButtonIconCenterAligned>
            <ButtonIconCenterAligned>
              <ContactPageOutlinedIcon
                sx={{
                  color: kycFile ? "#4286F5" : "lightgray",
                  cursor: kycFile ? "pointer" : "not-allowed",
                  paddingRight: "5px",
                }}
              />
              <div
                style={{
                  textDecoration: "solid",
                  marginTop: " 4px",
                  color: kycFile ? "#4286F5" : "lightgray",
                  cursor: kycFile ? "pointer" : "not-allowed",
                }}
                disabled={kycFile == "" || kycFile == null}
                onClick={() => setIsKycModalOpen(true)}
              >
                KYC
              </div>
            </ButtonIconCenterAligned>
            <ButtonIconCenterAligned>
              <MailOutlineIcon
                sx={{ paddingRight: "5px" }}
                onClick={() => setContactCardOpen(!contactCardOpen)}
              />
              <div
                style={{
                  ...linkStyles,
                  marginTop: "4px",
                }}
                onClick={() => setContactCardOpen(!contactCardOpen)}
              >
                Contact
              </div>
              {/* {contactCardOpen ? <ExpandLess /> : <ExpandMore />} */}
            </ButtonIconCenterAligned>
            <ButtonIconCenterAligned>
              <span style={linkStyles} onClick={onBasicAnswerNavClicked}>
                <HelpOutlineIcon sx={{ paddingRight: "5px" }} />
                Basic Answers
              </span>
            </ButtonIconCenterAligned>
          </Stack>
          <Collapse
            in={contactCardOpen}
            timeout="auto"
            unmountOnExit
            sx={{
              width: "250px",
              marginLeft: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "4px",
            }}
          >
            <div>
              <SmallText style={{ paddingBottom: "5px" }}>
                {candidate?.phoneNumber}
              </SmallText>
            </div>
            <SmallText>{candidate?.email}</SmallText>
          </Collapse>
        </Grid>
      </Grid>
      {isResumeModalOpen && (
        <ResumePreview
          isResumePreviewOpen={isResumeModalOpen}
          handleResumePreview={() => setIsResumeModalOpen(false)}
          resumePath={resumeFile}
        />
      )}
      {isKycModalOpen && (
        <Modal
          size="md"
          open={isKycModalOpen}
          close={() => setIsKycModalOpen(false)}
          title="KYC Image"
          children={
            <img src={kycFile} alt="kyc" style={{ maxWidth: "100%" }} />
          }
        />
      )}
    </div>
  );
};

export default Profile;
