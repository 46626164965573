import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { ButtonIcon } from "assets/styles/main.styles";

const ProfileNavbar = ({ resumeFile, handleResumePreview }) => {
  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems="left"
      sx={{ marginBottom: "10px" }}
    >
      <Stack
        direction="row"
        spacing={{ xs: 1, sm: 2 }}
        flexWrap="wrap"
        justifyContent="flex-start"
        paddingTop={"30px"}
      >
        <ButtonIcon>
          <DescriptionOutlinedIcon
            sx={{
              color: resumeFile == null ? "lightgray" : "#4286F5",
              cursor: resumeFile == null ? "not-allowed" : "pointer",
            }}
          />
          <div disabled={resumeFile === null}>
            <div
              style={{
                textDecoration: "solid",
                marginTop: " 4px",
                color: resumeFile == null ? "lightgray" : "#4286F5",
                cursor: resumeFile == null ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (resumeFile != null) {
                  handleResumePreview();
                }
              }}
            >
              Resume
            </div>
          </div>
        </ButtonIcon>
      </Stack>
    </Grid>
  );
};

export default ProfileNavbar;
