import { Grid, Select, MenuItem, Button } from "@mui/material";
import { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import { composer } from "../../utils";

export const Actions = ({
  onRecord,
  onStopRecording,
  isRecording,
  isSubmitting,
  allQuestionsAnswered,
}) => {
  const [audioSource, setAudioSource] = useState("default");
  const [audioSources, setAudioSources] = useState([]);
  const [isKeptDisabled, setIsKeptDisabled] = useState(false);

  useEffect(() => {
    const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));

    navigator.mediaDevices
      .getUserMedia({
        video: deviceIds ? deviceIds.videoDeviceId : true,
        audio: true,
      })
      .then((stream) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setAudioSources(
            devices.filter((device) => device.kind === "audioinput")
          );
          // stream.getTracks().forEach((x) => x.stop());
        });
      });
  }, [navigator]);

  return (
    <>
      {isSubmitting ? (
        <LoadingButton
          disabled
          variant="contained"
          loading
          loadingPosition="end"
          endIcon={<></>}
          sx={{
            width: "140px",
            "&MuiLoadingButton-loadingIndicator": {
              marginLeft: "-10px",
            },
          }}
        >
          Saving
        </LoadingButton>
      ) : allQuestionsAnswered ? (
        <></>
      ) : !isRecording ? (
        <Button
          style={{ whiteSpace: "nowrap" }}
          variant="contained"
          className="interview-start-button"
          onClick={() => {
            onRecord();
            setIsKeptDisabled(true);
            setTimeout(() => {
              setIsKeptDisabled(false);
            }, 10000);
          }}
        >
          Start Recording
        </Button>
      ) : (
        <Button
          variant="contained"
          className="interview-stop-button"
          onClick={onStopRecording}
          style={{ backgroundColor: "#ff0000" }}
          disabled={isKeptDisabled}
        >
          Stop Recording
        </Button>
      )}
    </>
  );
};
