import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";

import { Button, Checkbox, Title } from "components";

import InterviewIllustration from "assets/images/interviewIllustration.svg";

import {
  checkInterviewIsSubmitted,
  getCandidateInterviewLoadDetails,
} from "config/api.service";
import {
  SectionContainer,
  Box,
  ContentCenter,
  BlockMargin,
  CandidateCompanyLogo,
  InterviewIllustrationImage,
  CheckList,
  CheckListItem,
  TermsCheckbox,
} from "assets/styles/main.styles";
import { getCandidateInterviewDetails } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { useInterviewDataContext } from "contexts/interviewData";
import useFullscreen from "hooks/useFullscreen";
import useWindowSize from "hooks/useWindowSize";
import { webAuth } from "config/auth-config";
import { appUrl } from "config/constants";

const CandidateOverview = () => {
  const navigate = useNavigate();
  const [interviewUserData, setInterviewUserData] = useState({});
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { setInterviewData, setAnswerData } = useInterviewDataContext();
  const { toggle } = useFullscreen();

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const id = sessionStorage.getItem("uniqueInterviewId");

  const checkUser = async (email) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        webAuth.client.userInfo(token, (err, result) => {
          if (err) {
            console.log(err);
          } else {
            if (result.email != email) {
              localStorage.clear();
              navigate(`/login-failed`, { replace: true });
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const handleInterviewData = (data) => {
  //   let metaData = data.map((item) => JSON.parse(item.vidMeta));
  //   if (metaData.length > 0) {
  //     navigate("/candidate-interview", {
  //       state: { interviewData: interviewUserData },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (Object.keys(interviewUserData).length > 0) {
  //     getCandidateInterviewLoadDetails(id, handleInterviewData);
  //   }
  // }, [interviewUserData]);

  // async function checkWhetherInterviewIsFinished(inviteId) {
  //   const response = await checkInterviewIsSubmitted(inviteId, () =>
  //     console.log("Something went wrong!")
  //   );

  //   if ((await response.data.details) == undefined) {
  //     alert("Please double check your invite link!");
  //     // localStorage.removeItem("uniqueInterviewId");
  //     // setTimeout(() => {
  //     //   window.location.href = "https://kapiree.com/";
  //     // }, 1000);
  //   }
  //   if ((await response.data.details?.length) > 0) {
  //     if ((await response.data.details[0].isSubmitted) == 0) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   }
  // }

  const handleCandidateInfo = (data) => {
    // localStorage.clear();
    // localStorage.removeItem("uniqueInterviewId");
    const isSubmitted = data?.CandidateList?.InterviewPool[0]?.isSubmitted;
    const Interviewstatus = data?.CandidateList?.InterviewPool[0]?.status;
    checkUser(data?.CandidateList?.email);
    if (isSubmitted) {
      alert("You have already submitted the interview!");
    } else {
      if (Interviewstatus == 6) {
        (async () =>
          await getCandidateInterviewLoadDetails(id, (response) => {
            const answers = response.map((item) => {
              return {
                vidMeta: JSON.parse(item.vidMeta).vidMeta,
                isRecorded: true,
                isSubmitted: true,
                question: JSON.parse(item.vidMeta).question,
                retake: JSON.parse(item.vidMeta).retake,
                qId: item.qId,
              };
            });
            setAnswerData(answers);
          }))();
        navigate("/candidate-interview", { replace: true });
      }
      setInterviewUserData(data);
      setInterviewData(data); // storing in the custom hook to be used in the other pages
    }
    // localStorage.setItem("interviewData", JSON.stringify(data));
  };

  useEffect(() => {
    (async () => {
      if (id) {
        await getCandidateInterviewDetails(id, handleCandidateInfo);
      }
    })();
  }, [id]);

  const handleAgreeTermsCondition = async () => {
    const isFromWebsite = interviewUserData?.CandidateList?.fromWebsite;
    if (isFromWebsite) {
      // if (interviewUserData?.jobrole?.isKycRequired) {
      //   navigate("/candidate-interview/kyc");
      //   toggle();
      //   return;
      // }
      // navigate("/interview-instructions");
      navigate("/interview-settings");
    } else {
      navigate("/candidate-questions");
    }
  };

  return (
    <div style={{ padding: "1rem" }}>
      {!isMobile && <BlockMargin size={50}></BlockMargin>}

      {Object.keys(interviewUserData).length > 0 ? (
        <SectionContainer>
          <Container maxWidth="md">
            <Box>
              <ContentCenter>
                <BlockMargin size={10}>
                  <CandidateCompanyLogo
                    src={interviewUserData?.jobrole?.organization?.orgLogo}
                    alt={interviewUserData?.jobrole?.organization?.orgName}
                    style={{ marginBottom: "1rem" }}
                  />
                  <Title
                    level="h6"
                    text={`${interviewUserData?.jobrole?.organization?.orgName} - ${interviewUserData?.jobrole?.roleName}`}
                  />
                </BlockMargin>
                <BlockMargin size={20}>
                  <Title
                    level="h1"
                    text="You are invited for a video interview"
                  />
                </BlockMargin>
              </ContentCenter>
              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ padding: "0 1rem" }}
              >
                <Grid item xs={12} sm={6} textAlign="center">
                  <InterviewIllustrationImage
                    src={InterviewIllustration}
                    alt={InterviewIllustration}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <BlockMargin size={10}>
                    <CheckList>
                      <CheckListItem>
                        This interview will be an un monitored Video recording
                        session. We urge you to provide the best of your
                        presentation & make it impactful.
                      </CheckListItem>
                      <CheckListItem>
                        Once you Start the interview finish the interview in a
                        single stretch, once you leave you wont be able to re
                        attend the interview
                      </CheckListItem>
                      <CheckListItem>
                        Certain questions will only be shown 10s before you can
                        answer it.
                      </CheckListItem>
                      <CheckListItem>
                        Keep a Govt ID proof ready to verify your identity and
                        increace the chance of better rating
                      </CheckListItem>
                      <CheckListItem>
                        You will be shared(Mail/Whatsaap) with the interview
                        ratings once its reviewed by the Interview panel
                      </CheckListItem>
                    </CheckList>
                  </BlockMargin>
                  <TermsCheckbox>
                    <Checkbox
                      label="I agree to the"
                      checked={acceptTerms}
                      onChange={(event) => setAcceptTerms(event.target.checked)}
                    />
                    <Button
                      btnText="Terms and Conditions"
                      variant="text"
                      onClick={() =>
                        window.open("https://kapiree.com/terms-of-service")
                      }
                    />
                  </TermsCheckbox>
                  <BlockMargin size={10}>
                    <Grid container spacing={2} alignItems="flex-start">
                      <Grid item xs={12} md={6}>
                        <Button
                          disabled={!acceptTerms || !interviewUserData}
                          btnText="Start"
                          variant="contained"
                          onClick={handleAgreeTermsCondition}
                          className="SSSS"
                          loading={isSubmiting}
                        />
                      </Grid>
                    </Grid>
                  </BlockMargin>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </SectionContainer>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CandidateOverview;
