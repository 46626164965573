import { encrypt } from "utils/encrypt";
import { appUrl } from "config/constants";

export const statusColorCode = (arg, val) => {
  const Fit = {
    background: "#F5FFFA",
    border: "1px solid #EEF9FF",
    hover: "#D7FFEB",
  };
  const Rejected = {
    background: "#FDEDED",
    border: "1px solid #FCE5E5",
    hover: "#FFD6D6",
  };
  const PendingReview = {
    background: "#FFF8F1",
    border: "1px solid #FFF2E6",
    hover: "#FFFCF8",
  };
  const regular = {
    background: "#F4FAFD",
    border: "1px solid #DBF3FF",
    hover: "#CFEFFF",
  };
  // let m =  arg === "Fit" ? Fit : arg === "Rejected" ? Rejected : arg === "Pending Review" ? PendingReview : regular;
  let m = arg === "Fit" ? Fit : arg === "Rejected" ? Rejected : regular;
  return m[val];
};

export function stringAvatar(name) {
  if (name) {
    let nameArray = name.split(" ");
    return `${nameArray[0][0] || ""}${nameArray?.[1] ? nameArray?.[1][0] : ""}`;
  }
  return;
}

export function printNullToZero(value) {
  if (value == null) {
    return 0;
  }
  return value;
}

export function generateShareLink(id, role, forReview) {
  const currentTimestamp = Date.now();
  const threeDaysInMilliseconds = 1 * 24 * 60 * 60 * 1000;
  const futureTimestamp = currentTimestamp + threeDaysInMilliseconds;
  let data = {
    id,
    role,
    expiry: futureTimestamp,
  };
  let cipheredData = encrypt(data);
  let link = "";
  if (forReview) {
    link = `${appUrl}/reviewer-login-enc?id=${cipheredData}`;
  } else {
    link = `${appUrl}/candidate-profile?id=${cipheredData}`;
  }
  return link;
}

export const getSubmittedStatusColor = (status) => {
  let color = "#E3E3E3";
  if (status == 2) {
    color = "#44AEFF";
  } else if (status == 3) {
    color = "#84CD01";
  } else if (status == 5) {
    color = "#FE7900";
  }
  return color;
};

export const applicationStatuses = {
  1: "Requested",
  2: "Pending Review",
  3: "Cleared",
  5: "Rejected",
  6: "Attempted",
  7: "Invite Sent",
  8: "Auto-rejected",
  9: "Invite Expired",
};

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const videoInterviewStatus = (status) => {
  let words = "";
  if (status == 1) {
    words = "Waiting for video interview";
  } else {
    words = "Video Interview Submitted";
  }
  return words;
};

export const getBackgroundColorForStatus = (status) => {
  if (bgColorForStatus[status]) {
    return bgColorForStatus[status];
  }
  return "#F8E8FE";
};

export const colors = {
  applied: "#1376D3",
  submitted: "#44AEFF",
  cleared: "#84CD01",
  rejected: "#FFBFBF",
  inviteExpired: "#BDBDBD",
};

export const bgColorForStatus = {
  0: "#F8E8FE",
  1: "#F8E8FE",
  2: "#FEF2AE",
  3: "#96FFA2",
  4: "#FCE9EB",
  5: "#FFBFBF",
  6: "#FEF1A9",
  7: "#BFD1FF",
  8: "#FFC796",
  9: "#BDBDBD",
};

export const textColorForStatus = {
  0: "#B71DF6",
  1: "#B71DF6",
  2: "#99720C",
  3: "#003804",
  4: "#E02938",
  5: "#E02938",
  6: "#5F4607",
  7: "#130038",
  8: "#382F00",
  9: "#303030",
};

export const selectedColorForStatus = {
  1: {
    color: "#350038",
    bgColor: "#FFC1F9",
  },
  2: {
    color: "#5F4607",
    bgColor: "#FEF1A9",
  },
  5: {
    color: "#380000",
    bgColor: "#FFBFBF",
  },
  9: {
    color: "#303030",
    bgColor: "#BDBDBD",
  },
};

// export const statuses = {
//   0: "Request Interview",
//   1: "Requested",
//   2: "Pending Review",
//   3: "Fit",
//   4: "Maybe",
//   5: "Rejected",
//   6: "Attempted",
//   7: "Invite Sent",
//   8: "Auto-rejected",
//   9: "Invite Expired",
// };
