import Answers from "pages/Interviewer/Answers/Answers";
import { Footer, Header } from "components";
import Container from "@mui/material/Container";
import { decrypt } from "utils/encrypt";
import { ContainerMargin } from "layout/MainLayout/layout.styles";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";

export default function Review() {
  // const id = localStorage.getItem("candidateIdForExternal");
  // const role = localStorage.getItem("jobRoleIdForExternal");
  let [searchParams] = useSearchParams();
  let data = searchParams.get("id");
  let { id, role, expiry } = decrypt(data.replaceAll(" ", "+"));

  if (expiry < Date.now()) {
    return (
      <Box
        component="section"
        sx={{
          p: 2,
          height: "100vh",
          display: "flex",
          placeItems: "center",
          textAlign: "center",
        }}
      >
        <Container maxWidth="sm">Sorry! This page link is expired.</Container>
      </Box>
    );
  }
  return (
    <>
      <Header />
      <Container maxWidth={false} style={{ padding: 0 }}>
        <ContainerMargin>
          {id && role && <Answers id={id} role={role} />}
        </ContainerMargin>
      </Container>
      <Footer />
    </>
  );
}
