import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { useAlert } from "contexts/alert.context";
import Card from "@mui/material/Card";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { Button as MuiButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardCountCards from "components/DashboardCountCards";
import { Button, PageHeader, Dialog } from "components";
import SendViaEmail from "components/SendViaEmail/SendViaEmail";
import AddCandidate from "./modals/AddCandidate";
import { sendReviewInvitesByRole } from "config/api.service";
import { TableActionButton, ButtonIcon } from "assets/styles/main.styles";
import SelectedCandidate from "./SelectedCandidate";
import { capitalizeWord } from "utils";
import { Loader } from "./components/Loader";
import CandidatesList from "./CandidatesList";
import JobRoleFilterModal from "./components/JobRoleFilterModal";
import useWindowSize from "hooks/useWindowSize";
import { CandidateListContainer } from "./candidatelist.styles";

const CandidatesListing = () => {
  const { showAlert } = useAlert();
  const { state } = useLocation();
  const { roleName, id, candidate_id } = state;
  const [roleId, setRoleId] = useState(id);
  const [jobRoleName, setJobRoleName] = useState(roleName);
  const [sendForReviewModalOpen, setSendForReviewModalOpen] = useState(false);
  const [AddCandidateModalOpen, setAddCandidateModalOpen] = useState(false);
  const [
    selectedCandidatesForReviewEmail,
    setSelectedCandidatesForReviewEmail,
  ] = useState([]);

  const [candidateId, setCandidateId] = useState(null);
  const [jobRoleFilterModalOpen, setJobRoleFilterModalOpen] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  function handleCandidateClick(candidateId) {
    setCandidateId(candidateId);
  }

  const handleAddCandidateModalClose = () => {
    setAddCandidateModalOpen(false);
  };

  const handleSendReviewEmail = async (email) => {
    if (email) {
      const data = {
        email,
        roleId,
        candidateIds: selectedCandidatesForReviewEmail,
      };
      await sendReviewInvitesByRole(
        data,
        handleSendReviewSuccess,
        handleSendReviewError
      );
    }
    setSendForReviewModalOpen(false);
  };

  const handleSendReviewSuccess = (res) => {
    showAlert(res.message, "success");
  };

  const handleSendReviewError = (message) => {
    showAlert(message, "error");
  };

  const handleJobRoleFilter = (jobRole) => {
    setJobRoleFilterModalOpen(false);
    setRoleId(jobRole.id);
    setJobRoleName(jobRole.roleName);
  };

  return (
    <div style={{ padding: ".5rem 1rem", position: "relative" }}>
      {!isMobile && <DashboardCountCards roleId={roleId} />}
      <Card
        style={{
          padding: ".15rem .5rem 1rem .5rem",
          borderRadius: "8px",
          boxShadow:
            "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.02)",
        }}
      >
        <Grid container justifyContent="flex-start" alignItems="left">
          <Grid item xs={12} sm={8}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              style={{
                position: "relative",
              }}
            >
              <ButtonIcon
                onClick={() => {
                  setJobRoleFilterModalOpen(!jobRoleFilterModalOpen);
                }}
              >
                <MenuIcon
                  fontSize="large"
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </ButtonIcon>
              <JobRoleFilterModal
                jobRoleFilterModalOpen={jobRoleFilterModalOpen}
                setJobRoleFilterModalOpen={setJobRoleFilterModalOpen}
                handleJobRoleFilter={handleJobRoleFilter}
              />
              <PageHeader
                isStartAlign
                pageTitleText={capitalizeWord(jobRoleName)}
              />
              {isMobile == false ? (
                <div>
                  <Button
                    startIcon={<SendRoundedIcon />}
                    btnText="Send for Review"
                    variant={
                      selectedCandidatesForReviewEmail?.length == 0
                        ? "text"
                        : "outlined"
                    }
                    onClick={() => setSendForReviewModalOpen(true)}
                    disabled={selectedCandidatesForReviewEmail?.length == 0}
                  />
                </div>
              ) : (
                ""
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Stack
              direction="row"
              justifyContent={isMobile == true ? "flex-start" : "flex-end"}
              alignItems={"flex-end"}
            >
              {isMobile == true ? (
                <>
                  <div>
                    <Button
                      startIcon={<SendRoundedIcon />}
                      btnText="Send for Review"
                      variant={
                        selectedCandidatesForReviewEmail?.length == 0
                          ? "text"
                          : "outlined"
                      }
                      onClick={() => setSendForReviewModalOpen(true)}
                      disabled={selectedCandidatesForReviewEmail?.length == 0}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <TableActionButton>
                <MuiButton
                  size="small"
                  variant={
                    selectedCandidatesForReviewEmail?.length == 0
                      ? "outlined"
                      : "text"
                  }
                  onClick={() => setAddCandidateModalOpen(true)}
                  disabled={selectedCandidatesForReviewEmail?.length > 0}
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <GroupAddOutlinedIcon sx={{ fontSize: "1.5rem" }} />
                </MuiButton>
              </TableActionButton>
            </Stack>
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "flex-start",
          }}
        >
          {/* Left-side Panel */}
          <CandidateListContainer>
            <CandidatesList
              roleId={roleId}
              setCandidateId={setCandidateId}
              handleCandidateClick={handleCandidateClick}
              selectedCandidatesForReviewEmail={
                selectedCandidatesForReviewEmail
              }
              setSelectedCandidatesForReviewEmail={
                setSelectedCandidatesForReviewEmail
              }
              candidate_id={candidate_id}
            />
          </CandidateListContainer>
          {/* Right-side Panel */}
          <div
            style={{
              flex: "1",
              backgroundColor: "#F6F7F9",
              borderRadius: "10px",
              margin: "20px 0px",
              padding: "8px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 3px 4px -3px inset, rgba(0, 0, 0, 0.1) 0px 2px 3px -4px",
            }}
          >
            {candidateId == null ? (
              <Loader />
            ) : (
              <SelectedCandidate
                selectedCandidateId={candidateId}
                roleId={roleId}
              />
            )}
          </div>
        </div>

        {AddCandidateModalOpen && (
          <Dialog
            size="md"
            open={AddCandidateModalOpen}
            close={() => setAddCandidateModalOpen(false)}
            title="Add Candidates"
            children={
              <AddCandidate
                handleClose={handleAddCandidateModalClose}
                roleId={id}
                sheetData={[]}
              />
            }
          />
        )}
        {sendForReviewModalOpen && (
          <SendViaEmail
            isOpen={sendForReviewModalOpen}
            onClose={() => setSendForReviewModalOpen(false)}
            handleSendEmail={handleSendReviewEmail}
          />
        )}
      </Card>
    </div>
  );
};

export default CandidatesListing;
